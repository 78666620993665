import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid, Link } from "@mui/material";
import { useEffect, useState } from "react";
import Calendar from "../calendario";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import {
  cor_amarelo_500,
  cor_branco,
  cor_fundo,
  cor_preto_300,
  cor_preto_400,
  cor_principal_500,
  fonte,
  mobile,
} from "../theme";
import "./index.css";

const AulasGestor = ({ decodedToken }: any) => {
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const Details = (props: any) => {
    return <div>{props.data}</div>;
  };

  const showDetailsHandle = (dayStr: any) => {
    setData(dayStr);
    setShowDetails(true);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={3}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{ background: cor_branco, padding: 0, margin: 0 }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Aulas"} />
          </Grid>
          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={12} marginTop={".5rem"}>
                <Grid item>
                  <div
                    style={{
                      fontFamily: fonte,
                      color: cor_preto_300,
                      fontWeight: 600,
                      fontSize: "22px",
                    }}
                  >
                    Publicadas
                  </div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {[...Array(4)].map((_, index) => (
                    <Grid
                      item
                      height={125}
                      width={250}
                      style={{
                        marginTop: "1rem",
                        marginLeft: ".5rem",
                        background: cor_principal_500,
                        borderRadius: "1rem",
                      }}
                    >
                      <Link
                        key={index}
                        href="/aulas/Aula 1 -  Língua Portuguesa"
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              width: "100%",
                              height: "125px",
                              overflow: "hidden",
                            }}
                          >
                            <iframe
                              style={{
                                border: 0,
                                position: "absolute",
                                width: "130%",
                                height: "130%",
                                right: -40,
                                top: -1,
                              }}
                              src="https://player.vimeo.com/video/822165161?h=af091f12dc&amp;app_id=122963"
                            ></iframe>
                            <div
                              style={{
                                background: "rgba(0, 0, 0, 0.7)",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                              }}
                            ></div>
                            <div
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                color: cor_branco,
                              }}
                            >
                              <MoreVertIcon
                                style={{ width: "20px", height: "20px" }}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                left: 10,
                                top: 10,
                                background: cor_amarelo_500,
                                fontFamily: fonte,
                                padding: ".5rem",
                                borderRadius: "1rem",
                                fontSize: "8px",
                              }}
                            >
                              D{index + 1}
                            </div>

                            <div
                              style={{
                                position: "absolute",
                                left: 10,
                                top: 60,
                                fontWeight: 600,
                                background: cor_branco,
                                fontFamily: fonte,
                                color: cor_preto_400,
                                padding: ".5rem",
                                borderRadius: "1rem",
                                fontSize: "9px",
                              }}
                            >
                              RELAÇÃO ENTRE TEXTOS
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "justify",
                                  position: "absolute",
                                  left: 20,
                                  top: 88,
                                  fontFamily: fonte,
                                  color: cor_branco,
                                  fontSize: "6px",
                                  marginRight: "1rem",
                                }}
                              >
                                Reconhecer diferentes formas de tratar uma
                                informação na comparação de textos que tratam do
                                mesmo tema, em função das condições em que ele
                                foi produzido e daquelas em que serár ecebido.
                              </p>
                            </div>
                          </div>
                        </Grid>
                      </Link>
                    </Grid>
                  ))}
                </Grid>

                <Grid item>
                  <div
                    style={{
                      fontFamily: fonte,
                      color: cor_preto_300,
                      fontWeight: 600,
                      marginTop: "2rem",
                      fontSize: "22px",
                    }}
                  >
                    Planner
                  </div>
                  <p
                    style={{
                      marginTop: "0px",
                      fontFamily: fonte,
                      color: cor_preto_300,
                      fontSize: "14px",
                    }}
                  >
                    Programe aulas e conteúdos adicionais no calendário.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className="Calendario" style={{ marginBottom: "1rem" }}>
            <Calendar showDetailsHandle={showDetailsHandle} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AulasGestor;
