import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
} from "@mui/material";
import Vimeo from "@vimeo/player";
import { Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import IconePLay from "../../img/icon-park-solid_play.png";
import api from "../axios/axios";
import Carregar, { CarregarLoading } from "../carregar";
import Erro from "../erro";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import {
  StyledResultAntd,
  StyledSearchContainer,
  cor_amarelo_500,
  cor_branco,
  cor_branco_300,
  cor_fundo,
  cor_preto_300,
  cor_preto_400,
  cor_principal_400,
  cor_principal_500,
  fonte,
  mobile,
} from "../theme";
import Search from "antd/es/transfer/search";
const Vidoteca = ({ decodedToken }: any) => {
  const iframeRef = useRef<any>(null);
  const [videoteca, setVideoteca] = useState<any>({ items: [], total: 0 });
  const [favoritos, setFavoritos] = useState(false);
  const [disciplinas, setDisciplinas] = useState<any>({ items: [] });
  const [disciplinaSelecao, setDisciplinaSelecao] = useState<any>({ id: -1 });
  const [assistiu, setAssistiu] = useState<any>({ id: -1 });
  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
    carregarMais: false,
    loadingVideo: false,
  });
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<any>(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const newPreferencia = {
      ...item?.preferencia,
      status: true,
    };
    if (item && item?.id && assistiu?.preferencia?.status !== true)
      api.post("videoteca/preferencia/" + item?.id, newPreferencia);
  }, [assistiu]);

  const [buscar, setBuscar] = useState("");
  useEffect(() => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    if (buscar !== "") {
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `videoteca/pesquisar/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1`
              )
              .then((r) => {
                setVideoteca(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (buscar === "" && disciplinaSelecao?.id !== -1) {
      api
        .get(
          `/videoteca/disciplina/${
            disciplinaSelecao?.id === -1
              ? disciplinas?.items[0]?.id
              : disciplinaSelecao?.id
          }?page=${1}&size=${size}${favoritos ? "&favorito=true" : ""}`
        )
        .then((r: any) => {
          setVideoteca((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));
            return { ...r.data, items: novo };
          });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false, loadingVideo: false };
          })
        );
    }
  }, [buscar]);

  const handleBuscar = (e: any) => {
    if (e.target.value === "") {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: false,
        };
      });
    } else {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
    }

    setVideoteca({ items: [], total: 0 });
    setBuscar(e.target.value);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        if (item?.link && iframeRef.current) {
          const player = new Vimeo(iframeRef.current);

          player.on("ended", function () {
            setAssistiu(item);
            setVideoteca((antes: any) => {
              const updatedItems = antes?.items?.map((e: any) => {
                if (e?.id && item?.id && e?.id === item?.id) {
                  const newPreferencia = {
                    ...item?.preferencia,
                    status: true,
                  };
                  return {
                    ...e,
                    preferencia: newPreferencia,
                  };
                }

                return e;
              });

              return {
                ...antes,
                items: updatedItems,
              };
            });
          });
        }
      } catch {}
    }, 3000);

    return () => clearInterval(intervalId);
  }, [item?.link]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const size = 20;
  useEffect(() => {
    api
      .get("videoteca/disciplina")
      .then((r): any => {
        setDisciplinas((antes: any) => {
          const disciplina = r.data.items[0];
          setDisciplinaSelecao(disciplina);
          setConfiguracao((antes: any) => {
            return { ...antes, loadingVideo: true };
          });
          if (disciplina?.id && disciplina?.id !== -1)
            api
              .get("/videoteca/disciplina/" + disciplina?.id + "?size=" + size)
              .then((r) => {
                setVideoteca(r.data);
              });

          return r.data;
        });
        return r;
      })

      .then((r: any) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        });
      })
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        });
        console.log(e);
      })
      .finally(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, loadingVideo: false };
        })
      );
  }, []);

  useEffect(() => {
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        loadingVideo: true,
      };
    });

    if (
      disciplinaSelecao?.id &&
      buscar !== "" &&
      disciplinaSelecao?.id !== -1
    ) {
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `videoteca/pesquisar/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1`
              )
              .then((r) => {
                setVideoteca(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (disciplinaSelecao?.id && disciplinaSelecao?.id !== -1) {
      api
        .get("/videoteca/disciplina/" + disciplinaSelecao?.id + `?size=${size}`)
        .then((r) => {
          setVideoteca(r.data);
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, loadingVideo: false };
          })
        );
    }
  }, [disciplinaSelecao]);

  const handlePage = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    if (buscar !== "") {
      api
        .get(
          `videoteca/pesquisar/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=${page}`
        )
        .then((r) => {
          setVideoteca((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));
            return { ...r.data, items: novo };
          });
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingVideo: false,
              carregarMais: false,
            };
          });
        });
    } else if (disciplinaSelecao?.id && disciplinaSelecao?.id !== -1) {
      api
        .get(
          `/videoteca/disciplina/${
            disciplinaSelecao?.id === -1
              ? disciplinas?.items[0]?.id
              : disciplinaSelecao?.id
          }?page=${page}&size=${size}${favoritos ? "&favorito=true" : ""}`
        )
        .then((r: any) => {
          setVideoteca((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));
            return { ...r.data, items: novo };
          });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false };
          })
        );
    }
  };

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  const handleClose = () => setOpen(false);

  return (
    <>
      <React.Fragment>
        <Dialog
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "1.5rem",
              minHeight: "80%",
              minWidth: "80%",
              background: cor_branco,
            },
          }}
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: cor_preto_400,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              color: cor_preto_300,
              fontFamily: fonte,
              fontSize: "18px",
              fontWeight: 600,
              padding: ".5rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
            }}
          >
            <Grid item>
              <p style={{ textAlign: "center" }}>{item?.nome}</p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "auto" }}
          >
            <Grid item style={{ height: "auto", width: "100%" }}>
              <iframe
                ref={(el) => (iframeRef.current = el)}
                style={{
                  border: 0,
                  aspectRatio: "16 / 9",
                  width: "100%",
                  height: "auto",
                }}
                src={item?.link}
              />
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          menuSelecao={2}
          ativo={mobile(windowSize)}
          decodedToken={decodedToken}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{ background: cor_branco, padding: 0, margin: 0 }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Videoteca"} />
          </Grid>
          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} marginTop={".5rem"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  marginLeft={"-.5rem"}
                >
                  {favoritos ? (
                    ""
                  ) : (
                    <Grid item>
                      <Space direction="vertical">
                        <StyledSearchContainer>
                          <Search
                            handleClear={() =>
                              setConfiguracao((antes: any) => {
                                return {
                                  ...antes,
                                  loadingVideo: true,
                                };
                              })
                            }
                            value={buscar}
                            onChange={handleBuscar}
                            placeholder="Pesquisar..."
                          />
                        </StyledSearchContainer>
                      </Space>
                    </Grid>
                  )}
                  <Grid item>
                    {favoritos ? (
                      <span
                        onClick={(_) => {
                          setConfiguracao((antes: any) => {
                            return {
                              ...antes,
                              loadingVideo: true,
                            };
                          });
                          setVideoteca({ items: [], total: 0 });
                          setFavoritos(false);
                          const disciplinaId =
                            disciplinaSelecao?.id === -1
                              ? disciplinas?.items[0]?.id
                              : disciplinaSelecao?.id;
                          const apiUrl =
                            `videoteca/disciplina/${disciplinaId}` +
                            `?size=${size}`;
                          api
                            .get(apiUrl)
                            .then((r) => {
                              setVideoteca(r.data);
                            })
                            .finally(() =>
                              setConfiguracao((antes: any) => {
                                return {
                                  ...antes,
                                  loadingVideo: false,
                                };
                              })
                            );
                        }}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",

                          color: cor_principal_500,
                          fontFamily: fonte,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        &lt;   Favoritos{" "}
                        <FavoriteIcon style={{ marginLeft: ".5rem" }} />
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        {!favoritos ? (
                          <Button
                            disabled={buscar !== "" ? true : false}
                            onClick={(_) => {
                              setConfiguracao((antes: any) => {
                                return {
                                  ...antes,
                                  loadingVideo: true,
                                };
                              });
                              setVideoteca({ items: [], total: 0 });
                              setFavoritos(true);
                              const disciplinaId =
                                disciplinaSelecao?.id === -1
                                  ? disciplinas?.items[0]?.id
                                  : disciplinaSelecao?.id;
                              const apiUrl =
                                `videoteca/disciplina/${disciplinaId}?favorito=true` +
                                `&size=${size}`;
                              api
                                .get(apiUrl)
                                .then((r) => {
                                  setVideoteca(r.data);
                                })
                                .finally(() =>
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: false,
                                    };
                                  })
                                );
                            }}
                            style={{
                              background: `${cor_principal_500}`,
                              color: `${cor_branco}`,
                              fontFamily: fonte,
                            }}
                            endIcon={
                              <FavoriteIcon style={{ marginTop: "-.2rem" }} />
                            }
                            variant={"contained"}
                            size="small"
                          >
                            Favoritos
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item>
                        {/* <Button
                          style={{
                            marginRight: "1rem",
                            background: `${cor_principal_500}`,
                            color: `${cor_branco}`,
                            fontFamily: fonte,
                          }}
                          endIcon={
                            <FilterAltIcon style={{ marginTop: "-.2rem" }} />
                          }
                          variant={"contained"}
                          size="small"
                        >
                          Filtrar
                        </Button> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                {!favoritos ? (
                  <Grid item xs={10}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      {disciplinas?.items?.length > 0
                        ? disciplinas?.items?.map(
                            (item: any, index: number) => (
                              <Grid
                                item
                                key={item?.id}
                                style={{
                                  marginTop:
                                    disciplinaSelecao?.id === -1 && index === 0
                                      ? ""
                                      : disciplinaSelecao?.id === item?.id
                                      ? ""
                                      : ".5rem",
                                }}
                              >
                                <p
                                  onClick={() => {
                                    if (disciplinaSelecao?.id !== item?.id) {
                                      setVideoteca({ items: [], total: 0 });
                                      setDisciplinaSelecao(item);
                                    }
                                  }}
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    fontFamily: fonte,
                                    cursor: "pointer",
                                    userSelect: "none",
                                    color:
                                      (disciplinaSelecao?.id === -1 &&
                                        index === 0) ||
                                      disciplinaSelecao?.id === item?.id
                                        ? cor_principal_500
                                        : cor_preto_300,
                                  }}
                                >
                                  {item.nome}
                                </p>
                              </Grid>
                            )
                          )
                        : ""}
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              {!favoritos && disciplinas?.items.length > 0 ? (
                <Grid item xs={12} style={{ marginRight: "1rem" }}>
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: cor_principal_500,
                        borderRadius: "1rem",
                      },

                      height: 5,
                      borderRadius: 5,
                      background: cor_branco_300,
                    }}
                    variant="determinate"
                    value={
                      disciplinaSelecao?.id === -1
                        ? (1 / disciplinas?.items?.length) * 100
                        : ((disciplinas?.items?.findIndex(
                            (item: any) => item?.id === disciplinaSelecao?.id
                          ) +
                            1) /
                            disciplinas?.items?.length) *
                          100
                    }
                  />
                </Grid>
              ) : videoteca?.items?.length === 0 &&
                configuracao?.loadingVideo === false &&
                buscar === "" ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      status="success"
                      title="Nenhum vídeo cadastrado!"
                      subTitle="Nenhum vídeo cadastrado!"
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid
            item
            marginTop={"2rem"}
            marginRight={"1rem"}
            marginBottom={0}
            maxHeight={"calc(100vh - 260px)"}
            style={{
              overflow: "auto",
              marginBottom: 0,
              marginLeft: "2.5rem",
              scrollbarColor: `${cor_principal_500} #EDF2F6`,
              scrollbarWidth: "thin",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              {configuracao?.loadingVideo ? (
                <CarregarLoading />
              ) : (buscar !== "" || videoteca?.items?.length === 0) &&
                favoritos === false ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      status="success"
                      title="Nenhum vídeo encontrado!"
                      subTitle="Nenhum vídeo encontrado!"
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              {videoteca?.items
                .filter((item: any, index: any) => {
                  if (!favoritos) {
                    return true;
                  }
                  if (item?.preferencia?.favorito) return true;
                })
                .map((item: any, index: any) => (
                  <Grid
                    key={item?.id + item?.preferencia?.favorito}
                    item
                    height={225}
                    width={300}
                    style={{
                      marginTop: "1rem",
                      marginLeft: "1rem",
                      background: cor_principal_500,
                      borderRadius: "1rem",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          width: "100%",
                          height: "170px",
                          borderRadius: "10px",
                          borderEndEndRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          overflow: "hidden",
                        }}
                        onClick={(e) => {
                          setItem(item);
                          setOpen(true);
                        }}
                      >
                        <iframe
                          style={{
                            border: 0,
                            position: "absolute",
                            width: "130%",
                            height: "130%",
                            right: -40,
                            top: -1,
                          }}
                          src={item?.link}
                        ></iframe>
                        <div
                          style={{
                            background: "rgba(0, 0, 0, 0.7)",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        ></div>
                        <img
                          style={{
                            position: "absolute",
                            top: 50,
                            right: 125,
                          }}
                          src={IconePLay}
                          alt="Icone play"
                        />
                      </div>
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: "100%",
                          marginLeft: "-1rem",
                          marginTop: "-1rem",
                        }}
                      >
                        <LinearProgress
                          style={{ width: "300px", borderRadius: "0rem" }}
                          sx={{
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: `${cor_amarelo_500}`,
                            },
                            height: 5,
                            borderRadius: 5,
                            background: cor_branco,
                          }}
                          variant="determinate"
                          value={item?.preferencia?.status ? 100 : 0}
                        />
                      </Grid>

                      <Grid item style={{ marginTop: "-.8rem", width: "100%" }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          paddingRight={"1rem"}
                        >
                          <Grid
                            item
                            style={{ maxWidth: "80%", maxHeight: "15px" }}
                          >
                            <p
                              style={{
                                color: `${cor_branco}`,
                                fontFamily: fonte,
                                fontSize: "13px",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                                marginTop: ".3rem",
                              }}
                            >
                              {item?.nome}
                            </p>
                          </Grid>
                          <Grid
                            item
                            onClick={(e: any) => {
                              videoteca?.items.map((e: any) => {
                                if (e?.id && item?.id && e?.id === item?.id) {
                                  const newPreferencia = {
                                    ...item?.preferencia,
                                    favorito: item?.preferencia
                                      ? !item?.preferencia?.favorito
                                      : true,
                                  };
                                  api.post(
                                    "videoteca/preferencia/" + item?.id,
                                    newPreferencia
                                  );
                                  return {
                                    ...e,
                                    preferencia: newPreferencia,
                                  };
                                }

                                return e;
                              });
                              setVideoteca((antes: any) => {
                                const updatedItems = antes?.items?.map(
                                  (e: any) => {
                                    if (
                                      e?.id &&
                                      item?.id &&
                                      e?.id === item?.id
                                    ) {
                                      const newPreferencia = {
                                        ...item?.preferencia,
                                        favorito: item?.preferencia
                                          ? !item?.preferencia?.favorito
                                          : true,
                                      };
                                      return {
                                        ...e,
                                        preferencia: newPreferencia,
                                      };
                                    }

                                    return e;
                                  }
                                );

                                return {
                                  ...antes,
                                  items: updatedItems,
                                };
                              });
                            }}
                          >
                            {item?.preferencia?.favorito ? (
                              <FavoriteIcon
                                style={{
                                  borderRadius: "100%",
                                  background: `${cor_principal_400}`,
                                  padding: ".3rem",
                                  color: cor_branco,
                                  cursor: "pointer",
                                  marginTop: ".11rem",
                                }}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                style={{
                                  borderRadius: "100%",
                                  background: `${cor_principal_400}`,
                                  padding: ".3rem",
                                  color: cor_branco,
                                  cursor: "pointer",
                                  marginTop: ".11rem",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}

              {videoteca?.items?.length !== videoteca?.total ? (
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <LoadingButton
                        loading={configuracao?.carregarMais}
                        variant="outlined"
                        sx={{
                          background: cor_branco,
                          color: cor_preto_300,
                          fontFamily: fonte,
                          borderColor: cor_branco,
                          marginTop: "1rem",
                          marginBottom: ".5rem",
                          "&:hover": {
                            borderColor: cor_principal_500,
                          },
                        }}
                        onClick={(e) => handlePage(videoteca.page + 1)}
                      >
                        <span>Carregar mais</span>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Vidoteca;
