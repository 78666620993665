import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { keycloak } from ".";
import "./App.css";
import Aula from "./components/aula";
import AulaItem from "./components/aula-criar";
import AulaTurma from "./components/aula-turma";
import Aulas from "./components/aulas";
import AulasGestor from "./components/aulas-gestor";
import Configuracoes from "./components/configuracoes";
import FaleConosco from "./components/faleConosco";
import Home from "./components/home";
import Informacoes from "./components/informacoes";
import Metricas from "./components/metricas";
import Recados from "./components/recados";
import Sair from "./components/sair";
import Trilha from "./components/trilha";
import Vidoteca from "./components/videoteca";
import { AuthToken, getTipoConta } from "./types";

function App() {
  const [decodedToken, setDecodedToken] = useState(
    keycloak.token && (jwtDecode(keycloak.token) as AuthToken)
  );

  const tipoConta = getTipoConta(decodedToken);
  if (tipoConta === "aluno" || tipoConta === "professor")
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home decodedToken={decodedToken} />} />
          <Route path="/" element={<Home decodedToken={decodedToken} />} />
          <Route index element={<Home decodedToken={decodedToken} />} />
          <Route path="*" element={<Home decodedToken={decodedToken} />} />
          <Route
            path="/videoteca"
            element={<Vidoteca decodedToken={decodedToken} />}
          />
          <Route
            path="/videoteca/:uuid"
            element={<Aula decodedToken={decodedToken} />}
          />
          <Route
            path="/configuracoes"
            element={
              <Configuracoes
                decodedToken={decodedToken}
                setDecodedToken={setDecodedToken}
              />
            }
          />
          <Route
            path="/aulas/:uuid"
            element={<Aula decodedToken={decodedToken} />}
          />
          <Route
            path="/aulas"
            element={<Aulas decodedToken={decodedToken} />}
          />
          <Route
            path="/informacoes"
            element={<Informacoes decodedToken={decodedToken} />}
          />
          <Route
            path="/fale"
            element={<FaleConosco decodedToken={decodedToken} />}
          />
          <Route
            path="/metricas"
            element={<Metricas decodedToken={decodedToken} />}
          />
          <Route path="/sair" element={<Sair decodedToken={decodedToken} />} />
          <Route
            path="/trilhas"
            element={<Trilha decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/:uuid"
            element={<Aula decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/turma/:uuidTurma/:uuidDisciplina"
            element={<AulaItem decodedToken={decodedToken} />}
          />
          <Route
            path="/trilhas/turma/:uuidTurma/:uuidDisciplina/:uuidAula"
            element={<AulaItem decodedToken={decodedToken} />}
          />
        </Routes>
      </BrowserRouter>
    );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home decodedToken={decodedToken} />} />
        <Route path="/" element={<Home decodedToken={decodedToken} />} />
        <Route
          path="/trilhas"
          element={<Trilha decodedToken={decodedToken} />}
        />
        <Route
          path="/trilhas/turma"
          element={<AulaTurma decodedToken={decodedToken} />}
        />
        <Route
          path="/trilhas/:uuid"
          element={<Aula decodedToken={decodedToken} />}
        />
        <Route
          path="/trilhas/turma/:uuidTurma/:uuidDisciplina"
          element={<AulaItem decodedToken={decodedToken} />}
        />
        <Route
          path="/trilhas/turma/:uuidTurma/:uuidDisciplina/:uuidAula"
          element={<AulaItem decodedToken={decodedToken} />}
        />
        <Route index element={<Home decodedToken={decodedToken} />} />
        <Route path="*" element={<Home decodedToken={decodedToken} />} />
        <Route
          path="/videoteca"
          element={<Vidoteca decodedToken={decodedToken} />}
        />
        <Route
          path="/videoteca/:uuid"
          element={<Aula decodedToken={decodedToken} />}
        />
        <Route
          path="/configuracoes"
          element={
            <Configuracoes
              decodedToken={decodedToken}
              setDecodedToken={setDecodedToken}
            />
          }
        />
        <Route
          path="/aulas/:uuid"
          element={<Aula decodedToken={decodedToken} />}
        />
        <Route path="/aulas" element={<Aulas decodedToken={decodedToken} />} />
        <Route
          path="/aulas/turma"
          element={<AulaTurma decodedToken={decodedToken} />}
        />
        <Route
          path="/aulas/turma/:uuidTurma/:uuidDisciplina"
          element={<AulaItem decodedToken={decodedToken} />}
        />
        <Route
          path="/aulas/turma/:uuidTurma/:uuidDisciplina/:uuidAula"
          element={<AulaItem decodedToken={decodedToken} />}
        />
        <Route
          path="/aulas/gestor"
          element={<AulasGestor decodedToken={decodedToken} />}
        />
        <Route
          path="/informacoes"
          element={<Informacoes decodedToken={decodedToken} />}
        />
        <Route
          path="/fale"
          element={<FaleConosco decodedToken={decodedToken} />}
        />
        <Route
          path="/metricas"
          element={<Metricas decodedToken={decodedToken} />}
        />
        <Route
          path="/recados"
          element={<Recados decodedToken={decodedToken} />}
        />
        <Route path="/sair" element={<Sair decodedToken={decodedToken} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
