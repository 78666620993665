import CancelIcon from "@mui/icons-material/Cancel";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Button, Grid, Link } from "@mui/material";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import {
  cor_branco,
  cor_branco_200,
  cor_preto_100,
  cor_preto_300,
  cor_principal_500,
  cor_verde_200,
  cor_vermelho_200,
  fonte,
} from "../theme";
import api from "../axios/axios";
import { toast } from "react-toastify";
import { getTipoConta } from "../../types";

export const Resumo = ({ aulaItem, alternativasItem, decodedToken }: any) => {
  const svgRef = useRef(null);
  let aula: any = { ...aulaItem };
  let alternativas: any = [...alternativasItem];
  let quiz = [
    aula?.tipo === "avaliacao_quiz" && alternativas[1]?.id
      ? alternativas[1]
      : [],
  ];

  const total = aula?.pontuacao;
  const pontuacoes: any = [];
  let totalSomaAtividades = 0;
  const submissao: any = {
    id_aula: aula?.id,
    nota: 0,
    respostas: [],
    tempo: alternativas[0]?.tempo,
  };
  const submissaoQuiz: any = [];

  const acertos = aula.atividades
    .filter((e: any) => e.id !== -1)
    .filter((atividade: any, index: any) => {
      if (aula.tipo === "avaliacao_quiz" && atividade.tipo !== "quiz") {
        totalSomaAtividades += atividade?.nota;
        submissao?.respostas.push({
          tempo: alternativas[index + 1]?.tempo,
          id_atividade: alternativas[index + 1]?.id_atividade,
          id_alternativa: alternativas[index + 1]?.id,
        });
      } else {
        totalSomaAtividades += atividade?.nota;
        submissao?.respostas.push({
          tempo: alternativas[index + 1]?.tempo,
          id_atividade: alternativas[index + 1]?.id_atividade,
          id_alternativa: alternativas[index + 1]?.id,
        });
      }

      if (alternativas[index + 1]?.id === atividade.alternativa_certa)
        return true;
    });

  acertos.map((e: any) => {
    if (aula.tipo === "avaliacao_quiz" && e.tipo !== "quiz") {
      const proporcao_alternativa = e?.nota / totalSomaAtividades;
      const pontuacao = proporcao_alternativa * total;
      pontuacoes.push(pontuacao);
    } else if (aula.tipo === "avaliacao_quiz" && e.tipo === "quiz") {
      return null;
    } else {
      const proporcao_alternativa = e?.nota / totalSomaAtividades;
      const pontuacao = proporcao_alternativa * total;
      pontuacoes.push(pontuacao);
    }
  });

  const soma_pontuacoes = pontuacoes.reduce(
    (sum: any, pontuacao: any) => sum + pontuacao,
    0
  );

  submissao.nota = Math.round(soma_pontuacoes);

  let porcentagemTotal: number = soma_pontuacoes / total;

  if (Number.isNaN(porcentagemTotal)) porcentagemTotal = 0;

  if (submissao?.respostas?.length === 0) {
    porcentagemTotal = 1;
    submissao.nota = 100;
  }

  useEffect(() => {
    const porcentagem: number = porcentagemTotal * 100;
    const raioInterno = 105;
    const raioExterno = 90;

    const svg = d3
      .select(svgRef.current)
      .append("g")
      .attr("transform", "translate(150,150)");
    const backgroundArc: any = d3
      .arc()
      .innerRadius(raioInterno)
      .outerRadius(raioExterno)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2)
      .cornerRadius(10);

    svg
      .append("path")
      .attr("class", "background-arc")
      .attr("fill", "lightgray")
      .attr("stroke", "none")
      .attr("d", backgroundArc);

    const linearGradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "linear-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    linearGradient
      .append("stop")
      .attr("offset", "10%")
      .attr("stop-color", "#4F92D1");

    linearGradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#00519E");

    const arc: any = d3
      .arc()
      .innerRadius(raioInterno)
      .outerRadius(raioExterno)
      .startAngle(-Math.PI / 2)
      .endAngle(-Math.PI / 2 + 2 * Math.PI * (porcentagem / 200))
      .cornerRadius(10);
    svg
      .append("path")
      .attr("class", "arc")
      .attr("fill", "url(#linear-gradient)")
      .attr("d", arc)
      .transition()
      .duration(2000)
      .attrTween("d", () => {
        return (t: any) => {
          const interpolate = d3.interpolate(
            -Math.PI / 2,
            -Math.PI / 2 + 2 * Math.PI * (porcentagem / 200)
          );
          return arc.endAngle(interpolate(t))();
        };
      });

    const anguloMedio = (-Math.PI / 2 + 2 * Math.PI * (porcentagem / 200)) / 2;

    const x = (Math.cos(anguloMedio) * (raioInterno + raioExterno)) / 2;
    const y = (Math.sin(anguloMedio) * (raioInterno + raioExterno)) / 2;

    svg
      .append("text")
      .attr("x", 2)
      .attr("y", -25)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .text(`${porcentagem.toFixed(2)}%`)
      .attr("fill", cor_preto_300)
      .style("font-size", "30px")
      .style("font-family", fonte)
      .style("font-weight", "bold");

    if (getTipoConta(decodedToken) === "aluno") {
      toast.info("Enviando resposta");
      api
        .post("submissao", submissao)
        .then((e) => toast.success("Resposta enviada"))
        .catch((e) => toast.error("Resposta não enviada"));
    }

    return () => {
      svg.remove();
    };
  }, []);

  return (
    <>
      <Grid item style={{ padding: "1rem" }}>
        <Grid
          item
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
            borderRadius: "1rem",
            background: cor_branco,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              background: cor_principal_500,
              color: cor_branco,
              fontFamily: fonte,
              fontSize: "18px",
              fontWeight: 500,
              padding: ".5rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
            }}
          >
            <Grid item>{aula.nome}</Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ paddingTop: "1rem" }}
            >
              <Grid item>
                <svg ref={svgRef} width="300" height="180" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              padding={"1rem"}
              style={{ paddingBottom: "4rem" }}
              spacing={2}
            >
              {aula.atividades
                .filter((e: any) => e.id !== -1)
                .map((atividade: any, index: any) => (
                  <Grid item xs={8} key={atividade.id}>
                    <h2
                      style={{
                        fontFamily: fonte,
                        paddingBottom: 0,
                        marginBottom: 0,
                      }}
                    >
                      <iframe
                        style={{
                          border: 0,
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          borderRadius: "1rem",
                          fontFamily: fonte,
                          color: cor_preto_300,
                          fontWeight: 500,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          aspectRatio: "16 / 9",
                          width: "100%",
                          height: "300px",
                        }}
                        allow="fullscreen"
                        src={aulaItem?.atividades[index + 1]?.pergunta}
                      />
                    </h2>
                    <div>
                      {alternativas[index + 1].id ===
                      atividade.alternativa_certa ? (
                        <Grid item>
                          <div
                            style={{
                              border: `1px dashed ${cor_verde_200}`,
                              borderRadius: "1rem",
                              padding: "1rem",
                              color: cor_verde_200,
                              fontFamily: fonte,
                              fontWeight: 600,
                            }}
                          >
                            {atividade.tipo === "quiz" ? (
                              <>
                                {`${index + 1}) `}
                                <WorkspacePremiumIcon
                                  style={{ marginBottom: "-.3rem" }}
                                />{" "}
                                {alternativas[index + 1].id ??
                                  "Nenhuma seleção"}
                              </>
                            ) : (
                              <>
                                {atividade.alternativas
                                  .filter((r: any, id: any) => {
                                    if (
                                      r.id &&
                                      atividade.alternativa_certa &&
                                      r.id === atividade.alternativa_certa
                                    ) {
                                      r.index = id + 1;
                                      return r;
                                    }
                                  })
                                  .map((r: any) => (
                                    <div key={r.index}>
                                      {" "}
                                      {`${index + 1}) `}
                                      <span>
                                        <WorkspacePremiumIcon
                                          style={{ marginBottom: "-.3rem" }}
                                        />{" "}
                                      </span>
                                      {/* {String.fromCharCode(65 + r.index)} */}
                                      {`${r.nome}`}
                                    </div>
                                  ))}
                              </>
                            )}
                          </div>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={true}>
                            {atividade.alternativas
                              .filter((r: any, id: any) => {
                                if (
                                  alternativas[index + 1].id !== null &&
                                  r.id === alternativas[index + 1].id
                                ) {
                                  r.index = id;
                                  return r;
                                }
                              })
                              .map((r: any) => (
                                <div
                                  key={r?.id}
                                  style={{
                                    border: `1px dashed ${
                                      atividade?.tipo === "quiz"
                                        ? cor_verde_200
                                        : cor_vermelho_200
                                    }`,
                                    borderRadius: "1rem",
                                    padding: "1rem",
                                    color:
                                      atividade?.tipo === "quiz"
                                        ? cor_verde_200
                                        : cor_vermelho_200,
                                    fontFamily: fonte,
                                    fontWeight: 600,
                                  }}
                                >
                                  {" "}
                                  {`${index + 1}) `}
                                  <span>
                                    {atividade?.tipo === "quiz" ? (
                                      <WorkspacePremiumIcon
                                        style={{ marginBottom: "-.4rem" }}
                                      />
                                    ) : (
                                      <CancelIcon
                                        style={{ marginBottom: "-.4rem" }}
                                      />
                                    )}{" "}
                                  </span>
                                  {/* {String.fromCharCode(65 + r.index)} */}
                                  {`${r.nome}`}
                                </div>
                              ))}
                          </Grid>
                          {aula.atividades[index + 1]?.tipo !== "quiz" ? (
                            <>
                              {atividade.alternativas.filter(
                                (r: any) => alternativas[index + 1].id === r.id
                              ).length > 0 ? (
                                <Grid item xs={true}>
                                  <div
                                    style={{
                                      border: `1px dashed ${cor_preto_100}`,
                                      borderRadius: "1rem",
                                      padding: "1rem",
                                      color: cor_preto_100,
                                      fontFamily: fonte,
                                    }}
                                  >
                                    {atividade.alternativas
                                      .filter((r: any, id: any) => {
                                        if (
                                          atividade.alternativa_certa === r.id
                                        ) {
                                          r.index = id;
                                          return r;
                                        }
                                      })
                                      .map((r: any) => (
                                        <>
                                          {`Correta: `}
                                          {/* {String.fromCharCode(65 + r.index)} */}
                                          {`${r.nome}`}
                                        </>
                                      ))}
                                  </div>
                                </Grid>
                              ) : (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                  paddingLeft={2}
                                >
                                  <Grid item xs={12}>
                                    <div
                                      style={{
                                        border: `1px dashed ${cor_preto_100}`,
                                        borderRadius: "1rem",
                                        padding: "1rem",
                                        color: cor_preto_100,
                                        fontFamily: fonte,
                                      }}
                                    >
                                      {atividade.alternativas
                                        .filter((r: any, id: any) => {
                                          if (
                                            atividade.alternativa_certa === r.id
                                          ) {
                                            r.index = id;
                                            return r;
                                          }
                                        })
                                        .map((r: any) => (
                                          <>
                                            {`Correta: `}
                                            {/* {String.fromCharCode(65 + r.index)} */}
                                            {`${r.nome}`}
                                          </>
                                        ))}
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                      )}
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginTop: "-2rem", paddingBottom: "2.5rem" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Link
                  href={
                    window.location.href.includes("aulas")
                      ? "/aulas"
                      : "/trilhas"
                  }
                >
                  <Button
                    style={{
                      background: cor_principal_500,
                      color: cor_branco_200,
                      fontFamily: fonte,
                    }}
                  >
                    Voltar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
