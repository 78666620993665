import { Result } from "antd";
import { Button, Container, Grid, Link } from "@mui/material";
import { cor_branco, cor_preto_300, cor_principal_500, fonte } from "../theme";

const Erro = () => {
  return (
    <Container style={{ height: "100vh" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <Result
          style={{
            color: cor_preto_300,
            fontFamily: fonte,
          }}
          status="warning"
          title="Existem alguns problemas com sua operação."
          extra={
            <Link href={"/"}>
              <Button
                variant="contained"
                style={{
                  background: cor_principal_500,
                  color: cor_branco,
                  fontFamily: fonte,
                }}
                size="small"
                key="console"
              >
                Voltar
              </Button>
            </Link>
          }
        />
      </Grid>
    </Container>
  );
};

export default Erro;
