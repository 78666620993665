import { Container, Grid } from "@mui/material";
import { cor_preto_300 } from "../theme";
import "./index.css";

const Carregar = () => {
  return (
    <Container style={{ height: "100vh" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <span className="loader" style={{ color: cor_preto_300 }} />
      </Grid>
    </Container>
  );
};

export const CarregarLoading = () => {
  return (
    <Container style={{ height: "50vh" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <span className="loader" style={{ color: cor_preto_300 }} />
      </Grid>
    </Container>
  );
};

export default Carregar;
