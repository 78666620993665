import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Link } from "@mui/material";
import {
  MenuSupeior,
  cor_branco,
  cor_preto_300,
  cor_principal_500,
  cor_verde_500,
  cor_vermelho_500,
  fonte,
} from "../theme";

const MenuSuperior = ({
  texto,
  ativo = true,
  uuidDisciplina = null,
  setOpen = null,
  setProgramar = null,
  ativarBtn = null,
  publicar = null,
}: any) => {
  if (!ativo) return <></>;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: ".5rem", paddingBottom: ".8rem" }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              {((window.location.pathname.includes("aulas") &&
                window.location.pathname.includes("turma")) ||
                (window.location.pathname.includes("trilhas") &&
                  window.location.pathname.includes("turma"))) &&
              uuidDisciplina ? (
                <Link
                  href={`/${
                    window.location.href.includes("trilhas")
                      ? "trilhas"
                      : "aulas"
                  }/turma/`}
                >
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      fontFamily: fonte,
                      background: cor_vermelho_500,
                      color: cor_branco,
                    }}
                  >
                    Voltar
                  </Button>
                </Link>
              ) : (
                ""
              )}
            </Grid>
            <Grid item>
              <MenuSupeior
                style={{
                  marginLeft: "1.5rem",
                  fontFamily: fonte,
                  color: cor_preto_300,
                  fontWeight: 600,
                }}
              >
                {texto}
              </MenuSupeior>
            </Grid>
          </Grid>
        </Grid>
        {(window.location.pathname.includes("aulas") ||
          window.location.pathname.includes("turma")) &&
        uuidDisciplina ? (
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  onClick={(e: any) => {
                    setOpen(true);
                    setProgramar(false);
                  }}
                  size="small"
                  variant="outlined"
                  style={{
                    fontFamily: fonte,
                    color: cor_principal_500,
                    borderColor: cor_principal_500,
                  }}
                  endIcon={<DateRangeIcon />}
                >
                  Data de entrega
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: fonte,
                    background: cor_verde_500,
                    color: cor_branco,
                  }}
                  loading={ativarBtn}
                  loadingPosition="end"
                  endIcon={<CheckCircleOutlineIcon />}
                >
                  {publicar ? "PUBLICAR" : "PROGRAMAR"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default MenuSuperior;
