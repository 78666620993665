import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {
  cor_branco,
  cor_branco_200,
  cor_preto_300,
  cor_preto_400,
  cor_principal_500,
  cor_vermelho_500,
  fonte,
} from "../theme";

export default function Deletar({
  open,
  handleClose,
  item,
  funcaoDeletar,
  titulo = false,
}: any) {
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { borderRadius: "1.5rem", background: cor_branco } }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: cor_preto_400,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            gutterBottom
            style={{ fontFamily: fonte, color: cor_preto_300 }}
          >
            {`Têm certeza que deseja remover `}{" "}
            <b>{titulo ? item?.titulo : item?.nome}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: cor_vermelho_500,
              color: cor_branco_200,
              fontFamily: fonte,
            }}
            size="small"
            variant={"contained"}
            onClick={handleClose}
          >
            Não
          </Button>
          <Button
            style={{
              background: cor_principal_500,
              color: cor_branco,
              fontFamily: fonte,
            }}
            size="small"
            autoFocus
            onClick={(_) => {
              funcaoDeletar();
              handleClose();
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
