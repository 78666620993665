import AddIcon from "@mui/icons-material/Add";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Grid } from "@mui/material";
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getWeek,
  lastDayOfWeek,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import { pt } from "date-fns/locale";
import { useState } from "react";
import {
  cor_branco,
  cor_branco_500,
  cor_preto_100,
  cor_preto_200,
  cor_preto_300,
  cor_principal_500,
  cor_verde_limao_200,
  fonte,
} from "../theme";

const Calendar = ({ showDetailsHandle }: any) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [semanaMes, setSemanaMes] = useState(0);

  const changeMonthHandle = (btnType: any) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType: any) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day: any, dayStr: any) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = semanaMes === 0 ? "EEE" : "EEEE";
    const days = [];

    let startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
    for (let i = 0; i < 7; i++) {
      let day = addDays(startDate, i);
      let formattedDate = format(day, "d");
      days.push(
        <div className="col col-center" key={i}>
          {`${format(addDays(startDate, i), dateFormat, {
            locale: pt,
          })}` + (semanaMes === 0 ? ` ${formattedDate}` : "")}
        </div>
      );
    }
    return (
      <div
        className="days row"
        style={{
          background: cor_principal_500,
          color: cor_branco,
          fontFamily: fonte,
        }}
      >
        {days}
      </div>
    );
  };

  const renderCells = (semanaMes: any, setSemanaMes: any) => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });

    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < (semanaMes === 0 ? 7 : 28); i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell`}
            key={day.toString()}
            style={{
              paddingTop: "1rem",
              height: `${400 / (semanaMes === 0 ? 1 : 4)}` + "px",
              borderLeft: "1px solid " + cor_preto_100,
              borderRight:
                i === 6 && semanaMes === 0
                  ? "1px solid " + cor_preto_100
                  : (i === 6 || i === 13 || i === 20 || i === 27) &&
                    semanaMes === 1
                  ? "1px solid " + cor_preto_100
                  : "",
              borderBottomLeftRadius:
                i === 0 && semanaMes === 0
                  ? ".5rem"
                  : i === 21 && semanaMes === 1
                  ? ".5rem"
                  : "",
              borderBottomRightRadius:
                i === 6 && semanaMes === 0
                  ? ".5rem"
                  : i === 27 && semanaMes === 1
                  ? ".5rem"
                  : "",
              borderBottom: "1px solid " + cor_preto_100,
              background: cor_branco,
              cursor: "pointer",
            }}
          >
            <div style={{ position: "absolute" }}>
              <div
                style={{
                  position: "relative",
                  left: 5,
                  top: -5,
                  fontSize: "12px",
                }}
              >
                {semanaMes === 1 ? format(day, "d") : ""}
              </div>
            </div>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        marginBottom={"1rem"}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ color: cor_preto_200, fontFamily: fonte }}
          >
            <Grid item>
              <Button
                variant="contained"
                size="small"
                style={{
                  background:
                    semanaMes === 1 ? cor_branco_500 : cor_verde_limao_200,
                  color: cor_preto_300,
                }}
                onClick={(_) => setSemanaMes(0)}
              >
                Semana
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                style={{
                  background:
                    semanaMes === 0 ? cor_branco_500 : cor_verde_limao_200,
                  color: cor_preto_300,
                }}
                onClick={(_) => setSemanaMes(1)}
              >
                Mês
              </Button>
            </Grid>

            <Grid item style={{ paddingLeft: "1.5rem" }}>
              <div
                style={{ cursor: "pointer", marginTop: ".2rem" }}
                onClick={() => changeWeekHandle("next")}
              >
                <NavigateBeforeIcon />
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={(_) => setCurrentMonth(new Date())}
                style={{
                  fontSize: "13px",
                  marginTop: ".25rem",
                  cursor: "pointer",
                }}
              >
                Hoje
              </div>
            </Grid>
            <Grid item>
              <div
                style={{ cursor: "pointer", marginTop: ".2rem" }}
                onClick={() => changeWeekHandle("prev")}
              >
                <NavigateNextIcon />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                background: cor_branco_500,
                color: cor_preto_300,
              }}
              endIcon={<AddIcon />}
            >
              Criar
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  };
  return (
    <div className="calendar">
      {renderFooter()}
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", zIndex: 1, width: "100%" }}>
          {renderDays()}
        </div>
        <div style={{ position: "relative", zIndex: 0, top: 28 }}>
          {renderCells(semanaMes, setSemanaMes)}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
