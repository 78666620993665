import { yupResolver } from "@hookform/resolvers/yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SendIcon from "@mui/icons-material/Send";
import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";

import {
  EntreTexto,
  FaleTexto,
  FaleTextoContato,
  FaleTitulo,
  Guia,
  Perguntas,
  cor_branco,
  cor_fundo,
  cor_preto_100,
  cor_preto_300,
  cor_principal_300,
  cor_principal_500,
  fonte,
  mobile,
} from "../theme";

const Container = ({ texto1, texto2 }: any) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        padding={1.5}
        style={{
          background: cor_branco,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
        }}
        borderRadius={"1rem"}
      >
        <Grid item style={{ marginRight: ".5rem" }}>
          <QuestionMarkIcon
            style={{
              color: cor_preto_300,
              border: `2px solid ${cor_preto_300}`,
              height: "1rem",
              width: "1rem",
              borderRadius: ".2rem",
            }}
          />
        </Grid>
        <Grid item xs={true}>
          <Grid item>
            <FaleTitulo>{texto1}</FaleTitulo>
          </Grid>
          <Grid item>
            <FaleTexto>{texto2}</FaleTexto>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
const schema = yup
  .object()
  .shape({
    nome: yup.string().required("Por favor, preencha este campo."),
    sobrenome: yup.string().required("Por favor, preencha este campo."),
    email: yup.string().required("Por favor, preencha este campo."),
    mensagem: yup.string().required("Por favor, preencha este campo."),
    telefone: yup.string().required("Por favor, preencha este campo."),
  })
  .required();
const FaleConosco = ({ decodedToken }: any) => {
  const { formState, register, handleSubmit } = useForm({
    defaultValues: {
      nome: "",
      sobrenome: "",
      mensagem: "",
      telefone: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    toast.success("Enviando mensagem");
    console.log(data);

    const assunto = encodeURIComponent("Suporte Portal");
    const body = encodeURIComponent(
      `Nome: ${data?.nome}\nSobrenome: ${data?.sobrenome}\nTelefone:${data?.telefone}\nEmail:${data?.email}\n\nMensagem: ${data?.mensagem}`
    );
    window.location.href = `mailto:suporte@sappens.com?subject=${assunto}&body=${body}`;
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={5}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*  style={{ background: cor_branco, padding: 0, margin: 0 }}*/}
          {/*>*/}
          {/*  <MenuSuperior ativo={mobile(windowSize)} texto={"Fale Conosco"} />*/}
          {/*</Grid>*/}
          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} height={"100%"} style={{ marginLeft: "2rem" }}>
                <Perguntas>Perguntas Frequentes</Perguntas>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginRight: "2rem",
                  marginLeft: "2rem",
                  marginTop: ".5rem",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={true} minWidth={230}>
                        <Container
                          texto1={"Não consigo alterar minha foto de perfil."}
                          texto2={
                            "Lorem ipsum dolor sit amet consectetur adipiscing elit molestie dictumst. non penatibus dignissim pharetra nibh neque luctus etiam torquent per."
                          }
                        />
                      </Grid>
                      <Grid item xs={true} minWidth={230}>
                        <Container
                          texto1={"Não consigo alterar minha foto de perfil."}
                          texto2={
                            "Lorem ipsum dolor sit amet consectetur adipiscing elit molestie dictumst. non penatibus dignissim pharetra nibh neque luctus etiam torquent per."
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} marginTop={"-.8rem"}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item xs={true} minWidth={230}>
                        <Container
                          texto1={"Minhas atividades não foram salvas."}
                          texto2={
                            "Lorem ipsum dolor sit amet consectetur adipiscing elit molestie dictumst. non penatibus dignissim pharetra nibh neque luctus etiam torquent per."
                          }
                        />
                      </Grid>
                      <Grid item xs={true} minWidth={230}>
                        <Container
                          texto1={"Minhas atividades não foram salvas."}
                          texto2={
                            "Lorem ipsum dolor sit amet consectetur adipiscing elit molestie dictumst. non penatibus dignissim pharetra nibh neque luctus etiam torquent per."
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginRight: "2rem",
                  marginLeft: "2rem",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={true}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{
                        background: cor_branco,
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                      }}
                      borderRadius={"1rem"}
                      paddingBottom={"1rem"}
                    >
                      <Grid
                        item
                        xs={12}
                        minWidth={210}
                        style={{
                          background: cor_principal_500,
                          borderTopLeftRadius: "1rem",
                          borderTopRightRadius: "1rem",
                        }}
                      >
                        <Grid item paddingLeft={"2rem"}>
                          <EntreTexto>Entre em contato</EntreTexto>
                        </Grid>
                      </Grid>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          paddingLeft={"2rem"}
                          spacing={1}
                          rowSpacing={3}
                          padding={4}
                        >
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                            >
                              <Grid item xs={true}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Nome"
                                  {...register("nome")}
                                  error={Boolean(formState?.errors?.nome)}
                                  helperText={formState.errors.nome?.message}
                                  required
                                  type="text"
                                ></TextField>
                              </Grid>

                              <Grid item xs={true}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Sobrenome"
                                  {...register("sobrenome")}
                                  error={Boolean(formState?.errors?.sobrenome)}
                                  helperText={
                                    formState.errors.sobrenome?.message
                                  }
                                  required
                                  type="text"
                                ></TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                            >
                              <Grid item xs={true}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="E-mail"
                                  {...register("email")}
                                  error={Boolean(formState?.errors?.email)}
                                  helperText={formState.errors.email?.message}
                                  required
                                  type="text"
                                ></TextField>
                              </Grid>
                              <Grid item xs={true}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Telefone"
                                  {...register("telefone")}
                                  error={Boolean(formState?.errors?.telefone)}
                                  helperText={
                                    formState.errors.telefone?.message
                                  }
                                  required
                                  type="text"
                                ></TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs style={{ marginTop: "0rem" }}>
                            <TextField
                              fullWidth
                              size="small"
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: cor_preto_300,
                                },
                                "& label": {
                                  color: cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: cor_principal_500,
                                  },
                                },
                              }}
                              label="Mensagem"
                              {...register("mensagem")}
                              error={Boolean(formState?.errors?.mensagem)}
                              helperText={formState.errors.mensagem?.message}
                              required
                              type="text"
                            ></TextField>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} minWidth={210}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                          >
                            <Grid item>
                              <Button
                                type="submit"
                                endIcon={<SendIcon />}
                                variant={"contained"}
                                style={{
                                  marginRight: "2rem",
                                  background: cor_principal_300,
                                  fontFamily: fonte,
                                  color: cor_branco,
                                  marginTop: "-.5rem",
                                }}
                                size="small"
                              >
                                Enviar
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                      <Grid
                        item
                        xs={12}
                        paddingLeft={"2rem"}
                        marginTop={"1rem"}
                      >
                        <Grid item paddingBottom={".5rem"}>
                          <Guia>Guias e Recursos para Estudantes</Guia>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} paddingLeft={"2rem"}>
                        <Grid item paddingBottom={".5rem"}>
                          <Guia>Guias e Recursos para Professores</Guia>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={true}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{
                        background: cor_branco,
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                      }}
                      borderRadius={"1rem"}
                      paddingBottom={"1rem"}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          background: cor_principal_500,
                          borderTopLeftRadius: "1rem",
                          borderTopRightRadius: "1rem",
                        }}
                      >
                        <Grid item paddingLeft={"2rem"}>
                          <EntreTexto>Entre em contato</EntreTexto>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        paddingLeft={"1.5rem"}
                        marginTop={"1rem"}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item>
                            <PhoneIcon
                              style={{
                                color: cor_preto_300,
                                paddingLeft: ".3rem",
                              }}
                            />
                          </Grid>
                          <Grid item paddingBottom={".5rem"}>
                            <FaleTextoContato>(62) 3142-6510</FaleTextoContato>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={true}
                        paddingLeft={"2rem"}
                        marginTop={"1rem"}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <MailOutlineIcon style={{ color: cor_preto_300 }} />
                          </Grid>
                          <Grid item>
                            <FaleTextoContato>
                              suporte@sappens.com
                            </FaleTextoContato>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        paddingLeft={"2rem"}
                        marginTop={"1rem"}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item>
                            <PhoneAndroidIcon
                              style={{ color: cor_preto_300 }}
                            />
                          </Grid>
                          <Grid item paddingBottom={".5rem"}>
                            <FaleTextoContato>(61) 99998-9782</FaleTextoContato>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FaleConosco;
