import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SendIcon from "@mui/icons-material/Send";
import TuneIcon from "@mui/icons-material/Tune";
import { ListItemContent, ListItemDecorator } from "@mui/joy";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import Maca from "../../img/maki_school.png";
import api from "../axios/axios";
import Carregar, { CarregarLoading } from "../carregar";
import Deletar from "../confirmar/deletar";
import Erro from "../erro";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import {
  cor_amarelo_400,
  cor_branco,
  cor_branco_300,
  cor_branco_400,
  cor_fundo,
  cor_preto,
  cor_preto_100,
  cor_preto_200,
  cor_preto_300,
  cor_preto_400,
  cor_principal_200,
  cor_principal_500,
  cor_verde_limao_300,
  cor_vermelho_500,
  fonte,
  mobile,
} from "../theme";
import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const schema = yup
  .object()
  .shape({
    titulo: yup.string().required("Por favor, preencha este campo."),
    mensagem: yup.string().required("Por favor, preencha este campo."),
    tipo: yup.string().required("Por favor, preencha este campo."),
    id_cidade: yup.string().nullable(),
    id_escola: yup.string().nullable(),
    id_keycloak: yup.string().nullable(),
  })
  .required();

const Recados = ({ decodedToken }: any) => {
  const [configuracao, setConfiguracao] = useState<any>({
    filtro: false,
    cidadeSelecao: {
      id: -1,
      nome: "Nenhuma cidade",
    },
    escolaSelecao: {
      id: -1,
      nome: "Nenhuma escola",
    },
    pessoaSelecao: {
      id_keycloak: -1,
      nome: "Nenhuma pessoa",
    },
    recadosProfessores: {
      items: [],
      total: 0,
    },
    recadosEstudantes: {
      items: [],
      total: 0,
    },
    selecao: "professor",
    avancado: false,
    carregarMais: false,
    enviar: false,
    loading: true,
    erro: false,
    loadingRecado: true,
    loadingRecadoFiltro: false,
  });
  const [openItem, setOpenItem] = useState(false);
  const handleCloseItem = () => setOpenItem(false);

  const limpar = () => {
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        loadingRecado: true,
        loadingRecadoFiltro: true,
        filtro: false,
      };
    });
    if (configuracao?.selecao === "professor") {
      api
        .get(`/recado/tipo/professor`)
        .then((r1) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              recadosProfessores: r1?.data,
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            };
          });
          const fila = [];

          if (r1?.data?.items?.length > 0) {
            fila.push(r1.data.items[0]);
          } else {
            fila.push(null);
          }
          setOpenItem(false);
          setFila(fila);
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
              loadingRecado: false,
              loadingRecadoFiltro: false,
            };
          })
        );

      if (configuracao?.avancado) {
        api.get("cidade").then((r) => {
          r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              cidade: r.data,
              escola: {
                items: [],
              },
              pessoa: {
                items: [],
              },
            };
          });
        });
      }
    } else {
      api
        .get(`/recado/tipo/aluno`)
        .then((r2) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              recadosEstudantes: r2?.data,
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            };
          });

          const fila = [];

          if (r2?.data?.items?.length > 0) {
            fila.push(r2.data.items[0]);
          } else {
            fila.push(null);
          }
          setOpenItem(false);
          setFila(fila);
          if (configuracao?.avancado) {
            api.get("cidade").then((r) => {
              r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  cidade: r.data,
                  escola: {
                    items: [],
                  },
                  pessoa: {
                    items: [],
                  },
                };
              });
            });
          }
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingRecado: false,
              loadingRecadoFiltro: false,
            };
          })
        );
    }
  };
  const buscar = () => {
    const url = `recado/filtro?${
      configuracao?.cidadeSelecao?.id !== -1
        ? "id_cidade=" + configuracao?.cidadeSelecao?.id
        : ""
    }${
      configuracao?.escolaSelecao?.id !== -1
        ? "&id_escola=" + configuracao?.escolaSelecao?.id
        : ""
    }${
      configuracao?.pessoaSelecao?.id_keycloak !== -1
        ? "&id_pessoa=" + configuracao?.pessoaSelecao?.id_keycloak
        : ""
    } ${
      configuracao?.selecao === "professor" ? "&tipo=professor" : "&tipo=aluno"
    }`;
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        enviar: true,
        loadingRecadoFiltro: true,
      };
    });

    api
      .get(url)
      .then((r) => {
        if (configuracao?.selecao === "professor") {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              recadosProfessores: r.data,
              filtro: true,
            };
          });
        } else {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              recadosEstudantes: r.data,
              filtro: true,
            };
          });
        }
        const fila = [];

        if (r?.data?.items?.length > 0) {
          fila.push(r.data.items[0]);
        } else {
          fila.push(null);
        }

        setFila(fila);
      })
      .then(() => {
        setOpenItem(false);
      })
      .finally(() => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loadingRecadoFiltro: false,
            enviar: false,
          };
        });
      });
  };

  useEffect(() => {
    if (configuracao?.avancado) {
      api.get("cidade").then((r) => {
        r.data.items.unshift({ id: -1, nome: "Nenhuma cidade" });
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            cidade: r.data,
          };
        });
      });
    }
  }, [configuracao?.avancado]);

  useEffect(() => {
    if (configuracao?.cidadeSelecao?.id !== -1) {
      api.get("cidade/escola/" + configuracao?.cidadeSelecao?.id).then((r) => {
        r.data.items.unshift({ id: -1, nome: "Nenhuma escola" });
        setConfiguracao((antes: any) => {
          return { ...antes, escola: r.data };
        });
      });
    }
  }, [configuracao?.cidadeSelecao]);

  useEffect(() => {
    if (configuracao?.escolaSelecao?.id !== -1) {
      api
        .get(
          `cidade/escola/pessoa/${
            configuracao?.selecao === "professor" ? "professor" : "aluno"
          }/${configuracao?.escolaSelecao?.id}`
        )
        .then((r) => {
          r.data.items.unshift({ id_keycloak: -1, nome: "Nenhuma pessoa" });
          setConfiguracao((antes: any) => {
            return { ...antes, pessoa: r.data };
          });
        });
    }
  }, [configuracao?.escolaSelecao]);

  useEffect(() => {
    limpar();
  }, [configuracao?.selecao]);

  const [fila, setFila] = useState<any>();
  const defaultValues = {
    titulo: "",
    mensagem: "",
    tipo: configuracao?.selecao === "professor" ? "professor" : "aluno",
    id_cidade: undefined,
    id_escola: undefined,
    id_keycloak: undefined,
  };
  const { formState, register, handleSubmit, reset, getValues } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [open, setOpen] = useState(false);
  const [itemFuncao, setItemFuncao] = useState<any>();

  const funcaoDeletar = () => {
    api.delete("recado\\" + itemFuncao?.id).then((_) => {
      if (itemFuncao?.tipo === "professor") {
        let recado = null;
        if (configuracao?.recadosProfessores.length > 1)
          recado = configuracao?.recadosProfessores[1];
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            recadosProfessores: {
              ...antes.recadosProfessores,
              items: antes?.recadosProfessores?.items.filter(
                (e: any) => e.id !== itemFuncao?.id
              ),
              total: antes?.recadosProfessores?.total - 1,
            },
          };
        });
        setFila([recado, null]);
      } else {
        let recado = null;
        if (configuracao?.recadosEstudantes.length > 1)
          recado = configuracao?.recadosEstudantes[1];
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            recadosEstudantes: {
              ...antes.recadosEstudantes,
              items: antes?.recadosEstudantes?.items.filter(
                (e: any) => e.id !== itemFuncao?.id
              ),
              total: antes?.recadosEstudantes?.total - 1,
            },
          };
        });

        setFila([recado, fila[1]]);
      }
      reset(defaultValues, {
        keepDirtyValues: false,
      });
    });
  };

  const handleClickOpen = (item: any) => {
    setItemFuncao(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    setConfiguracao((antes: any) => {
      return { ...antes, enviar: true };
    });
    data.tipo = configuracao?.selecao === "professor" ? "professor" : "aluno";
    data.id_cidade =
      configuracao?.cidadeSelecao?.id !== -1
        ? configuracao?.cidadeSelecao?.id
        : null;
    data.id_escola =
      configuracao?.escolaSelecao?.id !== -1
        ? configuracao?.escolaSelecao?.id
        : null;
    data.id_keycloak =
      configuracao?.pessoaSelecao?.id_keycloak !== -1
        ? configuracao?.pessoaSelecao?.id_keycloak
        : null;
    let outraSelecao = false;
    if (data?.id) {
      api
        .patch("recado\\" + data.id, JSON.stringify(data))
        .then((_) => {
          if (configuracao?.selecao === "professor") {
            setConfiguracao((antes: any) => ({
              ...antes,
              recadosProfessores: {
                ...antes?.recadosProfessores,
                items: antes?.recadosProfessores?.items.map((e: any) =>
                  e?.id === _.data.id ? _.data : e
                ),
              },
              cidadeSelecao: {
                id: -1,
                nome: "Nenhuma cidade",
              },
              escolaSelecao: {
                id: -1,
                nome: "Nenhuma escola",
              },
              pessoaSelecao: {
                id_keycloak: -1,
                nome: "Nenhuma pessoa",
              },
            }));
            if (fila[0].id === _.data.id) {
              setFila([_.data, null]);
            }
          } else {
            if (configuracao?.filtro === false) {
              setConfiguracao((antes: any) => ({
                ...antes,
                recadosEstudantes: {
                  ...antes?.recadosEstudantes,
                  items: antes?.recadosEstudantes?.items.map((e: any) =>
                    e?.id === _.data.id ? _.data : e
                  ),
                },
                cidadeSelecao: {
                  id: -1,
                  nome: "Nenhuma cidade",
                },
                escolaSelecao: {
                  id: -1,
                  nome: "Nenhuma escola",
                },
                pessoaSelecao: {
                  id_keycloak: -1,
                  nome: "Nenhuma pessoa",
                },
              }));
            } else {
              let recadoAnterior =
                configuracao?.recadosEstudantes?.items?.filter((e: any) =>
                  e?.id === _.data.id ? _.data : e
                )[0];
              setConfiguracao((antes: any) => ({
                ...antes,
                recadosEstudantes: {
                  ...antes?.recadosEstudantes,
                  items: antes?.recadosEstudantes?.items
                    .map((e: any) => (e?.id === _.data.id ? _.data : e))
                    .filter((e: any) => {
                      if (e?.id !== recadoAnterior?.id) {
                        return true;
                      }

                      if (e?.id_keycloak !== recadoAnterior?.id_keycloak) {
                        outraSelecao = true;
                        return false;
                      } else if (e?.id_escola !== recadoAnterior?.id_escola) {
                        outraSelecao = true;
                        return false;
                      } else if (e?.id_cidade !== recadoAnterior?.id_cidade) {
                        outraSelecao = true;
                        return false;
                      } else {
                        return true;
                      }
                    }),
                  total:
                    antes?.total -
                    antes?.recadosEstudantes?.items
                      .map((e: any) => (e?.id === _.data.id ? _.data : e))
                      .filter((e: any) => {
                        if (e?.id !== recadoAnterior?.id) {
                          return true;
                        }

                        if (e?.id_keycloak !== recadoAnterior?.id_keycloak) {
                          return false;
                        } else if (e?.id_escola !== recadoAnterior?.id_escola) {
                          return false;
                        } else if (e?.id_cidade !== recadoAnterior?.id_cidade) {
                          return false;
                        } else {
                          return true;
                        }
                      }).length,
                },
              }));
            }

            if (
              outraSelecao === false &&
              configuracao.filtro === true &&
              fila[0].id === _.data.id
            ) {
              setFila([_.data, null]);
            } else if (outraSelecao) {
              setFila([null, null]);
            }
          }
          reset(defaultValues, { keepDirtyValues: false });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, enviar: false };
          })
        );
    } else {
      data = api
        .post("recado", JSON.stringify(data))
        .then((_) => {
          toast.success("Recado criado");
          reset(defaultValues, { keepDirtyValues: false });
          if (configuracao?.selecao === "professor") {
            if (configuracao?.filtro === false) {
              setConfiguracao((antes: any) => ({
                ...antes,
                recadosProfessores: {
                  ...antes?.recadosProfessores,
                  items: [_.data, ...antes?.recadosProfessores?.items],
                },
                cidadeSelecao: {
                  id: -1,
                  nome: "Nenhuma cidade",
                },
                escolaSelecao: {
                  id: -1,
                  nome: "Nenhuma escola",
                },
                pessoaSelecao: {
                  id_keycloak: -1,
                  nome: "Nenhuma pessoa",
                },
              }));
            } else {
              setConfiguracao((antes: any) => ({
                ...antes,
                recadosProfessores: {
                  ...antes?.recadosProfessores,
                  items: [_.data, ...antes?.recadosProfessores?.items],
                },
              }));
            }
          } else if (configuracao?.selecao === "aluno") {
            if (configuracao?.filtro === false) {
              setConfiguracao((antes: any) => ({
                ...antes,
                recadosEstudantes: {
                  ...antes?.recadosEstudantes,
                  items: [_.data, ...antes?.recadosEstudantes?.items],
                },
                cidadeSelecao: {
                  id: -1,
                  nome: "Nenhuma cidade",
                },
                escolaSelecao: {
                  id: -1,
                  nome: "Nenhuma escola",
                },
                pessoaSelecao: {
                  id_keycloak: -1,
                  nome: "Nenhuma pessoa",
                },
              }));
            } else {
              setConfiguracao((antes: any) => ({
                ...antes,
                recadosEstudantes: {
                  ...antes?.recadosEstudantes,
                  items: [_.data, ...antes?.recadosEstudantes?.items],
                },
              }));
            }
          }
        })
        .catch((_) => toast.error("Recado não criado"))
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, enviar: false };
          })
        );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePage = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });

    api
      .get(`/recado/tipo/${configuracao?.selecao}?page=${page}`)
      .then((r1) => {
        setConfiguracao((antes: any) => {
          if (configuracao?.selecao === "professor") {
            const updatedRecadosProfessores = {
              items: [
                ...(antes?.recadosProfessores?.items || []),
                ...r1?.data?.items,
              ],
              total: r1?.data?.total,
            };

            return {
              ...antes,
              recadosProfessores: updatedRecadosProfessores,
            };
          } else if (configuracao?.selecao === "aluno") {
            const updatedRecadosEstudantes = {
              items: [
                ...(antes?.recadosEstudantes?.items || []),
                ...r1?.data?.items,
              ],
              total: r1?.data?.total,
            };

            return {
              ...antes,
              recadosEstudantes: updatedRecadosEstudantes,
            };
          }

          return antes;
        });
      })
      .finally(() => {
        setConfiguracao((antes: any) => {
          return { ...antes, carregarMais: false };
        });
      });
  };

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      <React.Fragment>
        <Dialog
          onClose={handleCloseItem}
          open={openItem}
          PaperProps={{
            sx: {
              borderRadius: "1.5rem",
              minHeight: "45%",
              minWidth: "50%",
              background: cor_branco,
            },
          }}
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
            <IconButton
              aria-label="close"
              onClick={handleCloseItem}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: cor_preto_400,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              color: cor_preto_300,
              fontFamily: fonte,
              fontSize: "18px",

              paddingTop: "0rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
              padding: "2rem",
            }}
          >
            <Grid item xs={12} paddingLeft={"3rem"} paddingTop={"1rem"}>
              <Grid item xs={11}>
                <FormControl fullWidth size="small">
                  <InputLabel style={{ color: cor_preto }}>Cidade</InputLabel>
                  <Select
                    value={configuracao?.cidadeSelecao?.id}
                    disabled={configuracao?.cidade?.items?.length === 0}
                    label="Cidade"
                    name="cidade"
                    style={{ color: cor_preto }}
                  >
                    {configuracao?.cidade?.items?.length > 0 ? (
                      configuracao?.cidade?.items?.map((e: any) => (
                        <MenuItem
                          onClick={(_) => {
                            setConfiguracao((antes: any) => {
                              return {
                                ...antes,
                                cidadeSelecao: e,
                                escolaSelecao: {
                                  id: -1,
                                  nome: "Nenhuma escola",
                                },
                                pessoaSelecao: {
                                  id_keycloak: -1,
                                  nome: "Nenhuma pessoa",
                                },
                                escola: {
                                  items: [],
                                },
                                pessoa: {
                                  items: [],
                                },
                              };
                            });
                          }}
                          key={e.id}
                          value={e.id}
                        >
                          {e.nome}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={-1} disabled>
                        Nenhuma cidade
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} style={{ marginTop: "1rem" }}>
                <FormControl fullWidth size="small">
                  <InputLabel style={{ color: cor_preto }}>Escola</InputLabel>
                  <Select
                    id="escola-filtro"
                    value={configuracao?.escolaSelecao?.id}
                    label="Escola"
                    name="escola"
                    style={{ color: cor_preto }}
                    disabled={
                      configuracao?.cidadeSelecao?.id === -1 ? true : false
                    }
                  >
                    {configuracao?.escola?.items?.length > 0 ? (
                      configuracao?.escola?.items?.map((e: any) => (
                        <MenuItem
                          onClick={(_) => {
                            setConfiguracao((antes: any) => {
                              return {
                                ...antes,
                                escolaSelecao: e,
                                pessoaSelecao: {
                                  id_keycloak: -1,
                                  nome: "Nenhuma pessoa",
                                },
                                pessoa: {
                                  items: [],
                                },
                              };
                            });
                          }}
                          key={e.id}
                          value={e.id}
                        >
                          {e.nome}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={-1} disabled>
                        Nenhuma escola
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} style={{ marginTop: "1rem" }}>
                {configuracao?.selecao === "professor" ? (
                  <FormControl fullWidth size="small">
                    <InputLabel style={{ color: cor_preto }}>
                      Professor
                    </InputLabel>

                    <Select
                      id="professor-filtro"
                      value={configuracao?.pessoaSelecao?.id_keycloak}
                      label="Professor"
                      name="professor"
                      style={{ color: cor_preto }}
                      disabled={
                        configuracao?.cidadeSelecao?.id === -1 ||
                        configuracao?.escolaSelecao?.id === -1
                          ? true
                          : false
                      }
                    >
                      {configuracao?.pessoa?.items?.length > 0 ? (
                        configuracao?.pessoa?.items?.map((e: any) => (
                          <MenuItem
                            onClick={(_) =>
                              setConfiguracao((antes: any) => {
                                return {
                                  ...antes,
                                  pessoaSelecao: e,
                                };
                              })
                            }
                            key={e?.id_keycloak}
                            value={e?.id_keycloak}
                          >
                            {e?.nome}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={-1} disabled>
                          Nenhuma pessoa
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth size="small">
                    <InputLabel style={{ color: cor_preto }}>
                      Estudante
                    </InputLabel>

                    <Select
                      id="estudante-filtro"
                      value={configuracao?.pessoaSelecao?.id_keycloak}
                      label="Estudante"
                      name="estudante"
                      style={{ color: cor_preto }}
                      disabled={
                        configuracao?.cidadeSelecao?.id === -1 ||
                        configuracao?.escolaSelecao?.id === -1
                          ? true
                          : false
                      }
                    >
                      {configuracao?.pessoa?.items?.length > 0 ? (
                        configuracao?.pessoa?.items?.map((e: any) => (
                          <MenuItem
                            onClick={(_) =>
                              setConfiguracao((antes: any) => {
                                return {
                                  ...antes,
                                  pessoaSelecao: e,
                                };
                              })
                            }
                            key={e?.id_keycloak}
                            value={e?.id_keycloak}
                          >
                            {e?.nome}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={-1} disabled>
                          Nenhuma pessoa
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} paddingLeft={"3rem"} paddingTop={"2rem"}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    endIcon={<FilterAltIcon style={{ color: cor_branco }} />}
                    style={{
                      background: cor_principal_500,
                      fontFamily: fonte,
                      color: cor_branco,
                    }}
                    loading={configuracao?.enviar}
                    loadingPosition="end"
                    onClick={() => {
                      buscar();
                    }}
                  >
                    Filtrar
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    endIcon={<CloseIcon style={{ color: cor_branco }} />}
                    style={{
                      background: cor_vermelho_500,
                      fontFamily: fonte,
                      color: cor_branco,
                    }}
                    onClick={() => limpar()}
                    loading={configuracao?.enviar}
                    loadingPosition="end"
                  >
                    Limpar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
      <Deletar
        open={open}
        handleClose={handleClose}
        item={itemFuncao}
        funcaoDeletar={funcaoDeletar}
        titulo={true}
      />
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={9}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto", width: "100%" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            height={"100%"}
          >
            <Grid
              item
              xs={12}
              marginTop={mobile(windowSize) ? "" : "4.5rem"}
              paddingBottom={"1rem"}
            >
              <MenuPhone
                decodedToken={decodedToken}
                ativo={mobile(windowSize) ? true : false}
              />
              <Grid
                item
                xs={12}
                style={{ background: cor_branco, padding: 0, margin: 0 }}
              >
                <MenuSuperior ativo={mobile(windowSize)} texto={"Recados"} />
              </Grid>
              <Grid item xs={true} paddingLeft={2} paddingRight={2}>
                <Grid
                  item
                  xs={true}
                  style={{
                    background: cor_branco,
                    borderRadius: "1rem",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Grid
                    container
                    direction={mobile(windowSize) ? "row" : "column"}
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={2}
                    marginTop={2}
                  >
                    <Grid item>
                      <Button
                        onClick={(_) => {
                          // reset(defaultValues);
                          setConfiguracao((antes: any) => ({
                            ...antes,
                            selecao: "professor",
                            cidade: {
                              items: [],
                            },
                            escola: {
                              items: [],
                            },
                            pessoa: {
                              items: [],
                            },
                            recadosEstudantes: {
                              items: [],
                              total: 0,
                            },
                            cidadeSelecao: {
                              id: -1,
                              nome: "Nenhuma cidade",
                            },
                            escolaSelecao: {
                              id: -1,
                              nome: "Nenhuma escola",
                            },
                            pessoaSelecao: {
                              id_keycloak: -1,
                              nome: "Nenhuma pessoa",
                            },
                          }));
                        }}
                        style={{
                          background:
                            configuracao?.selecao === "professor"
                              ? cor_branco_300
                              : "",
                          fontFamily: fonte,
                          color: cor_preto_300,
                        }}
                      >
                        PROFESSORES
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={(_) => {
                          reset(defaultValues);
                          setConfiguracao((antes: any) => ({
                            ...antes,
                            selecao: "aluno",
                            cidade: {
                              items: [],
                            },
                            escola: {
                              items: [],
                            },
                            pessoa: {
                              items: [],
                            },
                            recadosProfessores: {
                              items: [],
                              total: 0,
                            },
                            cidadeSelecao: {
                              id: -1,
                              nome: "Nenhuma cidade",
                            },
                            escolaSelecao: {
                              id: -1,
                              nome: "Nenhuma escola",
                            },
                            pessoaSelecao: {
                              id_keycloak: -1,
                              nome: "Nenhuma pessoa",
                            },
                          }));
                        }}
                        style={{
                          background:
                            configuracao?.selecao === "aluno"
                              ? cor_branco_300
                              : "",
                          fontFamily: fonte,
                          color: cor_preto_300,
                        }}
                      >
                        ESTUDANTES
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={mobile(windowSize) ? "row" : "column"}
                    alignItems="stretch"
                  >
                    <Grid
                      item
                      xs={4}
                      style={{
                        background: cor_principal_200,
                        borderTop: "1px",
                        borderColor: cor_preto,
                        borderBottomLeftRadius: mobile(windowSize)
                          ? "1rem"
                          : "",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        padding={2}
                      >
                        <Grid item xs={12}>
                          <p
                            style={{
                              fontFamily: fonte,
                              color: cor_preto_300,
                              fontSize: "20px",
                            }}
                          >
                            Escrever Recado
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          {configuracao?.selecao === "professor" ? (
                            <p
                              style={{
                                fontFamily: fonte,
                                color: cor_preto_300,
                                marginTop: 0,
                              }}
                            >
                              Envie um recado para os professores da rede.
                            </p>
                          ) : (
                            <p
                              style={{
                                fontFamily: fonte,
                                color: cor_preto_300,
                                marginTop: 0,
                              }}
                            >
                              Envie um recado para os estudantes da rede.
                            </p>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            style={{ width: "100%" }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...register("titulo")}
                                error={Boolean(formState?.errors?.titulo)}
                                helperText={formState.errors.titulo?.message}
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: cor_preto_300,
                                  },
                                  "& label": {
                                    color: cor_preto_300,
                                  },
                                  "& label.Mui-focused": {
                                    color: cor_principal_500,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: cor_principal_500,
                                      borderRadius: "1rem",
                                    },
                                    "& fieldset": {
                                      borderColor: cor_preto_100,
                                      borderRadius: "1rem",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: cor_principal_500,
                                      borderRadius: "1rem",
                                    },
                                  },
                                  fieldset: {
                                    border: 0,
                                  },
                                  background: cor_branco,
                                  borderRadius: "1rem",
                                  border: 0,
                                }}
                                placeholder="Escreva o título da sua mensagem aqui."
                                multiline
                                rows={2}
                                type="text"
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "1rem" }}>
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...register("mensagem")}
                                error={Boolean(formState?.errors?.mensagem)}
                                helperText={formState.errors.mensagem?.message}
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: cor_preto_300,
                                  },
                                  "& label": {
                                    color: cor_preto_300,
                                  },
                                  "& label.Mui-focused": {
                                    color: cor_principal_500,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: cor_principal_500,
                                      borderRadius: "1rem",
                                    },
                                    "& fieldset": {
                                      borderColor: cor_preto_100,
                                      borderRadius: "1rem",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: cor_principal_500,
                                      borderRadius: "1rem",
                                    },
                                  },
                                  fieldset: {
                                    border: 0,
                                  },
                                  background: cor_branco,
                                  borderRadius: "1rem",
                                  border: 0,
                                }}
                                placeholder="Escreva sua mensagem aqui..."
                                multiline
                                rows={8}
                                type="text"
                              ></TextField>
                              <Grid item xs={12} style={{ marginTop: "1rem" }}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Button
                                      size="small"
                                      endIcon={
                                        <ScheduleIcon
                                          style={{ color: cor_preto_300 }}
                                        />
                                      }
                                      style={{
                                        fontFamily: fonte,
                                        color: cor_preto_300,
                                        border: "1px solid " + cor_preto_300,
                                      }}
                                    >
                                      PROGRAMAR
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      size="small"
                                      endIcon={<CloseIcon />}
                                      variant="contained"
                                      style={{
                                        color: cor_branco_400,
                                        borderColor: cor_branco_400,
                                        fontFamily: fonte,
                                        background: cor_vermelho_500,
                                      }}
                                      onClick={(_) => {
                                        setConfiguracao((antes: any) => {
                                          return {
                                            ...antes,
                                            cidadeSelecao: {
                                              id: -1,
                                              nome: "Nenhuma cidade",
                                            },
                                            escolaSelecao: {
                                              id: -1,
                                              nome: "Nenhuma escola",
                                            },
                                            pessoaSelecao: {
                                              id_keycloak: -1,
                                              nome: "Nenhuma pessoa",
                                            },
                                          };
                                        });
                                        reset(defaultValues);
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <LoadingButton
                                      variant="contained"
                                      size="small"
                                      type="submit"
                                      endIcon={
                                        <SendIcon
                                          style={{ color: cor_branco_400 }}
                                        />
                                      }
                                      style={{
                                        background: cor_principal_500,
                                        fontFamily: fonte,
                                        color: cor_branco_400,
                                      }}
                                      loading={configuracao?.enviar}
                                      loadingPosition="end"
                                    >
                                      ENVIAR
                                    </LoadingButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  marginTop: "2rem",
                                  paddingLeft: "1rem",
                                  color: cor_preto_300,
                                  fontFamily: fonte,
                                  fontStyle: "italic",
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    onClick={(_) =>
                                      setConfiguracao((antes: any) => {
                                        return {
                                          ...antes,
                                          avancado: !antes?.avancado,
                                        };
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span>Ajustes Avançados </span>
                                  </Grid>
                                  <Grid
                                    item
                                    onClick={(_) =>
                                      setConfiguracao((antes: any) => {
                                        return {
                                          ...antes,
                                          avancado: !antes?.avancado,
                                        };
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                    paddingLeft={"1rem"}
                                  >
                                    {!configuracao?.avancado ? (
                                      <KeyboardArrowDownIcon
                                        style={{ marginTop: ".5rem" }}
                                      />
                                    ) : (
                                      <KeyboardArrowUpIcon
                                        style={{ marginTop: ".5rem" }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {configuracao?.avancado ? (
                                <Grid
                                  item
                                  xs={12}
                                  paddingLeft={"3rem"}
                                  paddingTop={"1rem"}
                                >
                                  <Grid item xs={11}>
                                    <FormControl fullWidth size="small">
                                      <InputLabel style={{ color: cor_preto }}>
                                        Cidade
                                      </InputLabel>
                                      <Select
                                        value={configuracao?.cidadeSelecao?.id}
                                        disabled={
                                          configuracao?.cidade?.items
                                            ?.length === 0
                                        }
                                        label="Cidade"
                                        name="cidade"
                                        style={{ color: cor_preto }}
                                      >
                                        {configuracao?.cidade?.items?.length >
                                        0 ? (
                                          configuracao?.cidade?.items?.map(
                                            (e: any) => (
                                              <MenuItem
                                                onClick={(_) => {
                                                  setConfiguracao(
                                                    (antes: any) => {
                                                      return {
                                                        ...antes,
                                                        cidadeSelecao: e,
                                                        escolaSelecao: {
                                                          id: -1,
                                                          nome: "Nenhuma escola",
                                                        },
                                                        pessoaSelecao: {
                                                          id_keycloak: -1,
                                                          nome: "Nenhuma pessoa",
                                                        },
                                                        escola: {
                                                          items: [],
                                                        },
                                                        pessoa: {
                                                          items: [],
                                                        },
                                                      };
                                                    }
                                                  );
                                                }}
                                                key={e.id}
                                                value={e.id}
                                              >
                                                {e.nome}
                                              </MenuItem>
                                            )
                                          )
                                        ) : (
                                          <MenuItem value={-1} disabled>
                                            Nenhuma cidade
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={11}
                                    style={{ marginTop: "1rem" }}
                                  >
                                    <FormControl fullWidth size="small">
                                      <InputLabel style={{ color: cor_preto }}>
                                        Escola
                                      </InputLabel>
                                      <Select
                                        id="escola"
                                        value={configuracao?.escolaSelecao?.id}
                                        label="Escola"
                                        name="escola"
                                        style={{ color: cor_preto }}
                                        disabled={
                                          configuracao?.cidadeSelecao?.id === -1
                                            ? true
                                            : false
                                        }
                                      >
                                        {configuracao?.escola?.items?.length >
                                        0 ? (
                                          configuracao?.escola?.items?.map(
                                            (e: any) => (
                                              <MenuItem
                                                onClick={(_) => {
                                                  setConfiguracao(
                                                    (antes: any) => {
                                                      return {
                                                        ...antes,
                                                        escolaSelecao: e,
                                                        pessoaSelecao: {
                                                          id_keycloak: -1,
                                                          nome: "Nenhuma pessoa",
                                                        },
                                                        pessoa: {
                                                          items: [],
                                                        },
                                                      };
                                                    }
                                                  );
                                                }}
                                                key={e.id}
                                                value={e.id}
                                              >
                                                {e.nome}
                                              </MenuItem>
                                            )
                                          )
                                        ) : (
                                          <MenuItem value={-1} disabled>
                                            Nenhuma escola
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={11}
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {configuracao?.selecao === "professor" ? (
                                      <FormControl fullWidth size="small">
                                        <InputLabel
                                          style={{ color: cor_preto }}
                                        >
                                          Professor
                                        </InputLabel>

                                        <Select
                                          id="professor"
                                          value={
                                            configuracao?.pessoaSelecao
                                              ?.id_keycloak
                                          }
                                          label="Professor"
                                          name="professor"
                                          style={{ color: cor_preto }}
                                          disabled={
                                            configuracao?.cidadeSelecao?.id ===
                                              -1 ||
                                            configuracao?.escolaSelecao?.id ===
                                              -1
                                              ? true
                                              : false
                                          }
                                        >
                                          {configuracao?.pessoa?.items?.length >
                                          0 ? (
                                            configuracao?.pessoa?.items?.map(
                                              (e: any) => (
                                                <MenuItem
                                                  onClick={(_) =>
                                                    setConfiguracao(
                                                      (antes: any) => {
                                                        return {
                                                          ...antes,
                                                          pessoaSelecao: e,
                                                        };
                                                      }
                                                    )
                                                  }
                                                  key={e?.id_keycloak}
                                                  value={e?.id_keycloak}
                                                >
                                                  {e?.nome}
                                                </MenuItem>
                                              )
                                            )
                                          ) : (
                                            <MenuItem value={-1} disabled>
                                              Nenhuma pessoa
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    ) : (
                                      <FormControl fullWidth size="small">
                                        <InputLabel
                                          style={{ color: cor_preto }}
                                        >
                                          Estudante
                                        </InputLabel>

                                        <Select
                                          id="estudante"
                                          value={
                                            configuracao?.pessoaSelecao
                                              ?.id_keycloak
                                          }
                                          label="Estudante"
                                          name="estudante"
                                          style={{ color: cor_preto }}
                                          disabled={
                                            configuracao?.cidadeSelecao?.id ===
                                              -1 ||
                                            configuracao?.escolaSelecao?.id ===
                                              -1
                                              ? true
                                              : false
                                          }
                                        >
                                          {configuracao?.pessoa?.items?.length >
                                          0 ? (
                                            configuracao?.pessoa?.items?.map(
                                              (e: any) => (
                                                <MenuItem
                                                  onClick={(_) =>
                                                    setConfiguracao(
                                                      (antes: any) => {
                                                        return {
                                                          ...antes,
                                                          pessoaSelecao: e,
                                                        };
                                                      }
                                                    )
                                                  }
                                                  key={e?.id_keycloak}
                                                  value={e?.id_keycloak}
                                                >
                                                  {e?.nome}
                                                </MenuItem>
                                              )
                                            )
                                          ) : (
                                            <MenuItem value={-1} disabled>
                                              Nenhuma pessoa
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    )}
                                  </Grid>
                                </Grid>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        borderTop: ".1px solid " + cor_branco_400,
                        borderRight: mobile(windowSize)
                          ? ".1px solid " + cor_branco_400
                          : "",
                        padding: "1rem",
                      }}
                    >
                      {/* <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              size="small"
                              type="submit"
                              endIcon={
                                <TuneIcon style={{ color: cor_preto_200 }} />
                              }
                              style={{
                                background: cor_branco_300,
                                fontFamily: fonte,
                                color: cor_preto_200,
                              }}
                              onClick={() => {
                                setOpenItem(true);
                                setConfiguracao((antes: any) => {
                                  return {
                                    ...antes,
                                    avancado: true,
                                  };
                                });
                              }}
                            >
                              FILTRAR
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid> */}

                      <List
                        aria-labelledby="ellipsis-list-demo"
                        sx={{
                          "--ListItemDecorator-size": "56px",
                          marginLeft: "-1rem",
                        }}
                      >
                        {configuracao?.loadingRecado ||
                        configuracao?.loadingRecadoFiltro === true ? (
                          <CarregarLoading />
                        ) : (
                          ""
                        )}
                        {configuracao?.selecao === "aluno" &&
                        configuracao?.loadingRecadoFiltro === false
                          ? configuracao?.recadosEstudantes?.items?.map(
                              (item: any, index: any) => (
                                <ListItem
                                  key={item.id}
                                  style={{ cursor: "pointer" }}
                                  onClick={(_) => {
                                    setFila([item, null]);
                                  }}
                                >
                                  <ListItemDecorator>
                                    <Avatar
                                      style={{
                                        backgroundColor: cor_branco_300,
                                        padding: ".3rem",
                                      }}
                                    >
                                      <img
                                        src={Maca}
                                        alt="Icone Maça e Lápis"
                                        style={{
                                          width: "2rem",
                                          height: "auto",
                                        }}
                                      />
                                    </Avatar>
                                  </ListItemDecorator>
                                  <ListItemContent>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                    >
                                      <Grid item xs={true}>
                                        <Grid
                                          item
                                          style={{
                                            fontWeight: 600,
                                            fontFamily: fonte,
                                            color: cor_preto_400,
                                            paddingRight: ".5rem",
                                          }}
                                        >
                                          {item?.titulo}
                                        </Grid>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <span
                                              style={{
                                                fontFamily: fonte,
                                                color: cor_preto_400,
                                              }}
                                            >
                                              {item?.mensagem}
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          fontFamily: fonte,
                                          fontSize: "13px",
                                        }}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          justifyContent="flex-end"
                                          alignItems="end"
                                          rowSpacing={1}
                                        >
                                          <Grid item>
                                            <span
                                              style={{
                                                fontFamily: fonte,
                                                color: cor_preto_400,
                                              }}
                                            >
                                              {moment(item?.timestamp).format(
                                                "DD/MM/YYYY HH:mm"
                                              )}
                                            </span>
                                          </Grid>
                                          {item?.pessoa ||
                                          item?.escola ||
                                          item?.cidade ? (
                                            <Grid item>
                                              <span
                                                style={{
                                                  marginTop: "1rem",
                                                  fontFamily: fonte,
                                                  color: cor_preto_300,
                                                  background: cor_amarelo_400,
                                                  borderRadius: "1rem",
                                                  padding: ".1rem .5rem",
                                                  fontSize: "12px",
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {item?.pessoa
                                                  ? "Estudante"
                                                  : item?.escola
                                                  ? "Escola"
                                                  : item?.cidade
                                                  ? "Cidade"
                                                  : ""}
                                              </span>
                                            </Grid>
                                          ) : (
                                            ""
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </ListItemContent>
                                </ListItem>
                              )
                            )
                          : ""}
                        {configuracao?.selecao === "aluno" &&
                        configuracao?.loadingRecadoFiltro === false &&
                        configuracao?.recadosEstudantes?.items?.length <
                          configuracao?.recadosEstudantes?.total ? (
                          <ListItem key={-2} style={{ width: "100%" }}>
                            <ListItemDecorator style={{ width: "100%" }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <LoadingButton
                                    loading={configuracao?.carregarMais}
                                    variant="outlined"
                                    sx={{
                                      background: cor_branco,
                                      color: cor_preto_300,
                                      fontFamily: fonte,
                                      borderColor: cor_preto_300,
                                      marginTop: "1rem",
                                      marginBottom: ".5rem",
                                      "&:hover": {
                                        borderColor: cor_principal_500,
                                      },
                                    }}
                                    onClick={(e) =>
                                      handlePage(
                                        configuracao?.recadosEstudantes?.page +
                                          1
                                      )
                                    }
                                  >
                                    <span>Carregar mais</span>
                                  </LoadingButton>
                                </Grid>
                              </Grid>
                            </ListItemDecorator>
                          </ListItem>
                        ) : (
                          ""
                        )}
                        {configuracao?.selecao === "professor" &&
                        configuracao?.loadingRecadoFiltro === false
                          ? configuracao?.recadosProfessores?.items?.map(
                              (item: any, index: any) => (
                                <ListItem
                                  key={item.id}
                                  style={{ cursor: "pointer" }}
                                  onClick={(_) => setFila([item, null])}
                                >
                                  <ListItemDecorator>
                                    <Avatar
                                      style={{
                                        backgroundColor: cor_branco_300,
                                        padding: ".3rem",
                                      }}
                                    >
                                      <img
                                        src={Maca}
                                        alt="Icone Maça e Lápis"
                                        style={{
                                          width: "2rem",
                                          height: "auto",
                                        }}
                                      />
                                    </Avatar>
                                  </ListItemDecorator>
                                  <ListItemContent>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                    >
                                      <Grid item xs={true}>
                                        <Grid
                                          item
                                          style={{
                                            fontWeight: 600,
                                            fontFamily: fonte,
                                            color: cor_preto_400,
                                            paddingRight: ".5rem",
                                          }}
                                        >
                                          {item?.titulo}
                                        </Grid>
                                        <Grid item>
                                          <span
                                            style={{
                                              fontFamily: fonte,
                                              color: cor_preto_400,
                                            }}
                                          >
                                            {item?.mensagem}
                                          </span>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          fontFamily: fonte,
                                          fontSize: "13px",
                                        }}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          justifyContent="flex-end"
                                          alignItems="end"
                                          rowSpacing={1}
                                        >
                                          <Grid item>
                                            <span
                                              style={{
                                                fontFamily: fonte,
                                                color: cor_preto_400,
                                              }}
                                            >
                                              {moment(item?.timestamp).format(
                                                "DD/MM/YYYY HH:mm"
                                              )}
                                            </span>
                                          </Grid>
                                          {item?.pessoa ||
                                          item?.escola ||
                                          item?.cidade ? (
                                            <Grid item>
                                              <span
                                                style={{
                                                  marginTop: "1rem",
                                                  fontFamily: fonte,
                                                  color: cor_branco,
                                                  background:
                                                    cor_verde_limao_300,
                                                  borderRadius: "1rem",
                                                  padding: ".1rem .5rem",
                                                  fontSize: "12px",
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {item?.pessoa
                                                  ? "Professor"
                                                  : item?.escola
                                                  ? "Escola"
                                                  : item?.cidade
                                                  ? "Cidade"
                                                  : ""}
                                              </span>
                                            </Grid>
                                          ) : (
                                            ""
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </ListItemContent>
                                </ListItem>
                              )
                            )
                          : ""}

                        {configuracao?.selecao === "professor" &&
                        configuracao?.loadingRecadoFiltro === false &&
                        configuracao?.recadosProfessores?.items?.length <
                          configuracao?.recadosProfessores?.total ? (
                          <ListItem key={-3} style={{ width: "100%" }}>
                            <ListItemDecorator style={{ width: "100%" }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <LoadingButton
                                    loading={configuracao?.carregarMais}
                                    variant="outlined"
                                    sx={{
                                      background: cor_branco,
                                      color: cor_preto_300,
                                      fontFamily: fonte,
                                      borderColor: cor_preto_300,
                                      marginTop: "1rem",
                                      marginBottom: ".5rem",
                                      "&:hover": {
                                        borderColor: cor_principal_500,
                                      },
                                    }}
                                    onClick={(e) =>
                                      handlePage(
                                        configuracao?.recadosProfessores?.page +
                                          1
                                      )
                                    }
                                  >
                                    <span>Carregar mais</span>
                                  </LoadingButton>
                                </Grid>
                              </Grid>
                            </ListItemDecorator>
                          </ListItem>
                        ) : (
                          ""
                        )}
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        borderTop: ".1px solid " + cor_branco_400,
                        padding: "1rem",
                      }}
                    >
                      {fila
                        ?.filter((item: any) => {
                          if (item !== null) return item;
                        })
                        ?.map((item: any, index: any) => {
                          return (
                            <Grid item key={item?.id}>
                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {item?.pessoa ||
                                  item?.escola ||
                                  item?.cidade ? (
                                    <Grid item>
                                      <span
                                        style={{
                                          marginTop: "1rem",
                                          fontFamily: fonte,
                                          color:
                                            configuracao?.selecao ===
                                            "professor"
                                              ? cor_branco
                                              : cor_preto_300,
                                          background:
                                            configuracao?.selecao ===
                                            "professor"
                                              ? cor_verde_limao_300
                                              : cor_amarelo_400,
                                          borderRadius: "1rem",
                                          padding: ".1rem .5rem",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item?.pessoa
                                          ? item?.pessoa?.nome
                                          : item?.escola
                                          ? item?.escola?.nome
                                          : item?.cidade
                                          ? item?.cidade?.nome
                                          : ""}
                                      </span>
                                    </Grid>
                                  ) : (
                                    <Grid item></Grid>
                                  )}

                                  <Grid item>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        <div
                                          style={{
                                            color: cor_vermelho_500,
                                            cursor: "pointer",
                                          }}
                                          onClick={(_) => handleClickOpen(item)}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </Grid>
                                      <Grid item>
                                        <div
                                          style={{
                                            color: cor_preto_400,
                                            cursor: "pointer",
                                          }}
                                          onClick={(_) => {
                                            setConfiguracao((antes: any) => {
                                              return {
                                                ...antes,
                                                cidadeSelecao: item?.id_cidade
                                                  ? { id: item?.id_cidade }
                                                  : {
                                                      id: -1,
                                                      nome: "Nenhuma cidade",
                                                    },
                                                escolaSelecao: item?.id_escola
                                                  ? { id: item?.id_escola }
                                                  : {
                                                      id: -1,
                                                      nome: "Nenhuma escola",
                                                    },
                                                pessoaSelecao: item?.id_keycloak
                                                  ? {
                                                      id_keycloak:
                                                        item?.id_keycloak,
                                                    }
                                                  : {
                                                      id_keycloak: -1,
                                                      nome: "Nenhuma pessoa",
                                                    },
                                              };
                                            });
                                            reset(item);
                                          }}
                                        >
                                          <ModeEditIcon />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                style={{ marginTop: "1rem" }}
                              >
                                <Grid item xs={true}>
                                  <Grid
                                    item
                                    style={{
                                      fontWeight: 600,
                                      fontFamily: fonte,
                                      color: cor_preto_400,
                                      paddingRight: ".5rem",
                                    }}
                                  >
                                    {item?.titulo}
                                  </Grid>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item style={{ marginTop: ".5rem" }}>
                                      <div
                                        style={{
                                          fontFamily: fonte,
                                          color: cor_preto_400,
                                          textAlign: "justify",
                                        }}
                                      >
                                        {item?.mensagem}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    fontFamily: fonte,
                                    fontSize: "13px",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="end"
                                    rowSpacing={1}
                                  >
                                    <Grid item>
                                      <span
                                        style={{
                                          fontFamily: fonte,
                                          color: cor_preto_400,
                                        }}
                                      >
                                        {moment(item?.timestamp).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Recados;
