import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import HugoPesquisa from "../../img/Hugo_analise (1) 1.png";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import { cor_branco, cor_fundo, mobile } from "../theme";

const Metricas = ({ decodedToken }: any) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={4}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto", width: "100%" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{ background: cor_branco, padding: 0, margin: 0 }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Métricas"} />
          </Grid>
          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid style={{ marginBottom: "-1.5rem", marginLeft: "1.5rem" }}>
              <p
                style={{
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                  padding: "1rem",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                  background: "#F3069F",
                  fontWeight: 600,
                  color: "white",
                  fontFamily: "Poppins",
                  width: "fit-content",
                }}
              >
                Cesan
              </p>
            </Grid>

            <Paper
              elevation={4}
              style={{ height: "100%", borderRadius: "1rem" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                spacing={2}
                padding={"2rem"}
                style={{ height: "100%" }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <img
                        style={{ marginBottom: "-.2rem" }}
                        src={HugoPesquisa}
                        alt="Hugo Pesquisando"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item xs={11}>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontWeight: 600,
                            color: "#4F4F4F",
                            fontFamily: "Poppins",
                          }}
                        >
                          Bem-vindo(a) ao painel de métricas!
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p
                          style={{
                            textAlign: "justify",
                            color: "#4F4F4F",
                            fontFamily: "Poppins",
                            fontSize: "11px",
                          }}
                        >
                          Aqui, você pode acompanhar os resultados dos seus
                          estudantes em tempo real, conferir a lista de presença
                          e receber apoio pedagógico do nosso assistente virtual
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Metricas;
