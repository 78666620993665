import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpsIcon from "@mui/icons-material/Https";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  MenuItem,
  Select,
} from "@mui/material";
import { Space } from "antd";
import Search from "antd/es/transfer/search";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { hexToRgb } from "../../types";
import api from "../axios/axios";
import Carregar, { CarregarLoading } from "../carregar";
import Deletar from "../confirmar/deletar";
import Erro from "../erro";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import Paginacao from "../paginacao";
import {
  RangeDatePicker,
  StyledDatePicker,
  StyledResultAntd,
  StyledSearchContainer,
  cor_branco,
  cor_branco_200,
  cor_branco_300,
  cor_fundo,
  cor_preto_300,
  cor_preto_400,
  cor_principal_200,
  cor_principal_300,
  cor_principal_500,
  cor_vermelho_500,
  fonte,
  mobile,
} from "../theme";
import ListarRespostas from "../respostas";
const AulaTurma = ({ decodedToken }: any) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const tipoAula = window.location.href.includes("trilhas") ? "trilha" : "aula";
  const [data, setData] = useState<any>([null, null]);
  useEffect(() => {
    const fim = dayjs();
    const inicio = dayjs().subtract(6, "day");
    setAulas({ items: [], total: 0 });
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        loadingVideo: true,
      };
    });
    setData([inicio, fim]);
  }, []);

  const [turma, setTurma] = useState<any>(null);
  const [turmaSelecao, setTurmaSelecao] = useState({
    nome: "Nenhuma turma",
    id: -1,
  });
  const [escola, setEscola] = useState<any>(null);
  const [escolaSelecao, setEscolaSelecao] = useState({
    nome: "Nenhuma escola",
    id: -1,
  });
  const [disciplinas, setDisciplinas] = useState<any>(null);
  const [disciplinaSelecao, setDisciplinaSelecao] = useState<any>({ id: -1 });
  const [aulas, setAulas] = useState<any>({ items: [], total: 0 });
  const [open, setOpen] = useState(false);
  const [openListar, setOpenListar] = useState(false);
  const [itemFuncao, setItemFuncao] = useState<any>();
  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
    loadingVideo: true,
  });

  const [buscar, setBuscar] = useState("");
  useEffect(() => {
    if (buscar !== "" && disciplinaSelecao?.id !== -1) {
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1`
              )
              .then((r) => {
                setAulas(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (buscar === "" && data[0] && data[1]) {
      api
        .get(
          `turma/disciplina/aula/${
            disciplinaSelecao?.id
          }?page=${1}&inicio=${data[0].format(
            "DD-MM-YYYY"
          )}&fim=${data[1].format("DD-MM-YYYY")}&classe=${tipoAula}`
        )
        .then((r) => {
          setAulas(r.data);
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false, loadingVideo: false };
          });
        });
    }
  }, [buscar]);

  const handleBuscar = (e: any) => {
    if (e.target.value === "") {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: false,
        };
      });
    } else {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
    }

    setAulas({ items: [], total: 0 });
    setBuscar(e.target.value);
  };

  const handleClickOpen = (item: any) => {
    setItemFuncao(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenListar = (item: any) => {
    setItemFuncao(item);
    setOpenListar(true);
  };

  const handleCloseListar = () => {
    setOpenListar(false);
  };

  const funcaoDeletar = () => {
    api.delete("aula/" + itemFuncao?.id).then((r) => {
      setAulas((antesAulas: any) => {
        const aulas = antesAulas.items.filter(
          (t: any) => t?.id !== itemFuncao?.id
        );
        const novaAulas = { ...antesAulas, items: aulas };
        return novaAulas;
      });
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        loadingVideo: true,
      };
    });
    api
      .get("escola")
      .then((r): any => {
        setEscola(r.data);
        if (r.data?.items?.length > 0) {
          const escola = r.data?.items[0];
          setEscola(r.data);
          api
            .get("escola/turma/" + escola?.id)
            .then((r): any => {
              setTurma(r.data);
              if (r.data?.items?.length > 0) {
                const turma = r.data?.items[0];
                setTurmaSelecao(turma);
                api.get("turma/disciplina/" + turma?.id).then((r) => {
                  setDisciplinas(r.data);
                  const disciplina = r.data?.items[0];
                  setDisciplinaSelecao(disciplina);
                });
              }
            })
            .then((e: any) =>
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  loading: false,
                };
              })
            )
            .catch((e) => {
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  loading: false,
                };
              });
              console.log(e);
            })
            .finally(() => {
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  loadingVideo: false,
                };
              });
            });
          setEscolaSelecao(escola);
        }
      })
      .then((e: any) =>
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        })
      )
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        });
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (escolaSelecao?.id && escolaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });

      setTurmaSelecao({
        nome: "Nenhuma turma",
        id: -1,
      });
      setTurma({
        items: [
          {
            nome: "Nenhuma turma",
            id: -1,
          },
        ],
      });
      setDisciplinaSelecao({ id: -1 });
      setDisciplinas({ id: -1 });

      api
        .get("escola/turma/" + escolaSelecao?.id)
        .then((r): any => {
          setTurma(r.data);
          if (r.data?.items?.length > 0) {
            const turma = r.data?.items[0];
            setTurmaSelecao(turma);
            api.get("turma/disciplina/" + turma?.id).then((r) => {
              setDisciplinas(r.data);
              const disciplina = r.data?.items[0];
              setDisciplinaSelecao(disciplina);
            });
          }
        })
        .then((e: any) =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          })
        )
        .catch((e) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          });
          console.log(e);
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingVideo: false,
            };
          });
        });
    }
  }, [escolaSelecao]);

  useEffect(() => {
    if (turmaSelecao?.id && turmaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
      api
        .get("turma/disciplina/" + turmaSelecao?.id)
        .then((r) => {
          setDisciplinas(r.data);
          const disciplina = r.data?.items[0];
          setDisciplinaSelecao(disciplina);
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingVideo: false,
            };
          });
        });
    }
  }, [turmaSelecao]);

  useEffect(() => {
    if (buscar !== "" && disciplinaSelecao?.id !== -1) {
      setAulas({ items: [], total: 0 });
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1`
              )
              .then((r) => {
                setAulas(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (disciplinaSelecao?.id && disciplinaSelecao?.id !== -1) {
      setAulas({ items: [], total: 0 });
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
      api
        .get(
          "/turma/disciplina/aula/" +
            disciplinaSelecao?.id +
            `?inicio=${data[0].format("DD-MM-YYYY")}&fim=${data[1].format(
              "DD-MM-YYYY"
            )}&classe=${tipoAula}`
        )
        .then((r) => {
          setAulas(r.data);
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingVideo: false,
            };
          });
        });
    }
  }, [disciplinaSelecao, data]);

  const handlePage = (page: number) => {
    setAulas({ items: [], total: 0 });
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    if (buscar !== "") {
      api
        .get(
          `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=${page}`
        )
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));

            return { ...r.data, items: novo };
          });
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false };
          });
        });
    } else {
      api
        .get(
          `turma/disciplina/aula/${
            disciplinaSelecao?.id
          }?page=${page}&inicio=${data[0].format(
            "DD-MM-YYYY"
          )}&fim=${data[1].format("DD-MM-YYYY")}&classe=${tipoAula}`
        )
        .then((r) => {
          setAulas(r.data);
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingVideo: false,
            };
          });
        });
    }
  };

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      <Dialog
        onClose={handleCloseListar}
        open={openListar}
        PaperProps={{
          sx: {
            borderRadius: "1.5rem",
            background: cor_branco,
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
              },
            },
            maxWidth: "100% !important",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseListar}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: cor_preto_400,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ListarRespostas aulaItem={itemFuncao} />
        </DialogContent>
      </Dialog>

      <Deletar
        open={open}
        handleClose={handleClose}
        item={itemFuncao}
        funcaoDeletar={funcaoDeletar}
      />
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={3}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid item style={{ background: cor_branco, padding: 0, margin: 0 }}>
            <MenuSuperior
              ativo={mobile(windowSize)}
              texto={"Adicionar Conteúdo"}
            />
          </Grid>
          <Grid
            item
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Space direction="vertical">
                  <StyledSearchContainer>
                    <Search
                      handleClear={() =>
                        setConfiguracao((antes: any) => {
                          return {
                            ...antes,
                            loadingVideo: true,
                          };
                        })
                      }
                      disabled={disciplinaSelecao?.id === -1 ? true : false}
                      value={buscar}
                      onChange={handleBuscar}
                      placeholder="Pesquisar..."
                    />
                  </StyledSearchContainer>
                </Space>
              </Grid>
              <Grid item xs={true} marginTop={".5rem"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Select
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: cor_branco,
                            },
                          },
                        },
                      }}
                      id="escola"
                      disabled={buscar !== "" ? true : false}
                      value={escolaSelecao.nome}
                      label="Escola"
                      style={{
                        color: cor_preto_300,
                        height: "33px",
                        fontFamily: fonte,
                      }}
                      // {...register("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        background: cor_branco,
                        color: cor_preto_300,
                        fontFamily: fonte,
                      }}
                    >
                      {escola?.items?.length > 0 ? (
                        escola?.items?.map((t: any) => {
                          return (
                            <MenuItem
                              sx={{
                                color: cor_preto_300,
                                fontFamily: fonte,
                                "&:hover": {
                                  background: cor_principal_300,
                                  color: cor_branco,
                                },
                                "&.Mui-selected": {
                                  background: cor_principal_200,
                                  color: cor_branco,
                                },
                              }}
                              onClick={(e) => {
                                if (escolaSelecao.id !== t.id) {
                                  setEscolaSelecao(t);
                                  setAulas({ items: [], total: 0 });
                                  setTurma({ items: [], total: 0 });
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: true,
                                    };
                                  });
                                }
                              }}
                              key={t.id}
                              value={t?.nome}
                            >
                              {t?.nome}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          sx={{
                            color: cor_preto_300,
                            fontFamily: fonte,
                            "&:hover": {
                              background: cor_principal_300,
                              color: cor_branco,
                            },
                            "&.Mui-selected": {
                              background: cor_principal_200,
                              color: cor_branco,
                            },
                          }}
                          key={-1}
                          value={"Nenhuma escola"}
                        >
                          Nenhuma escola
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Select
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: cor_branco,
                            },
                          },
                        },
                      }}
                      disabled={buscar !== "" ? true : false}
                      id="turma"
                      value={turmaSelecao.nome}
                      label="Turma"
                      style={{
                        color: cor_preto_300,
                        height: "33px",
                        fontFamily: fonte,
                      }}
                      // {...register("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        background: cor_branco,
                        color: cor_preto_300,
                        fontFamily: fonte,
                      }}
                    >
                      {turma?.items?.length > 0 ? (
                        turma?.items?.map((t: any) => {
                          return (
                            <MenuItem
                              sx={{
                                color: cor_preto_300,
                                fontFamily: fonte,
                                "&:hover": {
                                  background: cor_principal_300,
                                  color: cor_branco,
                                },
                                "&.Mui-selected": {
                                  background: cor_principal_200,
                                  color: cor_branco,
                                },
                              }}
                              onClick={(e) => {
                                if (turmaSelecao.id !== t.id) {
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: true,
                                    };
                                  });
                                  setAulas({ items: [], total: 0 });
                                  setTurmaSelecao(t);
                                }
                              }}
                              key={t.id}
                              value={t?.nome}
                            >
                              {t?.nome}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          sx={{
                            color: cor_preto_300,
                            fontFamily: fonte,
                            "&:hover": {
                              background: cor_principal_300,
                              color: cor_branco,
                            },
                            "&.Mui-selected": {
                              background: cor_principal_200,
                              color: cor_branco,
                            },
                          }}
                          key={-1}
                          value={"Nenhuma turma"}
                        >
                          Nenhuma turma
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  {disciplinas?.items?.length > 0 ? (
                    <Grid item>
                      <StyledDatePicker>
                        <RangeDatePicker
                          format="DD/MM/YYYY"
                          style={{
                            width: "100%",
                            height: "34px",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          }}
                          disabled={buscar !== "" ? true : false}
                          defaultValue={data}
                          onChange={(e: any) => {
                            setData(e);
                          }}
                        />
                      </StyledDatePicker>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {disciplinas?.items?.length > 0 ? (
                    <Grid item>
                      <Link
                        href={
                          `/${
                            window.location.href.includes("aulas")
                              ? "aulas"
                              : "trilhas"
                          }/turma/` +
                          turmaSelecao.id +
                          "/" +
                          disciplinaSelecao?.id
                        }
                      >
                        <Button
                          style={{
                            marginRight: ".5rem",
                            background: cor_principal_500,
                            color: cor_branco,
                            fontFamily: fonte,
                            marginTop: ".1rem",
                          }}
                          endIcon={<AddIcon style={{ marginTop: "-.1rem" }} />}
                          variant={"contained"}
                          size="small"
                        >
                          Aula
                        </Button>
                      </Link>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {disciplinas?.items?.length > 0
                    ? disciplinas?.items?.map((item: any) => (
                        <Grid
                          item
                          key={item?.id}
                          style={{
                            marginTop:
                              disciplinaSelecao?.id === item?.id ? "" : ".5rem",
                          }}
                        >
                          <p
                            onClick={() => {
                              if (disciplinaSelecao?.id !== item?.id) {
                                setAulas({ items: [], total: 0 });
                                setConfiguracao((antes: any) => {
                                  return {
                                    ...antes,
                                    loadingVideo: true,
                                  };
                                });
                                setDisciplinaSelecao(item);
                              }
                            }}
                            style={{
                              fontWeight: 600,
                              fontSize: "15px",
                              fontFamily: fonte,
                              cursor: "pointer",
                              userSelect: "none",
                              color:
                                disciplinaSelecao?.id === item?.id
                                  ? cor_principal_500
                                  : cor_preto_300,
                            }}
                          >
                            {item.nome}
                          </p>
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>

              {disciplinas?.items?.length > 0 ? (
                <Grid item xs={12} style={{ marginRight: "1rem" }}>
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: cor_principal_500,
                        borderRadius: "1rem",
                      },

                      height: 5,
                      borderRadius: 5,
                      background: cor_branco_300,
                    }}
                    variant="determinate"
                    value={
                      ((disciplinas?.items?.findIndex(
                        (item: any) => item?.id === disciplinaSelecao?.id
                      ) +
                        1) /
                        disciplinas?.items?.length) *
                      100
                    }
                  />
                </Grid>
              ) : configuracao?.loadingVideo ? (
                <CarregarLoading />
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      style={{ color: cor_preto_300, fontFamily: fonte }}
                      status="success"
                      title="Nenhuma disciplina cadastrada!"
                      subTitle="Nenhuma disciplina cadastrada!"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {aulas?.items?.length === 0 &&
          configuracao?.loadingVideo &&
          disciplinas?.items?.length > 0 &&
          disciplinaSelecao?.id !== -1 ? (
            <CarregarLoading />
          ) : disciplinas?.items?.length > 0 &&
            buscar !== "" &&
            aulas?.items?.length === 0 ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <StyledResultAntd
                  status="success"
                  title="Nenhuma aula encontrada!"
                  subTitle="Nenhuma aula encontrada!"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            xs={true}
            marginTop={"2rem"}
            marginRight={"1rem"}
            maxHeight={"calc(100vh - 260px)"}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              height={"100%"}
              spacing={2}
            >
              {aulas?.items?.map((aulaItem: any, index: any) => (
                <Grid
                  item
                  xs={12}
                  key={aulaItem?.id}
                  style={{
                    marginTop: "1rem",
                    marginLeft: "2rem",
                    background: cor_branco,
                    borderRadius: "1rem",
                    padding: "2rem",
                  }}
                  height={"100%"}
                  padding={2}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    height={"100%"}
                  >
                    <Grid item xs={true}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item style={{ paddingRight: "1rem" }}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Grid item>
                              <div
                                style={{
                                  position: "relative",
                                  borderRadius: ".5rem",
                                  width: "220px",
                                  height: "130px",
                                  boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                                  overflow: "hidden",
                                }}
                              >
                                <iframe
                                  style={{
                                    border: 0,
                                    position: "absolute",
                                    width: "110%",
                                    height: "110%",
                                    top: "-5%",
                                    left: "-5%",
                                    borderRadius: ".5rem",
                                  }}
                                  src={aulaItem.link}
                                />
                                <div
                                  style={{
                                    background: `rgba(${hexToRgb(
                                      cor_principal_500
                                    )}, 0.5)`,
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={8} md={8} lg={8}>
                          <p
                            style={{
                              fontFamily: fonte,
                              fontWeight: 600,
                              color: cor_preto_400,
                              fontSize: "20px",
                              marginBottom: "0px",
                            }}
                          >
                            {aulaItem.nome}
                          </p>
                          <p
                            style={{
                              fontFamily: fonte,
                              fontWeight: 400,
                              color: cor_preto_300,
                              marginTop: ".5rem",
                            }}
                          >
                            {aulaItem.descricao}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        height={"100%"}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              {aulaItem.data_entrega ? (
                                <span
                                  style={{
                                    color: cor_preto_400,
                                    fontFamily: fonte,
                                    fontWeight: 600,
                                  }}
                                >
                                  Data de Entrega:{" "}
                                  {moment(aulaItem.data_entrega).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item>
                              {aulaItem.status ? (
                                ""
                              ) : (
                                <HttpsIcon
                                  style={{
                                    color: cor_principal_500,
                                  }}
                                ></HttpsIcon>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          paddingTop={"1rem"}
                        >
                          <Grid item xs={7} width={"100%"}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              paddingTop={"1rem"}
                              width={"100%"}
                            >
                              <Button
                                style={{
                                  background: cor_principal_500,
                                  color: cor_branco_200,
                                  width: "100%",
                                }}
                                size="small"
                                onClick={() => {
                                  setItemFuncao(aulaItem);
                                  setOpenListar(true);
                                }}
                                variant={"contained"}
                              >
                                Respostas
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={8}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              spacing={2}
                              paddingTop={"1rem"}
                            >
                              <Grid item>
                                <Button
                                  style={{
                                    background: cor_vermelho_500,
                                    color: cor_branco_200,
                                  }}
                                  size="small"
                                  variant={"contained"}
                                  onClick={(_) => {
                                    handleClickOpen(aulaItem);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                              <Grid item>
                                <Link
                                  href={
                                    `/${
                                      window.location.href.includes("aulas")
                                        ? "aulas"
                                        : "trilhas"
                                    }/turma/` +
                                    turmaSelecao?.id +
                                    "/" +
                                    disciplinaSelecao?.id +
                                    "/" +
                                    aulaItem?.id
                                  }
                                >
                                  <Button
                                    style={{
                                      background: cor_principal_500,
                                      color: cor_branco_200,
                                    }}
                                    size="small"
                                    variant={"contained"}
                                  >
                                    <BorderColorIcon />
                                  </Button>
                                </Link>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Paginacao item={aulas} handlePage={handlePage} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AulaTurma;
