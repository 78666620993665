import AccountCircle from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatIcon from "@mui/icons-material/Chat";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GridViewIcon from "@mui/icons-material/GridView";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import InsightsIcon from "@mui/icons-material/Insights";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  default as Settings,
  default as SettingsIcon,
} from "@mui/icons-material/Settings";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import Ret01 from "../../img/Group 130.png";
import Ret02 from "../../img/Group 131.png";
import Ret03 from "../../img/Group 132.png";
import GoiasIMG from "../../img/logo-cesan-fundo-azul.png";
import { getAcessoNivel1, getTipoConta } from "../../types";
import {
  cor_amarelo_500,
  cor_branco,
  cor_branco_200,
  cor_preto_200,
  cor_preto_300,
  cor_principal_400,
  cor_principal_500,
  fonte,
} from "../theme";
import SubMenuAulas from "./SubMenuAulas";
import SubMenuTrilhas from "./SubMenuTrilhas";
import api from "../axios/axios";

const drawerWidth = 240;
const navItems = [
  ["Home", "home", <HomeIcon style={{ color: cor_preto_200 }} />],
  // ["Videoteca", "videoteca", <GridViewIcon style={{ color: cor_preto_200 }} />],
  // ["Aulas", "aulas", <FactCheckIcon style={{ color: cor_preto_200 }} />],
  ["Trilhas", "trilhas", <AssignmentIcon style={{ color: cor_preto_200 }} />],
  ["Métricas", "metricas", <InsightsIcon style={{ color: cor_preto_200 }} />],
  ["Recados", "recados", <ChatIcon style={{ color: cor_preto_200 }} />],
  // [
  //   "Fale Conosco",
  //   "fale",
  //   <QuestionAnswerIcon style={{ color: cor_preto_200 }} />,
  // ],
  [
    "Configurações",
    "configuracoes",
    <SettingsIcon style={{ color: cor_preto_200 }} />,
  ],
  ["Informações", "informacoes", <InfoIcon style={{ color: cor_preto_200 }} />],
  ["Sair", "sair", <ExitToAppIcon style={{ color: cor_preto_200 }} />],
];

const CardMenu = ({ menuSelecao, decodedToken, ativo = true }: any) => {
  const [btnSelecao, setbtnSelecao] = useState<any>(menuSelecao);
  const [escurecer, setEscurecer] = useState(false);
  const [pontuacao, setPontuacao] = useState<any>({ estrela: 0, medalha: 0 });

  React.useEffect(() => {
    if (getAcessoNivel1(decodedToken)) {
      Promise.all([api.get("pontuacao")]).then(([r1]: any) => {
        setPontuacao(r1.data);
      });
    }
  }, []);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "1",
          display: escurecer ? "" : "none",
        }}
      />
      {ativo ? (
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          style={{
            minWidth: "12em",
            height: "auto",
            minHeight: "32.3rem",
            zIndex: "3",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              background: cor_principal_500,
              height: "100vh",
              minHeight: "32.3rem",
            }}
          >
            <Grid item>
              <Grid item style={{ position: "absolute", left: 1 }}>
                <img src={Ret01} alt="Imagem Retângulo" />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Link href="/configuracoes" style={{ cursor: "pointer" }}>
                      <Avatar
                        alt="Avatar"
                        src={decodedToken?.foto}
                        sx={{ width: 80, height: 80, marginTop: "2rem" }}
                      />
                    </Link>
                  </Grid>
                  <Grid item style={{ marginTop: ".5rem" }}>
                    <span style={{ fontFamily: fonte, color: cor_branco }}>
                      {decodedToken?.given_name}
                    </span>
                  </Grid>
                  <Grid item xs={12} width={"100%"}>
                    {getTipoConta(decodedToken) === "aluno" ? (
                      <Grid item xs={12} style={{ padding: 0, margin: 0 }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="stretch"
                          padding={2}
                          spacing={2}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <Grid item xs={true}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                background: cor_principal_400,
                                borderRadius: ".5rem",
                                paddingRight: ".8rem",
                                paddingLeft: ".5rem",
                              }}
                            >
                              <Grid item>
                                <StarBorderIcon
                                  style={{ color: cor_amarelo_500 }}
                                />
                              </Grid>
                              <Grid
                                item
                                style={{
                                  color: cor_branco_200,
                                  fontFamily: fonte,
                                }}
                              >
                                {pontuacao?.estrela}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={true}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                background: cor_principal_400,
                                borderRadius: ".5rem",
                                paddingRight: ".8rem",
                                paddingLeft: ".5rem",
                              }}
                            >
                              <Grid item>
                                <WorkspacePremiumIcon
                                  style={{ color: cor_amarelo_500 }}
                                />
                              </Grid>
                              <Grid
                                item
                                style={{
                                  color: cor_branco_200,
                                  fontFamily: fonte,
                                }}
                              >
                                {pontuacao?.medalha}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: "150px" }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item style={{ marginTop: "1rem", width: "100%" }}>
                    <Link href="/home">
                      <Button
                        fullWidth
                        onClick={(_) => setbtnSelecao(1)}
                        variant={btnSelecao === 1 ? "contained" : "text"}
                        style={{
                          justifyContent: "flex-start",
                          color: btnSelecao === 1 ? cor_preto_300 : cor_branco,
                          background: btnSelecao === 1 ? cor_branco : "none",
                          textTransform: "capitalize",
                          fontFamily: fonte,
                        }}
                        size="small"
                        startIcon={<HomeIcon />}
                      >
                        Home
                      </Button>
                    </Link>
                  </Grid>
                  {/* <Grid item style={{ width: "100%" }}>
                    <Link href="/videoteca">
                      <Button
                        fullWidth
                        onClick={(_) => setbtnSelecao(2)}
                        variant={btnSelecao === 2 ? "contained" : "text"}
                        style={{
                          justifyContent: "flex-start",
                          color: btnSelecao === 2 ? cor_preto_300 : cor_branco,
                          background: btnSelecao === 2 ? cor_branco : "none",
                          textTransform: "capitalize",
                          fontFamily: fonte,
                        }}
                        size="small"
                        startIcon={<GridViewIcon />}
                      >
                        Videoteca
                      </Button>
                    </Link>
                  </Grid> */}

                  {/*<Grid item style={{ width: "100%" }}>*/}
                  {/*  {getTipoConta(decodedToken) !== "gestor" ? (*/}
                  {/*    <Link href="/aulas">*/}
                  {/*      <Button*/}
                  {/*        fullWidth*/}
                  {/*        onClick={(_) => setbtnSelecao(3)}*/}
                  {/*        variant={btnSelecao === 3 ? "contained" : "text"}*/}
                  {/*        style={{*/}
                  {/*          justifyContent: "flex-start",*/}
                  {/*          color:*/}
                  {/*            btnSelecao === 3 ? cor_preto_300 : cor_branco,*/}
                  {/*          background: btnSelecao === 3 ? cor_branco : "none",*/}
                  {/*          textTransform: "capitalize",*/}
                  {/*          fontFamily: fonte,*/}
                  {/*        }}*/}
                  {/*        size="small"*/}
                  {/*        startIcon={<FactCheckIcon />}*/}
                  {/*      >*/}
                  {/*        Aulas*/}
                  {/*      </Button>*/}
                  {/*    </Link>*/}
                  {/*  ) : (*/}
                  {/*    <SubMenuAulas*/}
                  {/*      setEscurecer={setEscurecer}*/}
                  {/*      btnSelecao={btnSelecao}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</Grid>*/}
                  <Grid item style={{ width: "100%" }}>
                    {getTipoConta(decodedToken) !== "gestor" ? (
                      <Link href="/trilhas">
                        <Button
                          fullWidth
                          onClick={(_) => setbtnSelecao(20)}
                          variant={btnSelecao === 20 ? "contained" : "text"}
                          style={{
                            justifyContent: "flex-start",
                            color:
                              btnSelecao === 20 ? cor_preto_300 : cor_branco,
                            background: btnSelecao === 20 ? cor_branco : "none",
                            textTransform: "capitalize",
                            fontFamily: fonte,
                          }}
                          size="small"
                          startIcon={<AssignmentIcon />}
                        >
                          Trilhas
                        </Button>
                      </Link>
                    ) : (
                      <SubMenuTrilhas
                        setEscurecer={setEscurecer}
                        btnSelecao={btnSelecao}
                      />
                    )}
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <Link href="/metricas">
                      <Button
                        fullWidth
                        onClick={(_) => setbtnSelecao(4)}
                        variant={btnSelecao === 4 ? "contained" : "text"}
                        style={{
                          justifyContent: "flex-start",
                          color: btnSelecao === 4 ? cor_preto_300 : cor_branco,
                          background: btnSelecao === 4 ? cor_branco : "none",
                          textTransform: "capitalize",
                          fontFamily: fonte,
                        }}
                        size="small"
                        startIcon={<InsightsIcon />}
                      >
                        Métricas
                      </Button>
                    </Link>
                  </Grid>
                  {getTipoConta(decodedToken) === "gestor" ? (
                    <Grid item style={{ width: "100%" }}>
                      <Link href="/recados">
                        <Button
                          fullWidth
                          onClick={(_) => setbtnSelecao(9)}
                          variant={btnSelecao === 9 ? "contained" : "text"}
                          style={{
                            justifyContent: "flex-start",
                            color:
                              btnSelecao === 9 ? cor_preto_300 : cor_branco,
                            background: btnSelecao === 9 ? cor_branco : "none",
                            textTransform: "capitalize",
                            fontFamily: fonte,
                          }}
                          size="small"
                          startIcon={<ChatIcon />}
                        >
                          Recados
                        </Button>
                      </Link>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ marginLeft: "1rem" }}>
              <Grid
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item style={{ marginBottom: "1.5rem", width: "150px" }}>
                  <img
                    style={{ width: "150px" }}
                    src={GoiasIMG}
                    alt="Imagem Goiás"
                  />
                </Grid>
                {/* <Grid item style={{ width: "100%" }}>
                  <Link href="fale">
                    <Button
                      fullWidth
                      onClick={(_) => setbtnSelecao(5)}
                      variant={btnSelecao === 5 ? "contained" : "text"}
                      style={{
                        justifyContent: "flex-start",
                        color: btnSelecao === 5 ? cor_preto_300 : cor_branco,
                        textTransform: "capitalize",
                        background: btnSelecao === 5 ? cor_branco : "none",
                        fontFamily: fonte,
                      }}
                      size="small"
                      startIcon={<QuestionAnswerIcon />}
                    >
                      Fale conosco
                    </Button>
                  </Link>
                </Grid> */}
                <Grid item style={{ width: "100%" }}>
                  <Link href="/configuracoes">
                    <Button
                      fullWidth
                      onClick={(_) => setbtnSelecao(6)}
                      variant={btnSelecao === 6 ? "contained" : "text"}
                      style={{
                        justifyContent: "flex-start",
                        color: btnSelecao === 6 ? cor_preto_300 : cor_branco,
                        textTransform: "capitalize",
                        background: btnSelecao === 6 ? cor_branco : "none",
                        fontFamily: fonte,
                      }}
                      size="small"
                      startIcon={<SettingsIcon />}
                    >
                      Configurações
                    </Button>
                  </Link>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Link href="/informacoes">
                    <Button
                      fullWidth
                      onClick={(_) => setbtnSelecao(7)}
                      variant={btnSelecao === 7 ? "contained" : "text"}
                      style={{
                        justifyContent: "flex-start",
                        color: btnSelecao === 7 ? cor_preto_300 : cor_branco,
                        textTransform: "capitalize",
                        background: btnSelecao === 7 ? cor_branco : "none",
                        fontFamily: fonte,
                      }}
                      size="small"
                      startIcon={<InfoIcon />}
                    >
                      Informações
                    </Button>
                  </Link>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Link href="/sair">
                    <Button
                      fullWidth
                      onClick={(_) => setbtnSelecao(8)}
                      variant={btnSelecao === 8 ? "contained" : "text"}
                      style={{
                        justifyContent: "flex-start",
                        color: btnSelecao === 8 ? cor_preto_300 : cor_branco,
                        textTransform: "capitalize",
                        background: btnSelecao === 8 ? cor_branco : "none",
                        fontFamily: fonte,
                      }}
                      size="small"
                      startIcon={<ExitToAppIcon />}
                    >
                      Sair
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid item>
                <img
                  src={Ret03}
                  alt="Imagem Retângulo"
                  style={{ marginBottom: "-7px", marginTop: "-2rem" }}
                />
                <img
                  src={Ret02}
                  alt="Imagem Retângulo"
                  style={{ marginBottom: "-7px", marginTop: "-2rem" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export const MenuPhone = ({ decodedToken, ativo = true }: any) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  if (ativo) {
    return <></>;
  }
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Link
          href={"/configuracoes"}
          style={{ textDecoration: "none", color: cor_preto_300 }}
        >
          <p style={{ fontFamily: fonte, color: cor_branco }}>Configurações</p>
        </Link>
      </MenuItem>
    </Menu>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Grid item style={{ width: "100%", background: cor_principal_500 }}>
        <Link href={"/home"}>
          <img style={{ width: "150px" }} src={GoiasIMG} alt="Imagem Goiás" />
        </Link>
      </Grid>

      <Divider />
      <List>
        {navItems
          ?.filter((item) => {
            if (getTipoConta(decodedToken) === "gestor") {
              return item;
            } else if (
              getTipoConta(decodedToken) !== "gestor" &&
              item[0] !== "Recados"
            ) {
              return item;
            }
          })
          .map((item, index) => (
            <Link
              href={`/${item[1]}`}
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                textDecoration: "none",
              }}
              key={index}
            >
              <ListItem
                style={{
                  display: "flex",
                  textAlign: "start",
                  justifyContent: "start",
                }}
                disablePadding
              >
                <ListItemButton
                  style={{
                    display: "flex",
                    textAlign: "start",
                    justifyContent: "start",
                  }}
                >
                  <span style={{ paddingLeft: "1rem" }}>{item[2]}</span>

                  <p
                    style={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      color: cor_preto_200,
                      fontFamily: fonte,
                      margin: 7,
                    }}
                  >
                    {item[0]}
                  </p>
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
      </List>
    </Box>
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Link href={"/home"} style={{ textDecoration: "none" }}>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <HomeIcon style={{ color: cor_preto_200 }} />
          </ListItemIcon>
          <span style={{ color: cor_preto_200, fontFamily: fonte }}>Home</span>
        </MenuItem>
      </Link>
      <Link href={"/metricas"} style={{ textDecoration: "none" }}>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <InsightsIcon style={{ color: cor_preto_200 }} />
          </ListItemIcon>
          <span style={{ color: cor_preto_200, fontFamily: fonte }}>
            Métricas
          </span>
        </MenuItem>
      </Link>
      <Divider />
      <Link href={"/configuracoes"} style={{ textDecoration: "none" }}>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <Settings style={{ color: cor_preto_200 }} fontSize="small" />
          </ListItemIcon>
          <span style={{ color: cor_preto_200, fontFamily: fonte }}>
            Configurações
          </span>
        </MenuItem>
      </Link>
      <Link href={"/sair"} style={{ textDecoration: "none" }}>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <Logout style={{ color: cor_preto_200 }} fontSize="small" />
          </ListItemIcon>
          <span style={{ color: cor_preto_200, fontFamily: fonte }}>Sair</span>
        </MenuItem>
      </Link>
    </Menu>
  );

  const container = document.body;

  return (
    <Grid item xs={12}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="absolute"
          style={{
            backgroundColor: cor_principal_500,
            marginTop: "-1.5rem",
            height: "90px",
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              style={{ color: cor_branco }}
              onClick={(_) => setMobileOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "block" } }}
            >
              <Box
                style={{
                  width: "200px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Link href={"/home"}>
                  <img
                    style={{ width: "150px" }}
                    src={GoiasIMG}
                    alt="Imagem Goiás"
                  />
                </Link>
              </Box>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            {/* <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Pesquisar..."
                                size='small'
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search> */}
            <Box />
            <p style={{ color: cor_branco, fontFamily: fonte }}>
              Olá, {decodedToken?.given_name}
            </p>

            <Box sx={{ display: { xs: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar alt="Avatar" src={decodedToken?.foto} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
            {renderMenu}
          </Drawer>
        </nav>
      </Box>
    </Grid>
  );
};

export default CardMenu;
