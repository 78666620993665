import AvatarProfessor from "./img/130 PX.png";
import AvatarGestor from "./img/Ellipse 15 (1).png";
import AvatarAluno from "./img/Ellipse 15.png";

export const validarLink = (str: any) => {
  const pattern =
    /^(?:(?:https?:\/\/)|(?:www\.)|localhost|genial\.(?:ly|me)|vimeo\.com|youtube\.com|youtu\.be)(?:[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/g;
  return pattern.test(str);
};

export const hexToRgb = (hex: any) => {
  hex = hex.replace(/^#/, "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

export const convertToEmbedUrl = (url: any) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

  if (youtubeRegex.test(url)) {
    let videoId = "";

    if (url.includes("youtu.be")) {
      videoId = url.substring(url.lastIndexOf("/") + 1);
    } else {
      videoId = url.match(/[?&]v=([^&]+)/)[1];
    }

    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    return null; // URL não é válida para o YouTube
  }
};

export interface AuthToken {
  given_name: string;
  foto: string;
  preferred_username: string;
  realm_access: {
    roles: string[];
  };
  contratos: string[];
}

export const getAvatarFoto = (decodedToken: any): string => {
  const professor: string = decodedToken?.realm_access?.roles?.includes(
    "professor"
  )
    ? "professor"
    : decodedToken?.realm_access?.roles?.includes("aluno")
    ? "aluno"
    : decodedToken?.realm_access?.roles?.includes("gestor")
    ? "gestor"
    : "";
  return professor === "professor"
    ? AvatarAluno
    : professor === "aluno"
    ? AvatarProfessor
    : professor === "gestor"
    ? AvatarGestor
    : "";
};

export const getTipoConta = (decodedToken: any): string => {
  return decodedToken?.realm_access?.roles?.includes("professor")
    ? "professor "
    : decodedToken?.realm_access?.roles?.includes("aluno")
    ? "aluno"
    : decodedToken?.realm_access?.roles?.includes("gestor")
    ? "gestor"
    : "";
};

export const getAcessoNivel2 = (decodedToken: any): boolean => {
  return decodedToken?.realm_access?.roles?.includes("professor") ||
    decodedToken?.realm_access?.roles?.includes("gestor")
    ? true
    : false;
};

export const getAcessoNivel1 = (decodedToken: any): boolean => {
  return decodedToken?.realm_access?.roles?.includes("aluno") ? true : false;
};
