import { DatePicker, Result } from "antd";
import styled from "styled-components";

export const cor_principal_500 = "#00519E";
export const cor_principal_400 = "#2C6FAE";
export const cor_principal_300 = "#548ABD";
export const cor_principal_200 = "#92B1CE";
export const cor_preto_500 = "#030C15";
export const cor_preto_400 = "#0D2237";
export const cor_preto_200 = "#727D99";
export const cor_preto_300 = "#28425B";
export const cor_preto_100 = "#929CB7";
export const cor_preto = "black";
export const cor_branco = "#FFFFFF";
export const cor_branco_100 = "#BED0E2";
export const cor_branco_200 = "#F4F7FE";
export const cor_branco_300 = "#D5D9E2";
export const cor_branco_400 = "#C6CCDC";
export const cor_branco_500 = "#ACB3CD";
export const cor_amarelo_500 = "#FFDD00";
export const cor_amarelo_400 = "#FFE84C";
export const cor_verde_500 = "#1FA22E";
export const cor_verde_200 = "#56D689";
export const cor_verde_limao_100 = "#EBFFF4";
export const cor_verde_limao_200 = "#8CD294";
export const cor_verde_limao_300 = "#63C56E";
export const cor_verde_limao_400 = "#0BAB4B";
export const cor_verde_limao_500 = "#1FA22E";
export const cor_vermelho_500 = "#B20000";
export const cor_vermelho_200 = "#E95959";
export const cor_fundo = "#F6F6F6";
export const fonte = "Poppins";
export const cor_inicio_progresso_home = "#015092";
export const cor_fim_progresso_home = "015FA9";

// export const cor_principal_500 = "red";
// export const cor_principal_400 = "red";
// export const cor_principal_300 = "red";
// export const cor_principal_200 = "red";
// export const cor_preto_500 = "red";
// export const cor_preto_400 = "red";
// export const cor_preto_200 = "red";
// export const cor_preto_300 = "red";
// export const cor_preto_100 = "red";
// export const cor_preto = "red";
// export const cor_branco = "red";
// export const cor_branco_100 = "red";
// export const cor_branco_200 = "red";
// export const cor_branco_300 = "red";
// export const cor_branco_400 = "red";
// export const cor_branco_500 = "red";
// export const cor_amarelo_500 = "red";
// export const cor_amarelo_400 = "red";
// export const cor_verde_500 = "red";
// export const cor_verde_200 = "red";
// export const cor_verde_limao_100 = "red";
// export const cor_verde_limao_200 = "red";
// export const cor_verde_limao_300 = "red";
// export const cor_verde_limao_400 = "red";
// export const cor_verde_limao_500 = "red";
// export const cor_vermelho_500 = "red";
// export const cor_vermelho_200 = "red";
// export const cor_fundo = "red";
// export const fonte = "red";
//export const cor_inicio_progresso_home = "red";
//export const cor_fim_progresso_home = "red";

export const ListContainerStyle = styled.div`
  svg {
    color: ${cor_preto_100};
  }
  .ant-pagination-item {
    border-color: ${cor_principal_500};
    background-color: ${cor_branco};
  }
  a {
    color: ${cor_principal_500} !important;
  }
`;

const StyledRangePickerContainer = styled.div`
  background-color: ${cor_branco};
  font-family: ${fonte} !important;
  th {
    color: ${cor_preto_300} !important;
    font-family: ${fonte} !important;
  }
  .ant-picker-header button {
    color: ${cor_preto_300} !important;
    font-family: ${fonte} !important;
  }
  .ant-picker-header-view:hover {
    color: ${cor_principal_500};
    font-family: ${fonte} !important;
  }
  .ant-picker-header-view {
    color: ${cor_preto_300} !important;
    font-family: ${fonte} !important;
  }
  .ant-picker-cell {
    color: ${cor_preto_300} !important;
    font-family: ${fonte} !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start {
    color: ${cor_preto_300} !important;
    font-family: ${fonte} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: ${cor_principal_500} !important;
    color: ${cor_branco} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${cor_principal_500} !important;
    color: ${cor_branco} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: ${cor_principal_500} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before,
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: ${cor_principal_200} !important;
  }

  .ant-btn-primary {
    background-color: ${cor_principal_500} !important;
    border-color: ${cor_principal_500} !important;
  }
`;
export const RangeDatePicker = (props: any) => {
  return (
    <DatePicker.RangePicker
      panelRender={(panelNode) => (
        <StyledRangePickerContainer>{panelNode}</StyledRangePickerContainer>
      )}
      {...props}
    />
  );
};

export const StyledDatePicker = styled.span`
  .ant-picker-outlined {
    border-color: ${cor_branco_300};
  }
  .ant-picker {
    background-color: ${cor_branco} !important;
    color: ${cor_preto_300};
    font-family: ${fonte};
  }
  .ant-picker-active-bar {
    background-color: ${cor_principal_500} !important;
  }
  svg {
    color: ${cor_preto_300};
  }
  path {
    color: ${cor_preto_300};
  }
  &:hover .ant-picker {
    border-color: ${cor_principal_500} !important;
  }
`;

export const StyledSearchContainer = styled.div`
  span {
    background-color: ${cor_branco} !important;
    color: ${cor_preto_300};
    font-family: ${fonte};
    border-color: ${cor_branco_300};
  }
  .ant-input-disabled {
    background-color: ${cor_branco} !important;
    color: ${cor_preto_300};
    font-family: ${fonte};
    border-color: ${cor_branco_300};
  }
  ::placeholder {
    color: ${cor_preto_100};
    font-family: ${fonte};
  }
  &:hover .ant-input-affix-wrapper {
    border-color: ${cor_principal_500} !important;
  }
`;

export const StyledResultAntd = styled(Result)`
  path {
    color: ${cor_verde_limao_300} !important;
  }

  .ant-result-title {
    color: ${cor_preto_300};
    font-family: ${fonte};
  }

  .ant-result-subtitle {
    color: ${cor_preto_300};
    font-family: ${fonte};
  }
`;

export const Perguntas = styled.p`
  margin-top: 0;
  color: ${cor_preto_300};
  font-weight: 600;
  font-size: 20px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  margin-bottom: 0;
`;

export const ConfiguracoesPerfil = styled.p`
  margin-top: 0;
  color: ${cor_preto_400};
  font-weight: 600;
  font-size: 24px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;
`;

export const AlterarSenha = styled.p`
  margin-top: 0;
  color: ${cor_preto_300};
  width: 100%;
  font-size: 20px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  text-align: "justify";
  margin-bottom: 0.5rem;
`;

export const ConfiguracoesPerfilTextoNotificacao = styled.p`
  margin-top: 0;
  margin-left: 3.5rem;
  color: ${cor_preto_200};
  font-size: 12px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;
`;

export const ConfiguracoesPerfilNome = styled.p`
  margin-top: 0;
  color: ${cor_preto_500};
  font-weight: 600;
  font-size: 17.8px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  margin-bottom: 0;
`;

export const ConfiguracoesPerfilTexto = styled.p`
  margin-top: 0;
  color: ${cor_preto_500};
  font-weight: 600;
  font-size: 14px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  margin-bottom: 0;
`;

export const ConfiguracoesPerfilNomeArroba = styled.p`
  margin-top: 0;
  color: ${cor_preto_500};
  font-weight: 600;
  font-size: 12px;
  font-family: ${fonte};
  overflow-wrap: break-word;
  text-align: "center";
`;

export const ConfiguracoesTextoPerfil = styled.p`
  margin: 0;
  color: ${cor_preto_300};
  font-size: 12px;
  font-family: ${fonte};
  overflow-wrap: break-word;
`;

export const ConfiguracoesTextoSelecao = styled.p<{ $selecao?: boolean }>`
  margin: 0;
  color: ${(props) => (props.$selecao ? cor_principal_500 : cor_preto_300)};
  font-size: 18px;
  font-weight: ${(props) =>
    props.$selecao
      ? 600
      : "normal"}; // Ajusta a ${fonte} apenas se houver seleção
  font-family: ${fonte};
  overflow-wrap: break-word;
  cursor: pointer;
`;

export const ConfiguracoesTexto = styled.p`
  margin-top: 0;
  color: ${cor_preto_300};
  font-size: 12px;
  font-family: ${fonte};
  overflow-wrap: break-word;
`;

export const FaleTitulo = styled.p`
  color: ${cor_principal_500};
  font-weight: bold;
  font-size: 16px;
  font-family: ${fonte};
  margin-top: 0;
  padding-top: 0;
  overflow-wrap: break-word;
`;

export const FaleTexto = styled.p`
  color: ${cor_preto_300};
  font-size: 14px;
  font-family: ${fonte};
  text-align: "justify";
  overflow-wrap: break-word;
`;

export const EntreTexto = styled.p`
  color: ${cor_branco};
  font-size: 16px;
  font-family: ${fonte};
  padding-right: 0.5rem;
  overflow-wrap: break-word;
`;

export const Guia = styled.p`
  color: ${cor_preto_400};
  font-size: 14px;
  font-family: ${fonte};
  text-decoration: underline;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  overflow-wrap: break-word;
  padding-right: 1rem;
`;

export const FaleTextoContato = styled.p`
  color: ${cor_preto_300};
  font-size: 16px;
  font-family: ${fonte};
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const MenuSupeior = styled.p`
  color: ${cor_preto_300};
  font-size: 24px;
  font-family: ${fonte};
  font-weight: 600;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const mobile = (windowSize: any): any => {
  return windowSize?.height > 610 && windowSize?.width > 881;
};
