import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/Close";
import HttpsIcon from "@mui/icons-material/Https";
import LockIcon from "@mui/icons-material/Lock";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  Stepper,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAcessoNivel2, hexToRgb } from "../../types";
import api from "../axios/axios";
import Carregar, { CarregarLoading } from "../carregar";
import Erro from "../erro";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import ListarRespostas from "../respostas";
import {
  StyledResultAntd,
  cor_amarelo_500,
  cor_branco,
  cor_branco_200,
  cor_branco_300,
  cor_fundo,
  cor_preto_300,
  cor_preto_400,
  cor_principal_200,
  cor_principal_300,
  cor_principal_500,
  cor_verde_500,
  cor_verde_limao_200,
  fonte,
  mobile,
} from "../theme";
moment.locale("pt-br");

const Trilha = ({ decodedToken }: any) => {
  const [aulas, setAulas] = useState<any>({ items: [], total: 0 });
  const [disciplinas, setDisciplinas] = useState<any>(null);
  const [disciplinaSelecao, setDisciplinaSelecao] = useState<any>({ id: -1 });
  const [turma, setTurma] = useState<any>(null);
  const [escola, setEscola] = useState<any>(null);
  const [trilhaPos, setTrilhaPos] = useState<any>(0);
  const [itemFuncao, setItemFuncao] = useState<any>();
  const [escolaSelecao, setEscolaSelecao] = useState({
    nome: "Nenhuma escola",
    id: -1,
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const inicio = params.get("inicio");
  const fim = params.get("fim");
  const [openListar, setOpenListar] = useState(false);
  const size = 10;
  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
    loadingVideo: true,
    carregarMais: false,
  });
  const [turmaSelecao, setTurmaSelecao] = useState({
    nome: "Nenhuma turma",
    id: -1,
  });
  const handleCloseListar = () => {
    setOpenListar(false);
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [buscar, setBuscar] = useState("");
  useEffect(() => {
    if (buscar !== "") {
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1&trilha=true`
              )
              .then((r) => {
                let pos = 0;
                r?.data?.items?.map((i: any, index: any) => {
                  if (i?.submissao?.length > 0) {
                    pos = index + 1;
                  }
                });
                setTrilhaPos(pos);
                setAulas(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (buscar === "" && disciplinaSelecao?.id !== -1) {
      api
        .get(
          `turma/disciplina/aula/${
            disciplinaSelecao?.id
          }?page=${1}&size=${1000}&trilha=true`
        )
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));
            let pos = 0;
            r?.data?.items?.map((i: any, index: any) => {
              if (i?.submissao?.length > 0) {
                pos = index + 1;
              }
            });
            setTrilhaPos(pos);
            return { ...r.data, items: novo };
          });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false, loadingVideo: false };
          })
        );
    }
  }, [buscar]);

  useEffect(() => {
    setConfiguracao((antes: any) => {
      return {
        ...antes,
        loadingVideo: true,
      };
    });
    api
      .get("escola")
      .then((r): any => {
        setEscola(r.data);
        if (r.data?.items?.length > 0) {
          const escola = r.data?.items[0];
          setEscola(r.data);
          api
            .get("escola/turma/" + escola?.id)
            .then((r): any => {
              setTurma(r.data);
              if (r.data?.items?.length > 0) {
                const turma = r.data?.items[0];
                setTurmaSelecao(turma);
                api.get("turma/disciplina/" + turma?.id).then((r) => {
                  setDisciplinas(r.data);
                  const disciplina = r.data?.items[0];
                  setDisciplinaSelecao(disciplina);
                });
              }
            })
            .then((e: any) =>
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  loading: false,
                };
              })
            )
            .catch((e) => {
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  loading: false,
                };
              });
              console.log(e);
            })
            .finally(() => {
              setConfiguracao((antes: any) => {
                return {
                  ...antes,
                  loadingVideo: false,
                };
              });
            });
          setEscolaSelecao(escola);
        }
      })
      .then((e: any) =>
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        })
      )
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        });
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (escolaSelecao?.id && escolaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });

      setTurmaSelecao({
        nome: "Nenhuma turma",
        id: -1,
      });
      setTurma({
        items: [
          {
            nome: "Nenhuma turma",
            id: -1,
          },
        ],
      });
      setDisciplinaSelecao({ id: -1 });
      setDisciplinas({ id: -1 });

      api
        .get("escola/turma/" + escolaSelecao?.id)
        .then((r): any => {
          setTurma(r.data);
          if (r.data?.items?.length > 0) {
            const turma = r.data?.items[0];
            setTurmaSelecao(turma);
            api.get("turma/disciplina/" + turma?.id).then((r) => {
              setDisciplinas(r.data);
              const disciplina = r.data?.items[0];
              setDisciplinaSelecao(disciplina);
            });
          }
        })
        .then((e: any) =>
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          })
        )
        .catch((e) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          });
          console.log(e);
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loadingVideo: false,
            };
          });
        });
    }
  }, [escolaSelecao]);

  const handlePage = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    if (buscar !== "" && disciplinaSelecao?.id !== -1) {
      api
        .get(
          `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=${page}&trilha=true`
        )
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));
            let pos = 0;
            novo?.map((i: any, index: any) => {
              if (i?.submissao?.length > 0) {
                pos = index + 1;
              }
            });
            setTrilhaPos(pos);
            return { ...r.data, items: novo };
          });
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false };
          });
        });
    } else if (disciplinaSelecao?.id !== -1) {
      api
        .get(
          `turma/disciplina/aula/${disciplinaSelecao?.id}?page=${page}&size=${size}&trilha=true`
        )
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));

            let pos = 0;
            novo?.map((i: any, index: any) => {
              if (i?.submissao?.length > 0) {
                pos = index + 1;
              }
            });
            setTrilhaPos(pos);
            return { ...r.data, items: novo };
          });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false };
          })
        );
    }
  };

  useEffect(() => {
    if (buscar !== "" && disciplinaSelecao?.id !== -1) {
      setAulas({ items: [], total: 0 });
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1&trilha=true`
              )
              .then((r) => {
                let pos = 0;
                r?.data?.items?.map((i: any, index: any) => {
                  if (i?.submissao?.length > 0) {
                    pos = index + 1;
                  }
                });
                setTrilhaPos(pos);
                setAulas(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (disciplinaSelecao?.id && disciplinaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
      setAulas({ items: [], total: 0 });
      api
        .get(
          `turma/disciplina/aula/${disciplinaSelecao?.id}?size=${size}&trilha=true`
        )
        .then((r) => {
          let pos = 0;
          r?.data?.items?.map((i: any, index: any) => {
            if (i?.submissao?.length > 0) {
              pos = index + 1;
            }
          });
          setTrilhaPos(pos);

          setAulas(r.data);
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false, loadingVideo: false };
          })
        );
    }
  }, [disciplinaSelecao]);

  useEffect(() => {
    if (turmaSelecao?.id && turmaSelecao?.id !== -1) {
      api.get("turma/disciplina/" + turmaSelecao?.id).then((r) => {
        setDisciplinas(r.data);
        const disciplina = r.data?.items[0];
        setDisciplinaSelecao(disciplina);
      });
    }
  }, [turmaSelecao]);

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      <Dialog
        onClose={handleCloseListar}
        open={openListar}
        PaperProps={{
          sx: {
            borderRadius: "1.5rem",
            background: cor_branco,
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
              },
            },
            maxWidth: "100% !important",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseListar}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: cor_preto_400,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ListarRespostas aulaItem={itemFuncao} />
        </DialogContent>
      </Dialog>
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={20}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            ativo={mobile(windowSize) ? true : false}
            decodedToken={decodedToken}
          />
          <Grid item style={{ background: cor_branco, padding: 0, margin: 0 }}>
            <MenuSuperior ativo={mobile(windowSize)} texto={"Trilhas"} />
          </Grid>
          <Grid
            item
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              {/* <Grid item>
                <Space direction="vertical">
                  <StyledSearchContainer>
                    <Search
                      handleClear={() =>
                        setConfiguracao((antes: any) => {
                          return {
                            ...antes,
                            loadingVideo: true,
                          };
                        })
                      }
                      disabled={disciplinaSelecao?.id === -1 ? true : false}
                      value={buscar}
                      onChange={handleBuscar}
                      placeholder="Pesquisar..."
                    />
                  </StyledSearchContainer>
                </Space>
              </Grid> */}
              <Grid item xs={true} marginTop={".5rem"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Select
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: cor_branco,
                            },
                          },
                        },
                      }}
                      id="escola"
                      disabled={buscar !== "" ? true : false}
                      value={escolaSelecao.nome}
                      label="Escola"
                      style={{
                        color: cor_preto_300,
                        height: "33px",
                        fontFamily: fonte,
                      }}
                      // {...register("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },

                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        background: cor_branco,
                        color: cor_preto_300,
                        fontFamily: fonte,
                      }}
                    >
                      {escola?.items?.length > 0 ? (
                        escola?.items?.map((t: any) => {
                          return (
                            <MenuItem
                              sx={{
                                color: cor_preto_300,
                                fontFamily: fonte,
                                "&:hover": {
                                  background: cor_principal_300,
                                  color: cor_branco,
                                },
                                "&.Mui-selected": {
                                  background: cor_principal_200,
                                  color: cor_branco,
                                },
                              }}
                              onClick={(e) => {
                                if (escolaSelecao?.id !== t?.id) {
                                  setEscolaSelecao(t);
                                  setAulas({ items: [], total: 0 });
                                  setTurma({ items: [], total: 0 });
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: true,
                                    };
                                  });
                                }
                              }}
                              key={t?.id}
                              value={t?.nome}
                            >
                              {t?.nome}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          sx={{
                            color: cor_preto_300,
                            fontFamily: fonte,
                            "&:hover": {
                              background: cor_principal_300,
                              color: cor_branco,
                            },
                            "&.Mui-selected": {
                              background: cor_principal_200,
                              color: cor_branco,
                            },
                          }}
                          key={-1}
                          value={"Nenhuma escola"}
                        >
                          Nenhuma escola
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Select
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: cor_branco,
                            },
                          },
                        },
                      }}
                      disabled={buscar !== "" ? true : false}
                      id="turma"
                      value={turmaSelecao.nome}
                      label="Turma"
                      style={{
                        color: cor_preto_300,
                        height: "33px",
                        fontFamily: fonte,
                      }}
                      // {...register("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        background: cor_branco,
                        color: cor_preto_300,
                        fontFamily: fonte,
                      }}
                    >
                      {turma?.items?.length > 0 ? (
                        turma?.items?.map((t: any) => {
                          return (
                            <MenuItem
                              sx={{
                                color: cor_preto_300,
                                fontFamily: fonte,
                                "&:hover": {
                                  background: cor_principal_300,
                                  color: cor_branco,
                                },
                                "&.Mui-selected": {
                                  background: cor_principal_200,
                                  color: cor_branco,
                                },
                              }}
                              onClick={(e) => {
                                if (turmaSelecao?.id !== t?.id) {
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: true,
                                    };
                                  });
                                  setAulas({ items: [], total: 0 });
                                  setTurmaSelecao(t);
                                }
                              }}
                              key={t?.id}
                              value={t?.nome}
                            >
                              {t?.nome}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          sx={{
                            color: cor_preto_300,
                            fontFamily: fonte,
                            "&:hover": {
                              background: cor_principal_300,
                              color: cor_branco,
                            },
                            "&.Mui-selected": {
                              background: cor_principal_200,
                              color: cor_branco,
                            },
                          }}
                          key={-1}
                          value={"Nenhuma turma"}
                        >
                          Nenhuma turma
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  {/* {disciplinas?.items?.length > 0 ? (
                    <Grid item>
                      <StyledDatePicker>
                        <RangeDatePicker
                          format="DD/MM/YYYY"
                          style={{
                            width: "100%",
                            height: "34px",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          }}
                          disabled={buscar !== "" ? true : false}
                          defaultValue={data}
                          onChange={(e: any) => {
                            setData(e);
                          }}
                        />
                      </StyledDatePicker>
                    </Grid>
                  ) : (
                    ""
                  )} */}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {disciplinas?.items?.length > 0
                    ? disciplinas?.items?.map((item: any) => (
                        <Grid
                          item
                          key={item?.id}
                          style={{
                            marginTop:
                              disciplinaSelecao?.id === item?.id ? "" : ".5rem",
                          }}
                        >
                          <p
                            onClick={() => {
                              if (disciplinaSelecao?.id !== item?.id) {
                                setAulas({ items: [], total: 0 });
                                setConfiguracao((antes: any) => {
                                  return {
                                    ...antes,
                                    loadingVideo: true,
                                  };
                                });

                                setDisciplinaSelecao(item);
                              }
                            }}
                            style={{
                              fontWeight: 600,
                              fontSize: "15px",
                              fontFamily: fonte,
                              cursor: "pointer",
                              userSelect: "none",
                              color:
                                disciplinaSelecao?.id === item?.id
                                  ? cor_principal_500
                                  : cor_preto_300,
                            }}
                          >
                            {item.nome}
                          </p>
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              {disciplinas?.items?.length > 0 ? (
                <Grid item xs={12} style={{ marginRight: "1rem" }}>
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: cor_principal_500,
                        borderRadius: "1rem",
                      },

                      height: 5,
                      borderRadius: 5,
                      background: cor_branco_300,
                    }}
                    variant="determinate"
                    value={
                      ((disciplinas?.items?.findIndex(
                        (item: any) => item?.id === disciplinaSelecao?.id
                      ) +
                        1) /
                        disciplinas?.items?.length) *
                      100
                    }
                  />
                </Grid>
              ) : configuracao?.loadingVideo || buscar !== "" ? (
                ""
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      status="success"
                      title="Nenhuma disciplina cadastrada!"
                      subTitle="Nenhuma disciplina cadastrada!"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={true}
            marginTop={"2rem"}
            marginRight={"1rem"}
            maxHeight={"calc(100vh - 260px)"}
            style={{
              overflow: "auto",
              marginLeft: "1.5rem",
              marginBottom: 0,
              scrollbarColor: `${cor_principal_500} #EDF2F6`,
              scrollbarWidth: "thin",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              {configuracao?.loadingVideo && disciplinas?.items?.length > 0 ? (
                <CarregarLoading />
              ) : disciplinas?.items?.length > 0 &&
                aulas?.items?.length === 0 &&
                disciplinaSelecao?.id !== -1 ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      status="success"
                      title="Nenhuma aula encontrada!"
                      subTitle="Nenhuma aula encontrada!"
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ "& .MuiGrid-root": { zIndex: "0" } }}
                >
                  <Grid item>
                    <Stepper
                      orientation="vertical"
                      activeStep={trilhaPos}
                      sx={{
                        "--Stepper-verticalGap": "1rem",
                        "--StepIndicator-size": "4rem",
                        "--Step-gap": "2.5rem",
                        "--Step-connectorInset": "-4.5rem",
                        "--Step-connectorRadius": "1rem",
                        "--Step-connectorThickness": "4px",
                        "--joy-palette-success-solidBg":
                          "var(--joy-palette-success-400)",

                        [`& .${stepClasses.completed}`]: {
                          "&::after": { bgcolor: cor_principal_500 },
                        },
                        [`& .${stepClasses.disabled}`]: {
                          "&::after": {
                            bgcolor:
                              aulas?.items?.length < aulas?.total
                                ? ""
                                : "transparent",
                          },
                        },
                        [`& .${stepClasses.completed}:last-child`]: {
                          "&::after": {
                            bgcolor:
                              aulas?.total === trilhaPos
                                ? "transparent !important"
                                : cor_principal_500,
                          },
                        },
                        [`& .${stepClasses.active}:last-child`]: {
                          "&::after": {
                            bgcolor:
                              aulas?.total - 1 === trilhaPos
                                ? "transparent !important"
                                : cor_principal_500,
                          },
                        },
                        [`& .${stepClasses.active}`]: {
                          [`& .${stepIndicatorClasses.root}`]: {
                            border: "4px solid",
                            borderColor: cor_branco,
                            boxShadow: (theme) =>
                              `0 0 0 1px ${cor_principal_500}`,
                          },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                          color: "neutral.softDisabledColor",
                        },
                        [`& .${stepClasses.root}:has(+ .${stepClasses.disabled})::after`]:
                          {
                            color: "primary.500",
                            backgroundColor: "transparent",
                            backgroundImage:
                              "radial-gradient(currentColor 2px, transparent 2px)",
                            backgroundSize: "7px 7px",
                            backgroundPosition: "center left",
                          },
                      }}
                    >
                      {aulas?.items?.map((aula: any, index: any) => (
                        <Step
                          key={aula?.id}
                          completed={index < trilhaPos ? true : false}
                          active={trilhaPos === index ? true : false}
                          disabled={index > trilhaPos ? true : false}
                          indicator={
                            <StepIndicator
                              variant="solid"
                              style={{
                                color: cor_branco,
                                background:
                                  index > trilhaPos ? "" : cor_principal_500,
                              }}
                            >
                              {index > trilhaPos ? (
                                <LockIcon />
                              ) : trilhaPos === index ? (
                                <AssignmentIcon />
                              ) : (
                                <CheckRoundedIcon />
                              )}
                            </StepIndicator>
                          }
                          style={{ paddingBottom: "1rem" }}
                        >
                          <Grid
                            item
                            height={200}
                            width={400}
                            style={{
                              marginTop: "1rem",
                              marginLeft: "1rem",
                              background: cor_principal_500,
                              borderRadius: "1rem",
                            }}
                          >
                            {(trilhaPos < index || trilhaPos > index) &&
                            getAcessoNivel2(decodedToken) === false ? (
                              <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    cursor: "default",
                                    width: "100%",
                                    height: "200px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <iframe
                                    style={{
                                      border: 0,
                                      position: "absolute",
                                      width: "130%",
                                      height: "130%",
                                      right: -40,
                                      top: -1,
                                    }}
                                    src={aula.link}
                                  ></iframe>
                                  <div
                                    style={{
                                      background:
                                        trilhaPos < index
                                          ? "rgba(0, 0, 0, 0.7)"
                                          : `rgba(${hexToRgb(
                                              cor_verde_limao_200
                                            )}, 0.7)`,
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  ></div>
                                  {trilhaPos > index ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                        color: cor_branco,
                                      }}
                                    >
                                      <CheckCircleIcon
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {index > trilhaPos ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                      }}
                                    >
                                      <LockIcon
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {aula?.data_entrega ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: 10,
                                        top: 10,
                                        background: cor_amarelo_500,
                                        fontFamily: fonte,
                                        padding: ".5rem",
                                        borderRadius: "1rem",
                                        fontSize: "11px",
                                      }}
                                    >
                                      Data de Entrega:{" "}
                                      {moment(aula?.data_entrega).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {/* <div
                                  style={{
                                    position: "absolute",
                                    left: 10,
                                    top: 10,
                                    background: cor_amarelo_500,
                                    fontFamily: fonte,
                                    padding: ".5rem",
                                    borderRadius: "1rem",
                                    fontSize: "11px",
                                  }}
                                >
                                  D{index + 1}
                                </div> */}
                                  {aula.status ? (
                                    ""
                                  ) : (
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        color: cor_branco,
                                        padding: ".5rem",
                                        borderRadius: "1rem",
                                        fontSize: "11px",
                                      }}
                                    >
                                      <HttpsIcon />
                                    </div>
                                  )}

                                  <div
                                    style={{
                                      position: "absolute",
                                      left: 10,
                                      top: 80,
                                      fontWeight: 600,
                                      background: cor_branco,
                                      fontFamily: fonte,
                                      color: cor_preto_400,
                                      padding: ".5rem",
                                      borderRadius: "1rem",
                                      fontSize: "18px",
                                      textOverflow: "ellipsis",
                                      maxWidth: "321px",
                                      overflow: "hidden",
                                      margin: 0,
                                      maxHeight: "2.57rem",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      display: "-webkit-box",
                                    }}
                                  >
                                    {aula?.nome}
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        textAlign: "justify",
                                        position: "absolute",
                                        left: 20,
                                        top: 120,
                                        fontFamily: fonte,
                                        color: cor_branco,
                                        fontSize: "11px",
                                        marginRight: "1rem",
                                        overflowWrap: "break-word",
                                        width: "90%",
                                        maxHeight: "34%",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        WebkitLineClamp: 6,
                                        WebkitBoxOrient: "vertical",
                                        display: "-webkit-box",
                                      }}
                                    >
                                      {aula?.descricao}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                            ) : (
                              <Link href={"/trilhas/" + aula?.id}>
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      cursor: "pointer",
                                      width: "100%",
                                      height: "200px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <iframe
                                      style={{
                                        border: 0,
                                        position: "absolute",
                                        width: "130%",
                                        height: "130%",
                                        right: -40,
                                        top: -1,
                                      }}
                                      src={aula.link}
                                    ></iframe>
                                    <div
                                      style={{
                                        background: `rgba(${hexToRgb(
                                          cor_principal_500
                                        )}, 0.7)`,
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    ></div>

                                    {aula?.data_entrega ? (
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: 10,
                                          top: 10,
                                          background: cor_amarelo_500,
                                          fontFamily: fonte,
                                          padding: ".5rem",
                                          borderRadius: "1rem",
                                          fontSize: "11px",
                                        }}
                                      >
                                        Data de Entrega:{" "}
                                        {moment(aula?.data_entrega).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                        color: cor_branco,
                                      }}
                                    >
                                      <PlayCircleOutlineIcon
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </div>
                                    {aulas?.items[index].submissao?.length >
                                    0 ? (
                                      <div
                                        style={{
                                          position: "absolute",
                                          bottom: 10,
                                          right: 10,
                                          color: cor_verde_500,
                                        }}
                                      >
                                        <CheckCircleIcon
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {/* <div
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 10,
                            background: cor_amarelo_500,
                            fontFamily: fonte,
                            padding: ".5rem",
                            borderRadius: "1rem",
                            fontSize: "11px",
                          }}
                        >
                          D{index + 1}
                        </div> */}
                                    {aula.status ? (
                                      ""
                                    ) : (
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          top: 0,
                                          color: cor_branco,
                                          padding: ".5rem",
                                          borderRadius: "1rem",
                                          fontSize: "11px",
                                        }}
                                      >
                                        <HttpsIcon />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: 10,
                                        top: 80,
                                        fontWeight: 600,
                                        background: cor_branco,
                                        fontFamily: fonte,
                                        color: cor_preto_400,
                                        padding: ".5rem",
                                        borderRadius: "1rem",
                                        fontSize: "18px",
                                        textOverflow: "ellipsis",
                                        maxWidth: "321px",
                                        overflow: "hidden",
                                        margin: 0,
                                        maxHeight: "2.57rem",
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: "vertical",
                                        display: "-webkit-box",
                                      }}
                                    >
                                      {aula?.nome}
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          textAlign: "justify",
                                          position: "absolute",
                                          left: 20,
                                          top: 120,
                                          fontFamily: fonte,
                                          color: cor_branco,
                                          fontSize: "11px",
                                          marginRight: "1rem",
                                          overflowWrap: "break-word",
                                          width: "90%",
                                          maxHeight: "34%",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          WebkitLineClamp: 6,
                                          WebkitBoxOrient: "vertical",
                                          display: "-webkit-box",
                                        }}
                                      >
                                        {aula?.descricao}
                                      </p>
                                    </div>
                                  </div>
                                </Grid>
                              </Link>
                            )}
                            {getAcessoNivel2(decodedToken) ? (
                              <Button
                                style={{
                                  background: cor_principal_500,
                                  color: cor_branco_200,
                                  width: "100%",
                                  marginTop: "-.2rem",
                                  fontFamily: fonte,
                                }}
                                size="small"
                                onClick={() => {
                                  setItemFuncao(aula);
                                  setOpenListar(true);
                                }}
                                variant={"contained"}
                              >
                                Respostas
                              </Button>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {aulas?.items?.length !== aulas?.total ? (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <LoadingButton
                      loading={configuracao?.carregarMais}
                      variant="outlined"
                      sx={{
                        background: cor_branco,
                        color: cor_preto_300,
                        fontFamily: fonte,
                        borderColor: cor_branco,
                        marginTop: "1rem",
                        marginBottom: ".5rem",
                        "&:hover": {
                          borderColor: cor_principal_500,
                        },
                      }}
                      onClick={(e) => handlePage(aulas.page + 1)}
                    >
                      Carregar mais
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Trilha;
