import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Avatar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import Boneco from "../../img/PNG.png";
import Ret02 from "../../img/SVG (1).png";
import Ret03 from "../../img/SVG (2).png";
import Ret01 from "../../img/SVG.png";
import api from "../axios/axios";
import EscolherFoto from "../foto/escolher";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import {
  AlterarSenha,
  ConfiguracoesPerfil,
  ConfiguracoesPerfilNome,
  ConfiguracoesPerfilNomeArroba,
  ConfiguracoesPerfilTexto,
  ConfiguracoesPerfilTextoNotificacao,
  ConfiguracoesTexto,
  ConfiguracoesTextoPerfil,
  ConfiguracoesTextoSelecao,
  cor_branco,
  cor_branco_300,
  cor_fundo,
  cor_preto_100,
  cor_preto_200,
  cor_preto_300,
  cor_preto_500,
  cor_principal_400,
  cor_principal_500,
  cor_verde_500,
  cor_verde_limao_100,
  cor_verde_limao_400,
  cor_vermelho_500,
  fonte,
  mobile,
} from "../theme";

const schema = yup
  .object()
  .shape({
    senha: yup.string().required("Por favor, preencha este campo."),
    senhaNova: yup.string().required("Por favor, preencha este campo."),
    senhaNovaConfirma: yup.string().required("Por favor, preencha este campo."),
  })
  .required();

const Configuracoes = ({ decodedToken, setDecodedToken }: any) => {
  const { formState, register, handleSubmit } = useForm({
    defaultValues: {
      senha: "",
      senhaNova: "",
      senhaNovaConfirma: "",
    },
    resolver: yupResolver(schema),
  });

  const [openFoto, setOpenFoto] = useState(false);

  const onSubmit = (data: any) => {
    toast.info("Atualizando senha");
    api
      .post("perfil/senha", data)
      .then(() => toast.success("Senha atualizada"))
      .catch(() => toast.error("Senha não atualizada"));
  };

  const [selecao, setSelecao] = useState(2);
  const [recados, setRecados] = useState(true);
  const [avisos, setAvisos] = useState(true);
  const [faltas, setFaltas] = useState(true);
  const [novasAulas, setNovasAulas] = useState(true);
  const [lembretes, setLembretes] = useState(false);
  const [open, setOpen] = useState(false);

  const [senhaMostrar, setSenhaMostrar] = useState(false);
  const [senhaNovaMostrar, setSenhaNovaMostrar] = useState(false);
  const [senhaNovaConfirmaMostrar, setSenhaNovaConfirmaMostrar] =
    useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose} style={{ overflow: "hidden" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          style={{ padding: "2rem", paddingBottom: 0 }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={true}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    background: cor_verde_limao_100,
                    padding: "1rem",
                    borderRadius: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  <LockIcon style={{ color: cor_verde_limao_400 }} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AlterarSenha style={{ textAlign: "center" }}>
                  Alterar Senha
                </AlterarSenha>
              </Grid>

              <Grid item xs={12} marginTop={"2rem"}>
                <TextField
                  fullWidth
                  size="small"
                  {...register("senha")}
                  label="Senha Atual"
                  required
                  error={Boolean(formState?.errors?.senha)}
                  helperText={formState.errors.senha?.message}
                  type={senhaMostrar ? "text" : "password"}
                  sx={{
                    fieldset: { borderColor: cor_preto_200 },
                    label: {
                      color: cor_preto_300,
                      //  fontFamily: fonte, fontSize: "11px", marginTop: "3px"
                    },
                    ".MuiOutlinedInput-input": {
                      color: cor_preto_300,
                    },
                    "& label": {
                      color: cor_preto_300,
                    },
                    "& label.Mui-focused": {
                      color: cor_principal_500,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: cor_principal_500,
                      },
                      "& fieldset": {
                        borderColor: cor_preto_100,
                      },
                      "&:hover fieldset": {
                        borderColor: cor_principal_500,
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(_) => setSenhaMostrar(!senhaMostrar)}
                        edge="end"
                        style={{
                          borderLeft: `1px solid ${cor_preto_300}`,
                          borderRadius: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {senhaMostrar ? (
                          <VisibilityOffIcon style={{ color: cor_preto_300 }} />
                        ) : (
                          <VisibilityIcon style={{ color: cor_preto_300 }} />
                        )}
                      </IconButton>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} marginTop={"2rem"}>
                <TextField
                  fullWidth
                  size="small"
                  {...register("senhaNova")}
                  error={Boolean(formState?.errors?.senhaNova)}
                  helperText={formState.errors.senhaNova?.message}
                  required
                  label="Nova Senha"
                  type={senhaNovaMostrar ? "text" : "password"}
                  sx={{
                    fieldset: { borderColor: cor_preto_200 },
                    label: {
                      color: cor_preto_300,
                      //  fontFamily: fonte, fontSize: "11px", marginTop: "3px"
                    },
                    ".MuiOutlinedInput-input": {
                      color: cor_preto_300,
                    },
                    "& label": {
                      color: cor_preto_300,
                    },
                    "& label.Mui-focused": {
                      color: cor_principal_500,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: cor_principal_500,
                      },
                      "& fieldset": {
                        borderColor: cor_preto_100,
                      },
                      "&:hover fieldset": {
                        borderColor: cor_principal_500,
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(_) => setSenhaNovaMostrar(!senhaNovaMostrar)}
                        edge="end"
                        style={{
                          borderLeft: `1px solid ${cor_preto_300}`,
                          borderRadius: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {senhaNovaMostrar ? (
                          <VisibilityOffIcon style={{ color: cor_preto_300 }} />
                        ) : (
                          <VisibilityIcon style={{ color: cor_preto_300 }} />
                        )}
                      </IconButton>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} marginTop={"2rem"}>
                <TextField
                  fullWidth
                  size="small"
                  {...register("senhaNovaConfirma")}
                  error={Boolean(formState?.errors?.senhaNovaConfirma)}
                  helperText={formState.errors.senhaNovaConfirma?.message}
                  required
                  label="Confirmar Nova Senha"
                  type={senhaNovaConfirmaMostrar ? "text" : "password"}
                  sx={{
                    fieldset: { borderColor: cor_preto_200 },
                    label: {
                      color: cor_preto_300,
                      //  fontFamily: fonte, fontSize: "11px", marginTop: "3px"
                    },
                    ".MuiOutlinedInput-input": {
                      color: cor_preto_300,
                    },
                    "& label": {
                      color: cor_preto_300,
                    },
                    "& label.Mui-focused": {
                      color: cor_principal_500,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: cor_principal_500,
                      },
                      "& fieldset": {
                        borderColor: cor_preto_100,
                      },
                      "&:hover fieldset": {
                        borderColor: cor_principal_500,
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(_) =>
                          setSenhaNovaConfirmaMostrar(!senhaNovaConfirmaMostrar)
                        }
                        edge="end"
                        style={{
                          borderLeft: `1px solid ${cor_preto_300}`,
                          borderRadius: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {senhaNovaConfirmaMostrar ? (
                          <VisibilityOffIcon style={{ color: cor_preto_300 }} />
                        ) : (
                          <VisibilityIcon style={{ color: cor_preto_300 }} />
                        )}
                      </IconButton>
                    ),
                  }}
                ></TextField>
              </Grid>

              <Grid
                item
                style={{ marginTop: "2rem" }}
                xs={12}
                width={"30rem"}
                marginBottom={"1rem"}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={4}
                  alignItems="center"
                >
                  <Grid item style={{ marginBottom: "1rem" }}>
                    <Button
                      style={{
                        color: cor_vermelho_500,
                        borderColor: cor_vermelho_500,
                        fontFamily: fonte,
                      }}
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item style={{ marginBottom: "1rem" }}>
                    <Button
                      sx={{
                        "&:hover": {
                          // background: "#00519E",
                        },
                        color: cor_branco,
                        background: cor_principal_500,
                      }}
                      style={{ fontFamily: fonte }}
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      Alterar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Dialog>

      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={6}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto", width: "100%" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{ background: cor_branco, padding: 0, margin: 0 }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Configurações"} />
          </Grid>
          <Grid
            item
            xs={12}
            padding={3}
            paddingLeft={1}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "1rem"}
          >
            <Grid
              container
              direction={mobile(windowSize) ? "column" : "row"}
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ minHeight: "100%" }}
            >
              <Grid item minWidth={"100%"}>
                <Grid item style={{ padding: "2rem", paddingTop: "3rem" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item marginRight={"-4rem"} marginBottom={"1rem"}>
                      <ConfiguracoesPerfil>Seu perfil</ConfiguracoesPerfil>
                      <ConfiguracoesTexto>
                        Atualmente, este é o seu perfil.
                      </ConfiguracoesTexto>
                      <Grid
                        xs={8}
                        marginTop={"2rem"}
                        item
                        style={{
                          background: cor_branco,
                          borderRadius: "1rem",
                          padding: "1rem",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-start"
                          rowSpacing={2}
                        >
                          <Grid item>
                            <Avatar
                              src={decodedToken?.foto}
                              style={{
                                width: "6rem",
                                height: "6rem",
                              }}
                            />
                            <EscolherFoto
                              decodedToken={decodedToken}
                              open={openFoto}
                              setDecodedToken={setDecodedToken}
                              setOpen={setOpenFoto}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ConfiguracoesPerfilNome
                              style={{ textAlign: "center" }}
                            >
                              {decodedToken?.preferred_username}
                            </ConfiguracoesPerfilNome>
                            <ConfiguracoesPerfilNomeArroba
                              style={{ textAlign: "center" }}
                            >
                              @
                              {decodedToken?.realm_access?.roles?.includes(
                                "professor"
                              )
                                ? "professor "
                                : decodedToken?.realm_access?.roles?.includes(
                                    "aluno"
                                  )
                                ? "aluno"
                                : decodedToken?.realm_access?.roles?.includes(
                                    "gestor"
                                  )
                                ? "gestor"
                                : ""}
                            </ConfiguracoesPerfilNomeArroba>
                          </Grid>
                          <Grid item xs={12}>
                            <ConfiguracoesTextoPerfil
                              style={{ textAlign: "center" }}
                            >
                              Escolas:
                            </ConfiguracoesTextoPerfil>
                            <ConfiguracoesTextoPerfil
                              style={{ textAlign: "center" }}
                            >
                              Curso intensivo CESAN
                            </ConfiguracoesTextoPerfil>
                            {/*<ConfiguracoesTextoPerfil*/}
                            {/*  style={{ textAlign: "center" }}*/}
                            {/*>*/}
                            {/*  CEPMG Polivalente*/}
                            {/*</ConfiguracoesTextoPerfil>*/}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={true}>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item>
                            <ConfiguracoesTextoSelecao
                              onClick={(_: any) => setSelecao(1)}
                              $selecao={selecao === 1}
                            >
                              Configuações da conta
                            </ConfiguracoesTextoSelecao>
                          </Grid>
                          <Grid item>
                            <ConfiguracoesTextoSelecao
                              onClick={(_: any) => setSelecao(2)}
                              $selecao={selecao === 2}
                            >
                              Dados Pessoais
                            </ConfiguracoesTextoSelecao>
                          </Grid>
                          <Grid item>
                            <ConfiguracoesTextoSelecao
                              onClick={(_: any) => setSelecao(3)}
                              $selecao={selecao === 3}
                            >
                              Notificações
                            </ConfiguracoesTextoSelecao>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          width={"100%"}
                          marginTop={"1rem"}
                          height={5}
                          style={{
                            background: cor_branco_300,
                            borderRadius: "1rem",
                          }}
                        >
                          <Grid item>
                            {selecao === 2 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                                                             
                              </div>
                            ) : selecao === 1 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                  background: cor_principal_500,
                                  height: 5,
                                  width: "100%",
                                  borderRadius: "1rem",
                                }}
                              >
                                                                             
                              </div>
                            ) : selecao === 3 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                                                             
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item>
                            {selecao === 2 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                  background: cor_principal_500,
                                  height: 5,
                                  width: "100%",
                                  borderRadius: "1rem",
                                }}
                              >
                                                                             
                              </div>
                            ) : selecao === 1 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                                                             
                              </div>
                            ) : selecao === 3 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                                       
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item>
                            {selecao === 2 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                                       
                              </div>
                            ) : selecao === 1 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                }}
                              >
                                                       
                              </div>
                            ) : selecao === 3 ? (
                              <div
                                style={{
                                  cursor: "default",
                                  userSelect: "none",
                                  background: cor_principal_500,
                                  height: 5,
                                  width: "100%",
                                  borderRadius: "1rem",
                                }}
                              >
                                                                             
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid style={{ marginTop: "2.5rem" }} item xs={true}>
                        {selecao === 2 ? (
                          <form>
                            <Grid
                              container
                              direction="column"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              rowSpacing={4}
                            >
                              <Grid item>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="nome"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Nome"
                                  value={decodedToken?.given_name}
                                  type="text"
                                ></TextField>
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="matricula"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Matrícula"
                                  value={decodedToken?.preferred_username}
                                  type="text"
                                ></TextField>
                              </Grid>
                              {/* <Grid item>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="telefone"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Telefone"
                                  value={"(62)99357-2100"}
                                  type="text"
                                ></TextField>
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="endereco"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Endereço"
                                  value={"Avenida T-63"}
                                  type="text"
                                ></TextField>
                              </Grid> */}
                            </Grid>
                          </form>
                        ) : selecao === 1 ? (
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              rowSpacing={4}
                            >
                              <Grid item style={{ width: "100%" }}>
                                <Button
                                  sx={{
                                    color: cor_principal_500,
                                    borderColor: cor_principal_500,
                                    fontFamily: fonte,
                                  }}
                                  variant="outlined"
                                  onClick={() => {
                                    setOpenFoto(true);
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  Mudar Foto de Perfil
                                </Button>
                              </Grid>
                              <Grid item style={{ width: "100%" }}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="apelido"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Apelido"
                                  value={decodedToken?.preferred_username}
                                  type="text"
                                ></TextField>
                              </Grid>
                              {/* <Grid item style={{ width: "100%" }}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="email"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="E-mail"
                                  value={"anabrito@educago.com"}
                                  type="text"
                                ></TextField>
                              </Grid> */}
                              <Grid item style={{ width: "100%" }}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  name="senha"
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: cor_preto_300,
                                    },
                                    "& label": {
                                      color: cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor: cor_principal_500,
                                      },
                                    },
                                  }}
                                  label="Senha"
                                  value={"teste-32158"}
                                  type="password"
                                ></TextField>
                                <Button
                                  onClick={(_) => setOpen(true)}
                                  startIcon={
                                    <EditIcon style={{ color: cor_branco }} />
                                  }
                                  style={{
                                    marginTop: ".5rem",
                                    background: cor_verde_500,
                                    color: cor_branco,
                                    fontFamily: fonte,
                                  }}
                                  variant="contained"
                                  size="small"
                                >
                                  Alterar senha
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        ) : selecao === 3 ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Grid item xs={true}>
                              <ConfiguracoesPerfilTexto
                                style={{ marginBottom: "1rem" }}
                              >
                                Notificações por e-mail
                              </ConfiguracoesPerfilTexto>
                              <ConfiguracoesTextoPerfil>
                                Receba e-mails para saber o que está acontecendo
                                enquanto não está online. Você pode
                                desativá-los.
                              </ConfiguracoesTextoPerfil>
                            </Grid>
                            <Grid item xs={true}>
                              <Grid item>
                                <ConfiguracoesPerfilTexto>
                                  <Switch
                                    sx={{
                                      "& .MuiSwitch-switchBase": {
                                        "&.Mui-checked": {
                                          "& + .MuiSwitch-track": {
                                            backgroundColor: cor_principal_500,
                                          },
                                        },
                                        "& + .MuiSwitch-track": {
                                          backgroundColor: cor_preto_500,
                                        },
                                      },
                                      "&.MuiSwitch-root .MuiSwitch-switchBase":
                                        {
                                          color: cor_branco,
                                        },
                                      "&.MuiSwitch-root .Mui-checked": {
                                        color: cor_principal_500,
                                      },
                                    }}
                                    checked={true}
                                    disabled={true}
                                    onClick={(_) => setRecados(!recados)}
                                  />
                                  Recados Importantes
                                </ConfiguracoesPerfilTexto>
                                <ConfiguracoesPerfilTextoNotificacao>
                                  Recados automáticos enviados pelos gestores.
                                </ConfiguracoesPerfilTextoNotificacao>
                              </Grid>
                              {/*<Grid item>*/}
                              {/*  <ConfiguracoesPerfilTexto>*/}
                              {/*    <Switch*/}
                              {/*      sx={{*/}
                              {/*        "& .MuiSwitch-switchBase": {*/}
                              {/*          "&.Mui-checked": {*/}
                              {/*            "& + .MuiSwitch-track": {*/}
                              {/*              backgroundColor: cor_principal_500,*/}
                              {/*            },*/}
                              {/*          },*/}
                              {/*          "& + .MuiSwitch-track": {*/}
                              {/*            backgroundColor: cor_preto_500,*/}
                              {/*          },*/}
                              {/*        },*/}
                              {/*        "&.MuiSwitch-root .MuiSwitch-switchBase":*/}
                              {/*          {*/}
                              {/*            color: cor_branco,*/}
                              {/*          },*/}
                              {/*        "&.MuiSwitch-root .Mui-checked": {*/}
                              {/*          color: cor_principal_500,*/}
                              {/*        },*/}
                              {/*      }}*/}
                              {/*      checked={avisos}*/}
                              {/*      onClick={(_) => setAvisos(!avisos)}*/}
                              {/*    />*/}
                              {/*    Avisos SEDUC*/}
                              {/*  </ConfiguracoesPerfilTexto>*/}
                              {/*  <ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*    Receba os avisos da Secretaria de Educação*/}
                              {/*    diretamente no seu e-mail.*/}
                              {/*  </ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*</Grid>*/}
                              {/*<Grid item>*/}
                              {/*  <ConfiguracoesPerfilTexto>*/}
                              {/*    <Switch*/}
                              {/*      sx={{*/}
                              {/*        "& .MuiSwitch-switchBase": {*/}
                              {/*          "&.Mui-checked": {*/}
                              {/*            "& + .MuiSwitch-track": {*/}
                              {/*              backgroundColor: cor_principal_500,*/}
                              {/*            },*/}
                              {/*          },*/}
                              {/*          "& + .MuiSwitch-track": {*/}
                              {/*            backgroundColor: cor_preto_500,*/}
                              {/*          },*/}
                              {/*        },*/}
                              {/*        "&.MuiSwitch-root .MuiSwitch-switchBase":*/}
                              {/*          {*/}
                              {/*            color: cor_branco,*/}
                              {/*          },*/}
                              {/*        "&.MuiSwitch-root .Mui-checked": {*/}
                              {/*          color: cor_principal_500,*/}
                              {/*        },*/}
                              {/*      }}*/}
                              {/*      checked={faltas}*/}
                              {/*      onClick={(_) => setFaltas(!faltas)}*/}
                              {/*    />*/}
                              {/*    Faltas*/}
                              {/*  </ConfiguracoesPerfilTexto>*/}
                              {/*  <ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*    Receba um aviso sempre que um estudante se*/}
                              {/*    ausentar da escola.*/}
                              {/*  </ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*</Grid>*/}
                              {/*<Grid item>*/}
                              {/*  <ConfiguracoesPerfilTexto>*/}
                              {/*    <Switch*/}
                              {/*      sx={{*/}
                              {/*        "& .MuiSwitch-switchBase": {*/}
                              {/*          "&.Mui-checked": {*/}
                              {/*            "& + .MuiSwitch-track": {*/}
                              {/*              backgroundColor: cor_principal_500,*/}
                              {/*            },*/}
                              {/*          },*/}
                              {/*          "& + .MuiSwitch-track": {*/}
                              {/*            backgroundColor: cor_preto_500,*/}
                              {/*          },*/}
                              {/*        },*/}
                              {/*        "&.MuiSwitch-root .MuiSwitch-switchBase":*/}
                              {/*          {*/}
                              {/*            color: cor_branco,*/}
                              {/*          },*/}
                              {/*        "&.MuiSwitch-root .Mui-checked": {*/}
                              {/*          color: cor_principal_500,*/}
                              {/*        },*/}
                              {/*      }}*/}
                              {/*      checked={novasAulas}*/}
                              {/*      onClick={(_) => setNovasAulas(!novasAulas)}*/}
                              {/*    />*/}
                              {/*    Novas Aulas*/}
                              {/*  </ConfiguracoesPerfilTexto>*/}
                              {/*  <ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*    Receba uma mensagem sempre que uma nova aula*/}
                              {/*    estiver disponível.*/}
                              {/*  </ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*</Grid>*/}
                              {/*<Grid item>*/}
                              {/*  <ConfiguracoesPerfilTexto>*/}
                              {/*    <Switch*/}
                              {/*      sx={{*/}
                              {/*        "& .MuiSwitch-switchBase": {*/}
                              {/*          "&.Mui-checked": {*/}
                              {/*            "& + .MuiSwitch-track": {*/}
                              {/*              backgroundColor: cor_principal_500,*/}
                              {/*            },*/}
                              {/*          },*/}
                              {/*          "& + .MuiSwitch-track": {*/}
                              {/*            backgroundColor: cor_preto_500,*/}
                              {/*          },*/}
                              {/*        },*/}
                              {/*        "&.MuiSwitch-root .MuiSwitch-switchBase":*/}
                              {/*          {*/}
                              {/*            color: cor_branco,*/}
                              {/*          },*/}
                              {/*        "&.MuiSwitch-root .Mui-checked": {*/}
                              {/*          color: cor_principal_500,*/}
                              {/*        },*/}
                              {/*      }}*/}
                              {/*      checked={lembretes}*/}
                              {/*      onClick={(_) => setLembretes(!lembretes)}*/}
                              {/*    />*/}
                              {/*    Lembretes*/}
                              {/*  </ConfiguracoesPerfilTexto>*/}
                              {/*  <ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*    Notificações para te lembrar de acessar a*/}
                              {/*    plataforma.*/}
                              {/*  </ConfiguracoesPerfilTextoNotificacao>*/}
                              {/*</Grid>*/}
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                padding={2}
                minWidth={"100%"}
                style={{
                  marginRight: "0rem",
                  paddingLeft: "0rem",
                  paddingRight: "0rem",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    background: cor_principal_400,
                    borderRadius: "1rem",
                  }}
                >
                  <Grid item xs>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      minHeight={"100%"}
                    >
                      <Grid item minHeight={"100%"}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-end"
                          minHeight={"100%"}
                        >
                          <Grid item>
                            <img src={Ret01} alt="Imagem de boas vindas" />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          minHeight={"100%"}
                        >
                          <Grid item xs={12} style={{ paddingLeft: "1rem" }}>
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: "24px",
                                fontFamily: fonte,
                                color: cor_branco,
                              }}
                            >
                              Algum dado não está correto?
                            </p>

                            <p
                              style={{
                                marginTop: "-1rem",
                                marginBottom: "4rem",
                                paddingRight: "1rem",
                                fontFamily: fonte,
                                color: cor_branco,
                              }}
                            >
                              Entre em contato com o gestor
                              {/* Solicite a alteração para a Secretaria{" "}
                              <span
                                style={{
                                  color: cor_amarelo_500,
                                  textDecoration: "underline",
                                }}
                              >
                                aqui.
                              </span> */}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      height={"100%"}
                      minWidth={"100%"}
                      alignItems="flex-end"
                    >
                      <Grid item style={{ marginTop: "-5rem" }}>
                        <img
                          style={{ marginBottom: "-.2rem" }}
                          src={Boneco}
                          height={"230rem"}
                          alt="Imagem de boas vindas"
                        />
                      </Grid>
                      <Grid item height={"100%"}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          height={"100%"}
                          paddingTop={"1rem"}
                          paddingBottom={"1rem"}
                          style={{ paddingRight: ".3rem" }}
                        >
                          <Grid item>
                            <img
                              src={Ret02}
                              height={"auto"}
                              alt="Imagem de boas vindas"
                            />
                          </Grid>
                          <Grid item>
                            <img
                              src={Ret03}
                              height={"70px"}
                              alt="Imagem de boas vindas"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Configuracoes;
