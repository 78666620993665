import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Grid, Link, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
  cor_branco,
  cor_preto_300,
  cor_principal_200,
  cor_principal_300,
  fonte,
} from "../theme";

const SubMenuTrilhas = ({ setEscurecer, btnSelecao }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setEscurecer(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        fullWidth
        onClick={(e: any) => {
          handleClick(e);

          setEscurecer(true);
        }}
        variant={btnSelecao === 20 ? "contained" : "text"}
        style={{
          alignItems: "center",
          color: btnSelecao === 20 ? cor_preto_300 : cor_branco,
          background: btnSelecao === 20 ? cor_branco : "none",
          textTransform: "capitalize",
          fontFamily: fonte,
          marginTop: ".2rem",
          marginBottom: ".2rem",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            maxHeight: "22.75px",
          }}
        >
          <Grid item style={{ marginTop: "-.2rem" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                item
                style={{
                  marginLeft: btnSelecao === 20 ? "-.7rem" : "-.4em",
                }}
              >
                <AssignmentIcon style={{ marginRight: 8 }} />
              </Grid>
              <Grid item style={{ marginTop: "-.3rem" }}>
                Trilhas
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <NavigateNextIcon />
          </Grid>
        </Grid>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          color: btnSelecao === 4 ? cor_preto_300 : cor_branco,

          textTransform: "capitalize",
          fontFamily: fonte,
          left: ".5rem",
        }}
        sx={{
          ".MuiPaper-root": { background: cor_branco },
        }}
      >
        <Link
          href="/trilhas"
          style={{
            textDecoration: "none",
            color: cor_preto_300,
          }}
        >
          <MenuItem
            sx={{
              color: cor_preto_300,
              fontFamily: fonte,
              "&:hover": {
                background: cor_principal_300,
                color: cor_branco,
              },
              "&.Mui-selected": {
                background: cor_principal_200,
                color: cor_branco,
              },
            }}
          >
            <AssignmentIcon
              style={{
                marginRight: ".5rem",
                height: "1.4rem",
              }}
            />
            Trilhas
          </MenuItem>
        </Link>
        <Link
          href="/trilhas/turma"
          style={{
            textDecoration: "none",
            color: cor_preto_300,
          }}
        >
          <MenuItem
            sx={{
              color: cor_preto_300,
              fontFamily: fonte,
              "&:hover": {
                background: cor_principal_300,
                color: cor_branco,
              },
              "&.Mui-selected": {
                background: cor_principal_200,
                color: cor_branco,
              },
            }}
          >
            <AddCircleOutlineIcon
              style={{
                marginRight: ".5rem",
                height: "1.4rem",
              }}
            />
            Adicionar Conteúdo
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

React.memo(SubMenuTrilhas);

export default SubMenuTrilhas;
