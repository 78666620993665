import HttpsIcon from "@mui/icons-material/Https";
import SchoolIcon from "@mui/icons-material/School";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
} from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import moment from "moment";
import { useEffect, useState } from "react";
import Ret04 from "../../img/Group 125.png";
import Boneco from "../../img/bonecoOi.png";
import Maca from "../../img/maki_school.png";
import api from "../axios/axios";
import Carregar from "../carregar";
import Erro from "../erro";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import {
  cor_branco,
  cor_branco_300,
  cor_fim_progresso_home,
  cor_fundo,
  cor_inicio_progresso_home,
  cor_preto_100,
  cor_preto_200,
  cor_preto_300,
  cor_preto_400,
  cor_principal_500,
  cor_verde_500,
  cor_verde_limao_400,
  fonte,
  mobile,
} from "../theme";
import { useNavigate } from "react-router-dom";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { hexToRgb } from "../../types";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Cards = ({ aula }: any) => {
  return (
    <Link
      style={{ textDecoration: "none", cursor: "pointer" }}
      href={"/aulas/" + aula?.id}
    >
      <Grid
        style={{ width: "100%" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{
          padding: ".5rem",
          background: cor_branco,
          borderRadius: "1rem",
          borderColor: cor_branco,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          overflowWrap: "break-word",
          height: "100%",
        }}
      >
        <Grid
          item
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 0,
            marginTop: 0,
          }}
        >
          <Grid item style={{ height: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid
                item
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <iframe
                  src={aula?.link}
                  style={{ border: "0rem", pointerEvents: "none" }}
                  width={80}
                  height={60}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={true} paddingRight={"1rem"}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <span
                style={{
                  color: cor_verde_500,
                  fontFamily: fonte,
                  fontSize: "12px",
                  fontWeight: 600,
                  overflowWrap: "break-word",
                }}
              >
                Dia {moment(aula?.timestamp).format("DD/MM/YY")} - {aula?.nome}
              </span>
            </Grid>
            <Grid item>
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: fonte,
                  fontSize: "10px",
                  color: cor_preto_300,
                  overflowWrap: "break-word",
                }}
              >
                {aula?.descricao}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

const Recados = ({
  recados,
  setRecados,
  setConfiguracao,
  configuracao,
}: any) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [item, setItem] = useState<any>({});

  const handlePage = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    api
      .get(`/recado?page=` + page)
      .then((r2) => {
        setRecados((antes: any) => {
          return {
            ...antes,
            items: [...antes?.items, ...r2.data?.items],
            total: r2.data?.total,
          };
        });
      })
      .finally(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, carregarMais: false };
        })
      );
  };

  return (
    <>
      <React.Fragment>
        <Dialog
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "1.5rem",
              minHeight: "50%",
              minWidth: "50%",
              background: cor_branco,
            },
          }}
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: cor_preto_400,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              color: cor_preto_300,
              fontFamily: fonte,
              fontSize: "18px",
              fontWeight: 600,
              marginTop: ".5rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
              padding: "2rem",
            }}
          >
            <Grid item>
              <p style={{ textAlign: "center" }}>{item?.titulo}</p>
            </Grid>
            <Grid item>
              <p style={{ textAlign: "center" }}>
                {moment(item?.timestamp).format("DD/MM/YYYY HH:mm")}
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              color: cor_preto_300,
              fontFamily: fonte,
              fontSize: "18px",

              paddingTop: "0rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
              padding: "2rem",
            }}
          >
            <Grid item style={{ height: "auto", width: "100%" }}>
              <p style={{ textAlign: "center" }}>{item?.mensagem}</p>
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
      <Typography
        id="ellipsis-list-demo"
        level="body-xs"
        textTransform="uppercase"
        style={{
          fontFamily: fonte,
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "24px",
        }}
      >
        <span style={{ fontFamily: fonte, color: cor_preto_200 }}>Recados</span>
      </Typography>
      <List
        aria-labelledby="ellipsis-list-demo"
        sx={{ "--ListItemDecorator-size": "56px", marginLeft: "-1rem" }}
      >
        {recados?.items.length > 0 ? (
          recados?.items?.map((item: any, index: any) => (
            <ListItem
              key={item?.id}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setItem(item);
                setOpen(true);
              }}
            >
              <ListItemDecorator>
                <Avatar
                  style={{ backgroundColor: cor_branco_300, padding: ".3rem" }}
                >
                  <img
                    src={Maca}
                    alt="Icone Maça e Lápis"
                    style={{ width: "2rem", height: "auto" }}
                  />
                </Avatar>
              </ListItemDecorator>
              <ListItemContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Grid
                      item
                      style={{
                        fontWeight: 600,
                        fontFamily: fonte,
                        color: cor_preto_400,
                      }}
                    >
                      {item?.titulo}
                    </Grid>
                    <Grid item>
                      <span style={{ fontFamily: fonte, color: cor_preto_400 }}>
                        {item?.mensagem}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontFamily: fonte,
                      fontSize: "13px",
                    }}
                  >
                    <span style={{ fontFamily: fonte, color: cor_preto_400 }}>
                      {moment(item?.timestamp).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </Grid>
                </Grid>
              </ListItemContent>
            </ListItem>
          ))
        ) : (
          <ListItem key={-1} style={{ width: "100%" }}>
            <ListItemDecorator style={{ width: "100%" }}>
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: cor_preto_300,
                  fontFamily: fonte,
                  marginBottom: "-1rem",
                  marginTop: "-.5rem",
                }}
              >
                Nenhum recado!
              </p>
            </ListItemDecorator>
          </ListItem>
        )}

        {recados?.items?.length < recados?.total ? (
          <ListItem key={-2} style={{ width: "100%" }}>
            <ListItemDecorator style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <LoadingButton
                    variant="outlined"
                    sx={{
                      background: cor_branco,
                      color: cor_preto_300,
                      fontFamily: fonte,
                      borderColor: cor_preto_300,
                      marginTop: "1rem",
                      marginBottom: ".5rem",
                      "&:hover": {
                        borderColor: cor_principal_500,
                      },
                    }}
                    loading={configuracao?.carregarMais}
                    onClick={(e) => handlePage(recados.page + 1)}
                  >
                    Carregar mais
                  </LoadingButton>
                </Grid>
              </Grid>
            </ListItemDecorator>
          </ListItem>
        ) : (
          ""
        )}
      </List>
    </>
  );
};

const Home = ({ decodedToken }: any) => {
  const deParaData: any = {
    Do: "D",
    "2ª": "S",
    "3ª": "T",
    "4ª": "Q",
    "5ª": "Q",
    "6ª": "S",
    Sá: "S",
  };
  const navigate = useNavigate();
  const [recados, setRecados] = useState<any>({
    items: [],
    total: 0,
  });
  const [aulas, setAulas] = useState<any>({
    items: [],
    total: 0,
  });

  const [disciplinas, setDisciplinas] = useState<any>({
    items: [],
    total: 0,
  });

  const handlePageDisciplina = (page: number) => {
    api.get(`/turma/progresso?page=` + page).then((r) => {
      setDisciplinas(r.data);
    });
  };

  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
    carregarMais: false,
  });

  useEffect(() => {
    Promise.all([
      api.get(`/recado`),
      api.get(`/aula`),
      api.get("turma/progresso"),
    ])
      .then(([r2, r1, r3]: any) => {
        setRecados(r2.data);
        setAulas(r1.data);
        setDisciplinas(r3.data);
      })
      .then((e: any) =>
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        })
      )
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        });
        console.log(e);
      });
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      <Grid container direction="row" style={{ background: cor_fundo }}>
        <CardMenu
          menuSelecao={1}
          ativo={mobile(windowSize)}
          decodedToken={decodedToken}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{ background: cor_branco, padding: 0, margin: 0 }}
          >
            <MenuSuperior
              ativo={mobile(windowSize)}
              texto={"Olá, " + decodedToken?.given_name}
            />
          </Grid>

          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={2}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs>
                <Grid item xs={true}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #015FA9, #FFD901)",
                            borderRadius: "1rem",
                          }}
                        >
                          <Grid item minHeight={"100%"}>
                            <img
                              style={{ marginTop: "-.8rem" }}
                              src={Ret04}
                              alt="Imagem de boas vindas"
                            />
                          </Grid>
                          <Grid item minHeight={"100%"}>
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              padding={2}
                              paddingRight={0}
                              minHeight={"100%"}
                              style={{ maxHeight: 150 }}
                            >
                              <Grid
                                item
                                xs
                                paddingRight={0}
                                style={{ overflow: "hidden" }}
                              >
                                <Grid item paddingRight={0}>
                                  <Typography
                                    paddingRight={0}
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "24px",
                                      fontFamily: fonte,
                                      color: cor_branco,
                                    }}
                                  >
                                    É muito bom te ter de volta!
                                  </Typography>
                                </Grid>
                                <Grid item paddingRight={0}>
                                  <Typography
                                    paddingRight={0}
                                    style={{
                                      fontFamily: fonte,
                                      color: cor_branco,
                                    }}
                                  >
                                    Juntos, fazemos a diferença todos os dias.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs marginTop={"1.5rem"}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="flex-end"
                              minHeight={"100%"}
                              height={150}
                              marginLeft={"-1rem"}
                            >
                              <Grid item>
                                <img
                                  src={Boneco}
                                  height={"200"}
                                  style={{ marginTop: "-50px" }}
                                  alt="Imagem de boas vindas"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={2}
                      >
                        <Grid item xs>
                          <Grid
                            item
                            style={{
                              padding: "0",
                              background: cor_branco,
                              borderRadius: "1rem",
                              marginBottom: "0px",
                              height: "100%",
                            }}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="pt-br"
                              dateFormats={{ monthAndYear: "MMMM, YYYY" }}
                            >
                              <DateCalendar
                                sx={{
                                  ".MuiDayCalendar-weekDayLabel": {
                                    cursor: "default",
                                    userSelect: "none",
                                  },
                                  ".MuiPickersSlideTransition-root": {
                                    marginBottom: "-1rem",
                                  },
                                  "& .css-nk89i7-MuiPickersCalendarHeader-root":
                                    {
                                      flexDirection: "row-reverse !important",
                                      position: "relative",
                                    },
                                  "& .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button":
                                    {
                                      padding: "0px !important",
                                    },
                                  "& .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button":
                                    {
                                      position: "absolute",
                                      right: "25px",
                                      padding: "0px",
                                    },
                                  "& .css-cyfsxc-MuiPickersCalendarHeader-labelContainer":
                                    {
                                      // marginLeft: "auto !important",
                                      marginLeft: "45px",
                                      textTransform: "capitalize",
                                    },
                                  ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                    {
                                      color: cor_preto_100,
                                      fontFamily: fonte,
                                      fontWeight: 600,
                                    },
                                  ".MuiSvgIcon-fontSizeInherit ": {
                                    background: cor_principal_500,
                                    borderRadius: ".5rem",
                                    color: cor_branco,
                                    marginRight: "-1px",
                                  },
                                  ".MuiPickersFadeTransitionGroup-root": {
                                    color: cor_preto_200,
                                    fontFamily: fonte,
                                    fontWeight: 600,
                                  },
                                }}
                                dayOfWeekFormatter={(
                                  _day: any,
                                  weekday: any
                                ): any =>
                                  `${deParaData[weekday?.format("dd")]}`
                                }
                                slots={{
                                  day: (slotProps: any) => {
                                    return (
                                      <PickersDay
                                        style={{
                                          backgroundColor: dayjs(
                                            slotProps?.day
                                          ).isAfter(dayjs())
                                            ? cor_principal_500
                                            : cor_verde_limao_400,
                                          color: cor_branco,
                                          fontWeight: 600,
                                          cursor: dayjs(slotProps?.day).isAfter(
                                            dayjs()
                                          )
                                            ? "default"
                                            : "pointer",
                                          fontFamily: fonte,
                                          borderRadius: 10,
                                        }}
                                        day={slotProps?.day?.get("date")}
                                        onDaySelect={(day: any) => {
                                          if (
                                            dayjs(slotProps?.day).isAfter(
                                              dayjs()
                                            )
                                          ) {
                                          } else {
                                            const inicio = dayjs(
                                              slotProps?.day
                                            ).format("DD-MM-YYYY");

                                            navigate(
                                              `/trilhas?inicio=${inicio}&fim=${inicio}`
                                            );
                                          }
                                        }}
                                        outsideCurrentMonth={false}
                                        isFirstVisibleCell={false}
                                        isLastVisibleCell={false}
                                      >
                                        {dayjs(slotProps?.day).isAfter(
                                          dayjs()
                                        ) ? (
                                          <HttpsIcon />
                                        ) : (
                                          slotProps?.day?.get("date")
                                        )}
                                      </PickersDay>
                                    );
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>

                        <Grid item zeroMinWidth>
                          <Grid
                            item
                            style={{
                              background: cor_branco,
                              borderRadius: "1rem",
                              paddingBottom: "1.5rem",
                              height: "100%",
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="flex-start"
                              paddingRight={"4rem"}
                              style={{ paddingLeft: "1rem" }}
                              minWidth={220}
                            >
                              <Grid item>
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontFamily: fonte,
                                    fontSize: "18px",
                                    color: cor_preto_200,
                                  }}
                                >
                                  Acesso Rápido
                                </p>

                                {/* <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                        style={{
                                          background: cor_branco_300,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "100%",
                                        }}
                                      >
                                        <Grid item>
                                          <PermIdentityIcon
                                            style={{
                                              color: cor_preto_300,
                                              height: "100%",
                                              width: "100%",
                                              padding: ".3rem",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <p
                                      style={{
                                        marginLeft: "1rem",
                                        fontWeight: 600,
                                        fontFamily: fonte,
                                        color: cor_principal_500,
                                      }}
                                    >
                                      DASHBOARD
                                    </p>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                        style={{
                                          background: cor_branco_300,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "100%",
                                        }}
                                      >
                                        <Grid item>
                                          <ForumIcon
                                            style={{
                                              color: cor_preto_300,
                                              height: "100%",
                                              width: "100%",
                                              padding: ".5rem",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <p
                                      style={{
                                        marginLeft: "1rem",
                                        fontWeight: 600,
                                        fontFamily: fonte,
                                        color: cor_principal_500,
                                      }}
                                    >
                                      MENSAGEM
                                    </p>
                                  </Grid>
                                </Grid> */}
                                <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  {/*<Grid*/}
                                  {/*  container*/}
                                  {/*  direction="row"*/}
                                  {/*  justifyContent="flex-start"*/}
                                  {/*  alignItems="flex-start"*/}
                                  {/*>*/}
                                  {/*  <Grid item>*/}
                                  {/*    <Grid*/}
                                  {/*      container*/}
                                  {/*      justifyContent="center"*/}
                                  {/*      alignItems="center"*/}
                                  {/*      direction="column"*/}
                                  {/*      style={{*/}
                                  {/*        background: cor_branco_300,*/}
                                  {/*        height: "40px",*/}
                                  {/*        width: "40px",*/}
                                  {/*        borderRadius: "100%",*/}
                                  {/*      }}*/}
                                  {/*    >*/}
                                  {/*      <Grid item>*/}
                                  {/*        <VideoLibraryIcon*/}
                                  {/*          style={{*/}
                                  {/*            color: cor_preto_300,*/}
                                  {/*            height: "100%",*/}
                                  {/*            width: "100%",*/}
                                  {/*            padding: ".5rem",*/}
                                  {/*          }}*/}
                                  {/*        />*/}
                                  {/*      </Grid>*/}
                                  {/*    </Grid>*/}
                                  {/*  </Grid>*/}
                                  {/*  <Link*/}
                                  {/*    href="/videoteca"*/}
                                  {/*    style={{*/}
                                  {/*      cursor: "pointer",*/}
                                  {/*      textDecoration: "none",*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    <p*/}
                                  {/*      style={{*/}
                                  {/*        marginLeft: "1rem",*/}
                                  {/*        fontWeight: 600,*/}
                                  {/*        fontFamily: fonte,*/}
                                  {/*        color: cor_principal_500,*/}
                                  {/*      }}*/}
                                  {/*    >*/}
                                  {/*      VIDEOTECA*/}
                                  {/*    </p>*/}
                                  {/*  </Link>*/}
                                  {/*</Grid>*/}
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                        style={{
                                          background: cor_branco_300,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "100%",
                                        }}
                                      >
                                        <Grid item>
                                          <SchoolIcon
                                            style={{
                                              color: cor_preto_300,
                                              height: "100%",
                                              width: "100%",
                                              padding: ".2rem",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Link
                                      href="/trilhas"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "1rem",
                                          fontWeight: 600,
                                          fontFamily: fonte,
                                          color: cor_principal_500,
                                        }}
                                      >
                                        TRILHAS
                                      </p>
                                    </Link>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                        style={{
                                          background: cor_branco_300,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "100%",
                                        }}
                                      >
                                        <Grid item>
                                          <SettingsApplicationsIcon
                                            style={{
                                              color: cor_preto_300,
                                              height: "100%",
                                              width: "100%",
                                              padding: ".3rem",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Link
                                      href="/configuracoes"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "1rem",
                                          fontWeight: 600,
                                          fontFamily: fonte,
                                          color: cor_principal_500,
                                        }}
                                      >
                                        CONFIGURAÇÕES
                                      </p>
                                    </Link>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs style={{ minWidth: "180px" }}>
                          {disciplinas?.items?.length > 0 ? (
                            <Grid item>
                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                    >
                                      <Grid
                                        item
                                        style={{
                                          marginTop: "-.7rem",
                                          color: cor_preto_300,
                                        }}
                                      >
                                        <div
                                          onClick={() =>
                                            handlePageDisciplina(
                                              disciplinas?.page - 1 > 0
                                                ? disciplinas?.page - 1
                                                : Math.ceil(
                                                    disciplinas?.total /
                                                      disciplinas?.items?.length
                                                  )
                                            )
                                          }
                                        >
                                          <NavigateBeforeIcon
                                            style={{
                                              width: "24px",
                                              height: "24px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item>
                                        <p
                                          style={{
                                            fontFamily: fonte,
                                            color: cor_preto_200,
                                            fontWeight: 600,
                                            paddingTop: "0px",
                                            marginTop: "0px",
                                            paddingLeft: ".5rem",
                                            fontSize: "22px",
                                          }}
                                        >
                                          Progresso
                                        </p>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      marginTop: "-.7rem",
                                      color: cor_preto_300,
                                    }}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePageDisciplina(
                                          disciplinas?.page * 2 >=
                                            disciplinas?.total
                                            ? disciplinas?.page - 1 === 0
                                              ? 1
                                              : disciplinas?.page - 1
                                            : disciplinas?.page + 1
                                        )
                                      }
                                    >
                                      <NavigateNextIcon
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                rowSpacing={2}
                                style={{ marginTop: "-1.5rem" }}
                              >
                                {disciplinas?.items?.map((disciplina: any) => (
                                  <Grid item xs={12}>
                                    <Grid
                                      item
                                      xs={12}
                                      key={disciplina?.id}
                                      style={{
                                        background: cor_branco,
                                        padding: "1rem",
                                        paddingTop: ".1px",
                                        borderRadius: ".5rem",
                                      }}
                                    >
                                      <p
                                        style={{
                                          background: cor_fundo,
                                          borderRadius: ".5rem",
                                          fontFamily: fonte,
                                          color: cor_preto_300,
                                          height: "5rem",
                                          width: "100%",
                                          marginBottom: "-.3rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        {disciplina?.nome}
                                      </p>
                                      <p
                                        style={{
                                          borderRadius: ".5rem",
                                          fontFamily: fonte,
                                          color: cor_preto_200,
                                          padding: "0px",
                                          display: "flex",
                                          justifyContent: "end",
                                          alignItems: "end",
                                          textAlign: "end",
                                          fontSize: "12px",
                                          marginBottom: ".2rem",
                                        }}
                                      >
                                        {`${disciplina?.concluido}/${disciplina?.total}`}
                                      </p>
                                      <LinearProgress
                                        sx={{
                                          "& .MuiLinearProgress-bar": {
                                            background: `linear-gradient(45deg, rgba(${hexToRgb(
                                              cor_inicio_progresso_home
                                            )}, 1) 0%, rgba(${hexToRgb(
                                              cor_fim_progresso_home
                                            )}, .41) 100%)`,
                                            borderRadius: "1rem",
                                          },

                                          height: 8,
                                          borderRadius: 5,
                                          background: cor_branco_300,
                                        }}
                                        variant="determinate"
                                        value={
                                          disciplina?.total === 0
                                            ? 0
                                            : (disciplina?.concluido * 100) /
                                              disciplina?.total
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} style={{}}>
                      <span
                        style={{
                          fontFamily: fonte,
                          fontWeight: 600,
                          color: cor_preto_200,
                        }}
                      >
                        Descritivo das aulas no calendário
                      </span>
                    </Grid>
                    <Grid item xs={12} style={{ overflowWrap: "break-word" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={2}
                      >
                        {aulas?.items.map((aula: any) => (
                          <Grid xs={6} key={aula?.id} item>
                            <Cards aula={aula} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <Grid
                  item
                  xs={true}
                  style={{
                    marginBottom: "1rem",
                    padding: "1rem",
                    background: cor_branco,
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                    overflow: "auto",
                    maxHeight: "100vh",
                  }}
                >
                  <Recados
                    recados={recados}
                    setRecados={setRecados}
                    setConfiguracao={setConfiguracao}
                    configuracao={configuracao}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
